import {LocaleCode} from "./authService";

const portalPrefix = "FOP_";
const legacyPortalPrefix = "taxifyFleetOwnerPortal_";

export const getItemKey = (key: string) => `${portalPrefix}${key}`;

export const setItem = (key: string, value: string) => localStorage.setItem(`${getItemKey(key)}`, value);
export const setLegacyItem = (key: string, value: string) => localStorage.setItem(`${legacyPortalPrefix}${key}`, value);

export const removeItem = (key: string) => localStorage.removeItem(`${getItemKey(key)}`);
export const removeItems = (keys: string[]) => keys.forEach((key) => localStorage.removeItem(`${getItemKey(key)}`));

export const removeLegacyItem = (key: string) => localStorage.removeItem(`${legacyPortalPrefix}${key}`);
export const removeLegacyItems = (keys: string[]) =>
    keys.forEach((key) => localStorage.removeItem(`${legacyPortalPrefix}${key}`));

export const getItem = (key: string) => localStorage.getItem(`${getItemKey(key)}`);
export const getLegacyItem = (key: string) => localStorage.getItem(`${legacyPortalPrefix}${key}`);

export const getLanguageWithDefaultLocale = (defaultLocale: string = "en-us") =>
    getLegacyItem(LocaleCode) ?? defaultLocale;

export const clear = () => localStorage.clear();

const TABLE_COLUMN_SETTINGS_KEY = "table_column_settings";

export function getLocalStorageKeyForTable(tableId: string): string {
    return `${TABLE_COLUMN_SETTINGS_KEY}_${tableId}`;
}
