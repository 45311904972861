import React, {useCallback} from "react";

import {LocaleCode} from "common/services/authService";
import {getLanguageWithDefaultLocale, setLegacyItem} from "common/services/localStorageService";
import config from "config/index";
import defaultTranslations from "config/locales/en-US/translations.json";
import {BugsnagService} from "@fleet/common/services/bugsnag";
import {TranslationService} from "@fleet/common/services/translations";

type Translations = typeof defaultTranslations;
const fallbackTranslations: {[key: string]: Translations} = {
    /* eslint-disable @typescript-eslint/naming-convention -- kebab-case is required (en-us etc.) */
    "en-us": defaultTranslations,
    /* eslint-enable @typescript-eslint/naming-convention */
};

export interface Bugsnag {
    bugsnagNotify: (e: Error) => void;
    bugsnagLeaveBreadcrumb: (name: string, metadata?: Record<string, unknown>) => void;
    bugsnagSetUser: (id: string) => void;
}

export interface ThirdPartyState {
    changeLocale: (locale: string) => Promise<void>;
    bugsnag: Bugsnag;
}

const ThirdPartyContextProvider = React.createContext<ThirdPartyState>({
    changeLocale: () => Promise.resolve(),
    bugsnag: {
        bugsnagNotify: () => {},
        bugsnagLeaveBreadcrumb: () => {},
        bugsnagSetUser: () => {},
    },
});
ThirdPartyContextProvider.displayName = "ThirdPartyContextProvider";

const ThirdPartyProvider = ({children}: {children: React.ReactNode}) => {
    const {bugsnagNotify, ErrorBoundary, bugsnagLeaveBreadcrumb, bugsnagSetUser} = BugsnagService(
        config.isEnvLocal, // To test Bugsnag locally, disable this
        config.bugsnagApiKey,
        config.versionNumber,
        config.appStage,
        config.appBrand,
    );

    const onTranslationsFetchError = useCallback((e: Error) => bugsnagNotify(e), [bugsnagNotify]);

    const currentlySelectedLocale = getLanguageWithDefaultLocale();

    const {RemoteIntlProvider, changeAppLocale} = TranslationService(
        config.baseUrl,
        config.crowdinFolder,
        config.defaultLocale,
        currentlySelectedLocale,
        config.crowdinBackendProject,
        config.crowdinFileName,
        fallbackTranslations,
    );

    const changeLocale = async (locale: string) => {
        setLegacyItem(LocaleCode, locale);
        await changeAppLocale(locale);
    };

    return (
        <ErrorBoundary>
            <RemoteIntlProvider onTranslationsFetchError={onTranslationsFetchError} key={config.defaultLocale}>
                <ThirdPartyContextProvider.Provider
                    value={{
                        changeLocale,
                        bugsnag: {
                            bugsnagNotify,
                            bugsnagLeaveBreadcrumb,
                            bugsnagSetUser,
                        },
                    }}
                >
                    {children}
                </ThirdPartyContextProvider.Provider>
            </RemoteIntlProvider>
        </ErrorBoundary>
    );
};

export {ThirdPartyContextProvider, ThirdPartyProvider};
