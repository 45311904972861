import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {getDayMonthYearDate} from "common/util/FormatUtil";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {getDecimalSeparatorFromLocale} from "../../../../../common/services/decimalSeparator";

export function useOrderHistoryDownloadApi(
    period: ApiPeriod,
): (api: Api) => Promise<FleetOwnerReportingService.GetOrdersCsvResponse> {
    const dates =
        period.period === Period.CUSTOM ? {start: period.startDate, end: period.endDate} : apiPeriodToDates(period);

    const fetchOrderHistory = (api: Api) =>
        api.fleetOwnerReporting.getOrdersCsv({
            start_date: getDayMonthYearDate(dates.start),
            end_date: getDayMonthYearDate(dates.end),
            decimal_separator: getDecimalSeparatorFromLocale(),
        });

    return fetchOrderHistory;
}
