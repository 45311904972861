import {ChangeEvent, KeyboardEvent, useCallback, useContext, useState} from "react";

import {UnreachableCode} from "common/components/util/UnreachableCode";
import {useCountdown} from "common/hooks/useCountdown";
import {useI18n} from "common/hooks/useI18n";
import config from "config/index";
import {VerificationContextProvider} from "features/account/pages/Verification/VerificationProvider";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, GhostButton, LinkButton, TextField, Typography} from "@bolteu/kalep-react";

import {ResendCode} from "./ResendCode";
import {UpdatePhone} from "./UpdatePhone";

import VerificationCodeData = FleetOwnerVerificationNoAuthService.VerificationCodeData;
import Source = FleetOwnerVerificationNoAuthService.Source;

interface Props {
    otpData: VerificationCodeData;
}

const CodeInput = ({otpData}: Props) => {
    const {i18n} = useI18n();
    const {isLoading, confirmCode, setConfirmCodeError, confirmCodeError, requestCode} =
        useContext(VerificationContextProvider);
    const [code, setCode] = useState("");
    const {seconds} = useCountdown(otpData.resend_wait_time_seconds);
    const [isUpdatePhoneShown, setUpdatePhoneShown] = useState(false);
    const {can_update_phone_number: isUpdatePhoneNumberEnabled, source} = otpData;

    const isCodeConfirmingDisabled = code.length !== otpData.verification_code_length || !!confirmCodeError;

    const handleConfirmCode = useCallback(async () => {
        if (isCodeConfirmingDisabled) {
            return;
        }
        confirmCode(code);
    }, [code, confirmCode, isCodeConfirmingDisabled]);

    const handleCodeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setCode(event.target.value);
            setConfirmCodeError(undefined);
        },
        [setConfirmCodeError],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                handleConfirmCode();
            }
        },
        [handleConfirmCode],
    );

    const getBackButtonHref = () => {
        switch (source) {
            case Source.LOGIN:
                return `${window.origin}/v2`;
            case Source.SIGNUP:
                return config.landingPageUrl;
            default:
                UnreachableCode.never(source);
                return `${window.origin}/v2`;
        }
    };

    const handleChangePhoneNumber = useCallback(async () => {
        setUpdatePhoneShown(true);
    }, []);

    const handleClosePhoneNumber = useCallback(async () => {
        setUpdatePhoneShown(false);
    }, []);

    const handleUpdatePhoneSuccess = useCallback(() => {
        requestCode({channel: otpData.verification_code_channel});
        setUpdatePhoneShown(false);
    }, [otpData.verification_code_channel, requestCode]);

    if (isUpdatePhoneShown) {
        return <UpdatePhone onBack={handleClosePhoneNumber} onSuccess={handleUpdatePhoneSuccess} />;
    }
    // TODO code completion for chrome??
    return (
        <div className="flex w-full max-w-[356px] flex-col gap-2 text-center">
            <div>
                <Typography variant="title-primary">{i18n("login.phone.enterCode")}</Typography>
            </div>
            <div className="mb-6 flex flex-col items-center">
                <Typography fontSize="text-base" color="secondary">
                    {i18n("login.phone.verificationCodeSent", {
                        phone: "",
                    })}
                </Typography>
                <Typography variant="title-primary" fontSize="text-lg">
                    {otpData.verification_code_target}
                </Typography>
                {isUpdatePhoneNumberEnabled && (
                    <div className="mt-2">
                        <GhostButton onClick={handleChangePhoneNumber}>
                            <span className="text-action-primary">{i18n("login.phone.changePhoneNumber")}</span>
                        </GhostButton>
                    </div>
                )}
            </div>
            <div className="flex w-full flex-col gap-4 text-left">
                <TextField
                    type="text"
                    placeholder={i18n("login.phone.enterCode")}
                    required
                    autoComplete="one-time-code"
                    inputMode="numeric"
                    disabled={isLoading}
                    onChange={handleCodeChange}
                    error={!!confirmCodeError}
                    helperText={confirmCodeError}
                    fullWidth
                    onKeyDown={handleKeyDown}
                />
                <div className="text-secondary mb-5">
                    <ResendCode seconds={seconds} />
                </div>
                <div className="flex w-full flex-row gap-4">
                    <div className="basis-1/2">
                        <LinkButton variant="secondary" fullWidth disabled={isLoading} href={getBackButtonHref()}>
                            {i18n("common.back")}
                        </LinkButton>
                    </div>
                    <div className="basis-1/2">
                        <Button
                            fullWidth
                            loading={isLoading}
                            onClick={handleConfirmCode}
                            disabled={isCodeConfirmingDisabled}
                        >
                            {i18n("common.continue")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodeInput;
