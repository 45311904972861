import {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {Marker} from "common/geo/components/google-maps/Marker";
import {GoogleMapApi, Map, Maps, Ref} from "common/geo/components/google-maps/types";
import {useI18n} from "common/hooks/useI18n";
import {debounce} from "lodash-es";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";

import {DriverInfo} from "../../types";
import {DriverMapMarker} from "../marker/DriverMapMarker";
import {LocationMapMarker} from "../marker/LocationMapMarker";

export type BBox = [number, number, number, number];

// Loosely center the Europe
const defaultMapConfig = {
    center: {
        lat: 48,
        lng: 13,
    },
    zoom: 4,
};

interface UseMapViewProps {
    drivers: FleetOwnerPortalService.DriverForFleetOwner[];
    onDriverSelected: (driver: FleetOwnerPortalService.DriverForFleetOwner) => void;
    onDriverPreviewSelected?: (driverId: number | null) => void;
    selectedDriver: DriverInfo | null;
    previewDriverId?: number | null;
    isMobileView?: boolean;
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null;
}

export const useMapView = ({
    drivers,
    selectedDriver,
    onDriverSelected,
    onDriverPreviewSelected,
    isMobileView = false,
    previewDriverId,
    lastDropoffAndWaitingTime,
}: UseMapViewProps) => {
    const [googleMapsApi, setGoogleMapsApi] = useState<GoogleMapApi | undefined>();
    const [hasUserRecentlyChangedMap, setHasUserRecentlyChangedMap] = useState(false);
    const [zoom, setZoom] = useState(defaultMapConfig.zoom);
    const [bounds, setBounds] = useState<BBox>([
        -84.822265625, 15.129091742705643, 110.82226562499999, 68.20980341203649,
    ]);
    const automaticZoomLevel = useRef<number>();
    const {i18n} = useI18n();

    useEffect(() => {
        setHasUserRecentlyChangedMap(false);
    }, [setHasUserRecentlyChangedMap]);

    const getBbox = useCallback((): BBox => {
        const mapBounds = googleMapsApi?.map.getBounds();
        if (!mapBounds) {
            return [0, 0, 0, 0];
        }
        const sw = mapBounds.getSouthWest();
        const ne = mapBounds.getNorthEast();
        return [sw.lng(), sw.lat(), ne.lng(), ne.lat()];
    }, [googleMapsApi?.map]);

    const onGoogleApiLoaded = useCallback(({map, maps, ref}: {map: Map; maps: Maps; ref: Ref}) => {
        setGoogleMapsApi({
            map,
            maps,
            ref,
        });
    }, []);

    const selectDriverPreview = useCallback(
        (driverId: number | null) => {
            if (onDriverPreviewSelected !== undefined) {
                onDriverPreviewSelected(driverId);
            }
        },
        [onDriverPreviewSelected],
    );

    const isDriverWaiting = selectedDriver?.driver.state === FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS;
    const dropOffLocationDivRef = useRef<HTMLDivElement | null>(null);

    const markers = useMemo(() => {
        const driversArray = selectedDriver ? [selectedDriver.driver] : drivers;
        const markersList = driversArray.map((driver) => {
            return (
                <Marker key={driver.id} lat={driver.lat} lng={driver.lng}>
                    <DriverMapMarker
                        driver={driver}
                        selectDriver={onDriverSelected}
                        selectDriverPreview={selectDriverPreview}
                        isSelected={selectedDriver?.driver.id === driver.id}
                        isSelectedForPreview={previewDriverId === driver.id}
                        isMobileView={isMobileView}
                    />
                </Marker>
            );
        });

        if (lastDropoffAndWaitingTime?.last_dropoff && isDriverWaiting) {
            markersList.push(
                <Marker
                    key={`${selectedDriver?.driver.order_id}-last-dropoff`}
                    lat={lastDropoffAndWaitingTime.last_dropoff?.last_dropoff_lat || 0}
                    lng={lastDropoffAndWaitingTime.last_dropoff?.last_dropoff_lng || 0}
                >
                    <Tooltip
                        content={i18n("auth.app.fleet.live-map.last-order.last-dropoff")}
                        boundaryElement={dropOffLocationDivRef.current ? dropOffLocationDivRef.current : undefined}
                    >
                        <div ref={dropOffLocationDivRef} className="align-center flex flex-col p-3">
                            <LocationMapMarker type="last-dropoff" />
                        </div>
                    </Tooltip>
                </Marker>,
            );
        }

        if (selectedDriver?.order) {
            markersList.push(
                <Marker
                    key={`${selectedDriver.driver.order_id}-start`}
                    lat={selectedDriver.order.lat}
                    lng={selectedDriver.order.lng}
                >
                    <LocationMapMarker type="start" />
                </Marker>,
            );

            if (selectedDriver.order.destination_lat && selectedDriver.order.destination_lng) {
                markersList.push(
                    <Marker
                        key={`${selectedDriver.driver.order_id}-destination`}
                        lat={selectedDriver.order.destination_lat}
                        lng={selectedDriver.order.destination_lng}
                    >
                        <LocationMapMarker type="destination" />
                    </Marker>,
                );
            }
        }
        return markersList;
        // eslint-disable-next-line react-hooks/exhaustive-deps -- zoom and bounds, get markers update on map movement
    }, [drivers, selectedDriver, onDriverSelected, selectDriverPreview, previewDriverId, isMobileView, zoom, bounds]);

    const userMapChangeFlagHandler = useMemo(() => debounce(() => setHasUserRecentlyChangedMap(false), 60_000), []);

    useEffect(() => {
        return () => {
            userMapChangeFlagHandler.cancel();
        };
    }, [userMapChangeFlagHandler]);

    const handleUserChangedMap = useCallback(() => {
        setHasUserRecentlyChangedMap(true);
        userMapChangeFlagHandler();
    }, [userMapChangeFlagHandler]);

    const handleZoomEnd = useCallback(
        (zoomValue: number) => {
            setZoom(zoomValue);
            setBounds(getBbox());
            if (zoomValue !== automaticZoomLevel.current) {
                handleUserChangedMap();
            }
        },
        [getBbox, handleUserChangedMap],
    );

    const handleOnDrag = useCallback(() => {
        setBounds(getBbox());
        handleUserChangedMap();
    }, [getBbox, handleUserChangedMap]);

    return {
        defaultMapConfig,
        hasUserRecentlyChangedMap,
        markers,
        googleMapsApi,
        automaticZoomLevel,
        setBounds,
        getBbox,
        onGoogleApiLoaded,
        handleZoomEnd,
        handleOnDrag,
    };
};
