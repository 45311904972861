import React from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";

import FilledCircle from "assets/icons/filled_circle.svg?react";
import {useI18n} from "common/hooks/useI18n";
import {getLocalStorageKeyForTable} from "common/services/localStorageService";
import {formatNumber} from "common/util/FormatUtil";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {TableColumnSettings} from "../TableWithCustomizableColumns";

export interface CellProps {
    valueAtIdx: number;
    column: FleetOwnerPortalService.ApiColumn;
}

interface StateDisplayIndicatorProps {
    entityState?: FleetOwnerPortalService.EntityState;
}

export function NumberCellContent({value, unit}: {value: number; unit?: string}): React.ReactElement {
    const intl = useIntl();
    const formattedValue = formatNumber(intl, value);
    if (!value) {
        return (
            <Typography variant="body-tabular-s-regular" color="secondary">
                {formattedValue}
            </Typography>
        );
    }
    return (
        <div className="flex w-full items-baseline gap-x-1">
            <Typography variant="body-tabular-m-regular">{formattedValue}</Typography>{" "}
            <Typography variant="body-tabular-s-regular" color="secondary">
                {unit}
            </Typography>
        </div>
    );
}

export function DurationCellContent({value}: {value: number}): React.ReactElement {
    const {i18n} = useI18n();

    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);

    const hoursLabel = i18n("common.hour_abbreviation");
    const minutesLabel = i18n("common.minute_abbreviation");

    return (
        <div className="flex gap-1 whitespace-nowrap">
            {hours !== 0 ? (
                <div className="flex items-baseline gap-x-1">
                    <Typography variant="body-tabular-m-regular">{hours}</Typography>
                    <Typography variant="body-tabular-s-regular" color="secondary">
                        {` ${hoursLabel}`}
                    </Typography>
                </div>
            ) : null}
            <div className="flex items-baseline gap-x-1">
                <Typography variant="body-tabular-m-regular">{minutes}</Typography>
                <Typography variant="body-tabular-s-regular" color="secondary">
                    {minutesLabel}
                </Typography>
            </div>
        </div>
    );
}

export function getVehicleStatusIcon(status: FleetOwnerPortalService.EntityState): React.ReactElement {
    switch (status) {
        case FleetOwnerPortalService.EntityState.DEFAULT:
            return <></>;
        case FleetOwnerPortalService.EntityState.ALERT:
            return <FilledCircle width={12} height={12} className="fill-red-500" />;
        case FleetOwnerPortalService.EntityState.INACTIVE:
            return <FilledCircle width={12} height={12} className="fill-neutral-500" />;
        default:
            return <></>;
    }
}

export function CellRenderer(cellProps: CellProps) {
    switch (cellProps.column.type) {
        case FleetOwnerPortalService.ListColumnType.STRING:
            return <StringCellContent value={cellProps.column.cells[cellProps.valueAtIdx]} />;
        case FleetOwnerPortalService.ListColumnType.NUMBER:
            return (
                <NumberCellContent value={cellProps.column.cells[cellProps.valueAtIdx]} unit={cellProps.column.unit} />
            );
        case FleetOwnerPortalService.ListColumnType.DURATION:
            return <DurationCellContent value={cellProps.column.cells[cellProps.valueAtIdx]} />;
        default:
            return <></>;
    }
}

export function ReferenceColumnContent(
    cellProps: CellProps & {
        referenceColumn?: FleetOwnerPortalService.ReferenceColumnShape;
        stateColumn?: FleetOwnerPortalService.StateColumn;
        subDisplayColumn?: FleetOwnerPortalService.ApiColumn;
        displayAsLink?: boolean;
    },
) {
    const primaryCellValue = (
        <Typography variant="body-tabular-m-regular">{cellProps.column.cells[cellProps.valueAtIdx]}</Typography>
    );
    return (
        <div className="flex items-center gap-3">
            <StateDisplayIndicator entityState={cellProps.stateColumn?.cells[cellProps.valueAtIdx]} />
            <div className="flex flex-col">
                {cellProps.displayAsLink ? (
                    <Link className="hover:underline" to={cellProps.referenceColumn?.cells[cellProps.valueAtIdx] ?? ""}>
                        {primaryCellValue}
                    </Link>
                ) : (
                    primaryCellValue
                )}
                <div>
                    <StateDisplayText stateColumn={cellProps.stateColumn} valueAtIdx={cellProps.valueAtIdx} />
                    {cellProps.subDisplayColumn && (
                        <Typography variant="body-tabular-s-regular" color="secondary">
                            {`${cellProps.subDisplayColumn.title}: ${
                                cellProps.subDisplayColumn.cells[cellProps.valueAtIdx]
                            }`}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
}

function StateDisplayIndicator({entityState}: StateDisplayIndicatorProps) {
    return entityState ? getVehicleStatusIcon(entityState) : <></>;
}

export function StateDisplayText(stateDisplayProps: {
    stateColumn?: FleetOwnerPortalService.StateColumn;
    valueAtIdx: number;
}) {
    if (!stateDisplayProps.stateColumn) {
        return <></>;
    }

    const {value_map} = stateDisplayProps.stateColumn;
    const valueMapPair = value_map.find(
        (value) => value.key === stateDisplayProps.stateColumn?.cells[stateDisplayProps.valueAtIdx],
    );

    if (!valueMapPair) {
        return <></>;
    }

    switch (valueMapPair.key) {
        case FleetOwnerPortalService.EntityState.ALERT:
            return (
                <Typography variant="body-tabular-s-regular" color="danger-primary">
                    {valueMapPair.value}
                </Typography>
            );
        case FleetOwnerPortalService.EntityState.INACTIVE:
            return <Typography variant="body-tabular-s-regular">{valueMapPair.value}</Typography>;
        case FleetOwnerPortalService.EntityState.DEFAULT:
            return <></>;
        default:
            return <></>;
    }
}

export function StringCellContent({value}: {value: string}) {
    return <Typography variant="body-tabular-m-regular">{value}</Typography>;
}

export function isColumnVisible(column: FleetOwnerPortalService.ApiColumn): boolean {
    const specialColumnsType = [
        FleetOwnerPortalService.ListColumnType.REFERENCE,
        FleetOwnerPortalService.ListColumnType.STATE,
    ];

    return (
        column.accessibility === FleetOwnerPortalService.Accessibility.VISIBLE &&
        !specialColumnsType.includes(column.type)
    );
}

export interface UseableColumns {
    referenceColumn?: FleetOwnerPortalService.ReferenceColumnShape;
    userDefinedVisibleColumns: FleetOwnerPortalService.ApiColumn[];
    apiDefinedVisibleColumns: FleetOwnerPortalService.ApiColumn[];
    firstColumn: FleetOwnerPortalService.ApiColumn;
    stateColumn?: FleetOwnerPortalService.StateColumn;
}

export function useApiDrivenTableColumns(
    columns: FleetOwnerPortalService.ApiColumn[],
    tableId: string,
): UseableColumns {
    const apiDefinedVisibleColumns = columns?.filter(isColumnVisible);
    const [value] = useLocalStorage<TableColumnSettings>(getLocalStorageKeyForTable(tableId), {});

    const userDefinedVisibleColumns = apiDefinedVisibleColumns?.slice(1)?.filter((column) => {
        const isShown: boolean = value[column.key] ?? true; // we assume that the column is shown by default
        return isShown;
    });

    return {
        referenceColumn: columns?.find(
            (column) => column.type === FleetOwnerPortalService.ListColumnType.REFERENCE,
        ) as FleetOwnerPortalService.ReferenceColumnShape,
        userDefinedVisibleColumns,
        apiDefinedVisibleColumns,
        firstColumn: apiDefinedVisibleColumns[0],
        stateColumn: columns?.find(
            (column) => column.type === FleetOwnerPortalService.ListColumnType.STATE,
        ) as FleetOwnerPortalService.StateColumn,
    };
}
