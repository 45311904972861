import {ValidatorResult} from "common/constants/types";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {Hint} from "./Hint";
import {UseFormValueType} from "./types";

interface TextProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.TextField;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    validatorErrors: ValidatorResult[];
}

const Text = ({useFormValue, fieldConfig, onBlur, validatorErrors}: TextProps) => {
    const {TextField, ComboboxTextField} = useFormValue;
    const {
        name,
        label,
        placeholder,
        hint,
        required: isRequired,
        prefix_combo_box,
        disabled: isTextDisabled,
    } = fieldConfig;

    if (!prefix_combo_box) {
        return (
            <TextField
                name={name as never}
                label={label}
                placeholder={placeholder}
                required={isRequired}
                helperText={<Hint hint={hint} />}
                onBlur={onBlur}
                disabled={isTextDisabled}
            />
        );
    }

    const {disabled: isComboboxDisabled} = prefix_combo_box;

    return (
        <ComboboxTextField
            name={name as never}
            fieldConfig={fieldConfig}
            validatorErrors={validatorErrors}
            onTextInputBlur={onBlur}
            disabled={isTextDisabled || isComboboxDisabled}
        />
    );
};

export {Text};
