import {FC, useCallback, useState} from "react";

import Pagination from "common/components/Pagination";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import TableCustomizationDrawer from "common/components/table/TableCustomizationDrawer";
import TableSearchField from "common/components/table/TableSearchField";
import {selectPeriodOptionsUntilNow} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {OnlineHoursConfig} from "..";
import {useTableColumns} from "../hooks/driver/useTableColumns";
import DriversFilter, {DriverFilter} from "./DriversFilter";
import FilteredBy from "./DriversFilteredBy";
import DriversTable, {DRIVERS_TABLE_ID} from "./DriversTable";

import EngagementDataRangeDateDriver = FleetOwnerPortalService.EngagementDataRangeDateDriver;

import DriversDownload from "./DriversDownload";

interface DriversTabProps {
    dashboardData?: EngagementDataRangeDateDriver[];
    onlineHoursConfig: OnlineHoursConfig;
    selectedPeriod: ApiPeriod;
    onPeriodChange: (period: ApiPeriod) => void;
    onSearchChange: (search: string) => void;
    driverCount?: number;
    pageSize: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    isLoading: boolean;
    filters: DriverFilter;
    setFilters: (filters: DriverFilter) => void;
    showPaginationText?: boolean;
}

const DriversTab: FC<DriversTabProps> = ({
    dashboardData,
    onlineHoursConfig,
    selectedPeriod,
    onPeriodChange,
    currentPage,
    driverCount,
    pageSize,
    onPageChange,
    onSearchChange,
    isLoading,
    filters,
    setFilters,
    showPaginationText = false,
}) => {
    const columns = useTableColumns(onlineHoursConfig);

    const [isInvalidPeriod, setIsInvalidPeriod] = useState<boolean>(false);

    const onPeriodErrorStateChange = useCallback((hasError: boolean) => {
        setIsInvalidPeriod(hasError);
    }, []);

    return (
        <>
            <div className="flex flex-col gap-4 lg:flex-row lg:justify-between">
                <div className="flex flex-[2] justify-between gap-2 sm:justify-start sm:gap-4">
                    <TableSearchField onChange={onSearchChange} />
                    <DriversFilter filters={filters} setFilters={setFilters} />
                    <TableCustomizationDrawer tableId={DRIVERS_TABLE_ID} columns={columns} />
                </div>
                <div className="flex flex-col gap-4 sm:flex-row">
                    <div className="w-full sm:w-auto">
                        <PeriodDatePicker
                            period={selectedPeriod}
                            onPeriodChange={onPeriodChange}
                            selectPeriodOptions={selectPeriodOptionsUntilNow}
                            dateRangeLimitDays={31}
                            onErrorStateChange={onPeriodErrorStateChange}
                        />
                    </div>
                    <DriversDownload period={selectedPeriod} isError={isInvalidPeriod} />
                </div>
            </div>
            <FilteredBy filters={filters} setFilters={setFilters} />
            <DriversTable dashboardData={dashboardData} onlineHoursConfig={onlineHoursConfig} isLoading={isLoading} />
            <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={driverCount}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText={showPaginationText}
            />
        </>
    );
};

export default DriversTab;
