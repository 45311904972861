import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {AutocompleteFieldOption} from "@bolteu/kalep-form-react/build/DataContainer/fields/AutocompleteField";

import {Hint} from "./Hint";
import {useComboBox} from "./hooks/useComboBox";
import {UseFormValueType} from "./types";

interface SelectProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.ComboBoxField;
    error?: string;
}

export type CustomOption = AutocompleteFieldOption &
    Pick<FleetOwnerRegistrationService.ComboBoxOption, "icon" | "appearance">;

const Combobox = ({useFormValue, fieldConfig, error}: SelectProps) => {
    const {AutocompleteField} = useFormValue;
    const {name, label, options, hint, required: isRequired, placeholder, disabled: isDisabled} = fieldConfig;

    const {autocompleteFieldOptions, filterOptions, getOptionLabel, renderOption} = useComboBox(options);

    const restProps = !error
        ? {
              helperText: <Hint hint={hint} />,
          }
        : {};

    return (
        <AutocompleteField
            name={name as never}
            label={label}
            options={autocompleteFieldOptions}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            required={isRequired}
            filterOptions={filterOptions}
            placeholder={placeholder}
            disabled={isDisabled}
            {...restProps}
        />
    );
};

export {Combobox};
