import {Typography} from "@bolteu/kalep-react";

interface ThreeLineColumnLabelProps {
    label: string;
}

const ThreeLineColumnLabel = ({label}: ThreeLineColumnLabelProps) => {
    return (
        <Typography fontWeight="semibold" variant="body-secondary" inlineStyle={{lineHeight: "1rem"}} lines={3}>
            {label}
        </Typography>
    );
};

export {ThreeLineColumnLabel};
