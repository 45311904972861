import {useContext, useEffect} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates} from "common/services/period";
import {getIsoDate} from "common/util/FormatUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

const fetchDrivers = (api: Api, period: {start?: Date; end?: Date}) => {
    let dateFilter = {};
    if (period.end && period.start) {
        dateFilter = {
            start_date: getIsoDate(period.start),
            end_date: getIsoDate(period.end),
        };
    }

    return api.fleetOwnerPortal.getDrivers(dateFilter);
};

export const useDrivers = (period?: ApiPeriod) => {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {fetch, data, status} = useFetch(fetchDrivers);

    useEffect(() => {
        if (!fetch || !fleet) {
            return;
        }

        if (period) {
            const searchDate = apiPeriodToDates(period);
            fetch(searchDate);
        } else {
            fetch({});
        }
    }, [fetch, fleet, period]);

    return {drivers: data?.list ?? [], status};
};
