import {FC, useCallback, useState} from "react";

import {Glossary, GlossaryItemProps} from "common/components/glossary/Glossary";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import {useI18n} from "common/hooks/useI18n";
import {selectPeriodOptionsUntilNow} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

const GET_DATA_DAYS_RANGE_LIMIT = 186;

interface ReportsPageHeaderProps {
    period: ApiPeriod;
    onPeriodChange: (apiPeriod: ApiPeriod) => void;
}

const ReportsPageHeader: FC<ReportsPageHeaderProps> = ({period, onPeriodChange}) => {
    const {i18n} = useI18n();

    const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);

    const handleGlossaryOpen = useCallback(() => {
        setIsGlossaryOpen(true);
    }, []);

    const handleGlossaryClose = useCallback(() => {
        setIsGlossaryOpen(false);
    }, []);

    const SeeGlossaryLink = () => (
        <span
            className="text-action-primary"
            onClick={handleGlossaryOpen}
            onKeyDown={handleGlossaryOpen}
            role="button"
            tabIndex={0}
        >
            {i18n("auth.app.fleet.reports.glossary.link").toLocaleLowerCase()}
        </span>
    );

    const glossaryItems: GlossaryItemProps[] = [
        // Earnings
        {
            title: i18n("auth.app.fleet.reports.header.gross_revenue"),
            text: i18n("auth.app.fleet.reports.gross_revenue_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.cards.bonuses"),
            text: i18n("auth.app.fleet.reports.bonuses_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.compensations"),
            text: i18n("auth.app.fleet.reports.compensations_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.cancellation_fees"),
            text: i18n("auth.app.fleet.reports.cancellation_fees_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.expense_refunds"),
            text: i18n("auth.app.fleet.reports.expense_refunds_explanation"),
        },
        // Expenses
        {
            title: i18n("auth.app.fleet.reports.expenses_total"),
            text: i18n("auth.app.fleet.reports.expenses_total_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.expense_commissions"),
            text: i18n("auth.app.fleet.reports.expense_commissions_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.expense_other_fees"),
            text: i18n("auth.app.fleet.reports.expense_other_fees_explanation"),
        },
        // Net
        {
            title: i18n("auth.app.fleet.reports.header.net_earnings"),
            text: i18n("auth.app.fleet.reports.net_earnings_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.payouts"),
            text: i18n("auth.app.fleet.reports.payouts_explanation"),
        },
        // Earnings indicators
        {
            title: i18n("auth.app.fleet.reports.header.earnings_per_hour_gross"),
            text: i18n("auth.app.fleet.reports.earnings_per_hour_gross_explanation"),
        },
        {
            title: i18n("auth.app.fleet.reports.header.earnings_per_hour_net"),
            text: i18n("auth.app.fleet.reports.earnings_per_hour_net_explanation"),
        },
    ];

    return (
        <div className="flex flex-col justify-between gap-6 lg:flex-row">
            <div className="flex flex-col gap-y-3">
                <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("auth.app.fleet.reports.title")} />
                <div className="font-normal text-neutral-700">
                    <span>
                        <span>{i18n("auth.app.fleet.reports.description_v2")}</span>{" "}
                        {i18n("auth.app.fleet.reports.glossary.text", {
                            fleet_reports_see_glossary_link: <SeeGlossaryLink />,
                        })}
                    </span>
                </div>
            </div>
            <Glossary isOpen={isGlossaryOpen} handleClose={handleGlossaryClose} items={glossaryItems} />
            <PeriodDatePicker
                period={period}
                selectPeriodOptions={selectPeriodOptionsUntilNow}
                onPeriodChange={onPeriodChange}
                dateRangeLimitDays={GET_DATA_DAYS_RANGE_LIMIT}
                allowedDateIntervalMonths={24}
            />
        </div>
    );
};

export default ReportsPageHeader;
