import {TOPBAR_HEIGHT_TAILWIND_CONFIG, TopBarTailwindClassNameConfig} from "common/styles";

import {useTailwindViewport} from "./useTailwindViewport";

export const useTopBarHeightTailwindConfig = (): TopBarTailwindClassNameConfig => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return isMobileView ? TOPBAR_HEIGHT_TAILWIND_CONFIG.height16 : TOPBAR_HEIGHT_TAILWIND_CONFIG.height14;
};
