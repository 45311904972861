import DeactivatedEntityIsland from "common/components/deactivatedEntityIsland/DeactivatedEntityIsland";
import {DocumentsList} from "common/components/document/DocumentsList";
import {DocumentOwnerType, useDocumentsList} from "common/components/document/useDocumentsList";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import VehicleAlert from "./BlockedVehicleAlert";
import PageHeader from "./PageHeader";
import VehicleData from "./VehicleData";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;
import ToggleVehicleDeactivationButton from "./ToggleVehicleDeactivationButton";

interface Props {
    vehicle: FleetOwnerPortalService.GetCarResponse;
    toggleVehicleDeactivationStatus: () => void;
}

const VehicleDetailsBody = ({vehicle, toggleVehicleDeactivationStatus}: Props) => {
    const {documentsListProps, documentsAlertsProps} = useDocumentsList({
        areDocumentsEnabled: true,
        owner: {id: vehicle.id, type: DocumentOwnerType.Vehicle},
        isUploadAllowed: vehicle.portal_status !== FleetOwnerPortalService.EntityPortalStatus.Deactivated,
    });

    let InfoIsland = null;
    if (vehicle.portal_status === EntityPortalStatus.Blocked) {
        InfoIsland = (
            <VehicleAlert
                expiredDocuments={documentsAlertsProps?.expiredDocuments ?? false}
                declinedDocuments={documentsAlertsProps?.declinedDocuments ?? false}
            />
        );
    } else if (vehicle.portal_status === EntityPortalStatus.Deactivated) {
        InfoIsland = (
            <DeactivatedEntityIsland
                title_key="auth.app.fleet.vehicle.deactivation_island.title"
                deactivated_by_fleet_text_key="auth.app.fleet.vehicle.deactivation_island.deactivated_by_fleet_text"
                deactivated_by_bolt_text_key="auth.app.fleet.vehicle.deactivation_island.deactivated_by_bolt_text"
                deactivated_by_bolt_date_unknown_text_key="auth.app.fleet.vehicle.deactivation_island.deactivated_by_bolt_date_unknown_text"
                deactivationDetails={vehicle.deactivation_details}
            />
        );
    }

    return (
        <div className="mt-6">
            <div className="flex items-start justify-between">
                <PageHeader vehicle={vehicle} />
                {vehicle.show_block_button && (
                    <ToggleVehicleDeactivationButton
                        key={vehicle.portal_status}
                        vehicleId={vehicle.id}
                        vehiclePortalStatus={vehicle.portal_status}
                        isDeactivatedByFleetOwner={Boolean(vehicle.deactivation_details?.is_deactivated_by_fleet_owner)}
                        onSuccessAction={toggleVehicleDeactivationStatus}
                    />
                )}
            </div>
            <div className="grid grid-cols-1 gap-y-4 md:grid-cols-12">
                {InfoIsland && <div className="col-span-7">{InfoIsland}</div>}
                <div className="col-span-7 col-start-auto mb-4">
                    <VehicleData vehicle={vehicle} />
                </div>
                <div className="col-span-4 col-end-[-1]">
                    {documentsListProps && <DocumentsList {...documentsListProps} />}
                </div>
            </div>
        </div>
    );
};

export default VehicleDetailsBody;
