import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useI18n} from "common/hooks/useI18n";
import {ResponseCodes} from "common/ResponseCodes";
import {FleetOwnerVerificationNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {isServerError, isValidationError} from "common/util/isErrorType";
import NoAuthLayout from "features/account/components/NoAuthLayout";
import {useVerificationNavigations} from "features/account/components/Verification/hooks/useVerificationNavigations";
import {SubmitOtpDynamicForm} from "features/account/components/Verification/SubmitOtpDynamicForm";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const VerificationPassword = () => {
    const {i18n} = useI18n();
    const params = useParams();
    const [passwordForm, setPasswordForm] = useState<
        FleetOwnerVerificationNoAuthService.GetOtpFormResponse | undefined
    >();
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const {get2faNavigationTo} = useVerificationNavigations();

    const attemptKey = params.key;

    useEffect(() => {
        const getPasswordForm = async () => {
            try {
                const response = await FleetOwnerVerificationNoAuthApiClient.getOtpForm({
                    attempt_key: attemptKey ?? "",
                    non_code_verification_channel:
                        FleetOwnerVerificationNoAuthService.NonCodeVerificationChannel.PASSWORD,
                });
                setPasswordForm(response);
            } catch (error) {
                if (isValidationError(error)) {
                    setErrorMessage(i18n("api.error.FLEET_OWNER_VERIFICATION_PASSWORD_INVALID_KEY"));
                } else if (
                    isServerError(error) &&
                    error.response.code === ResponseCodes.TWO_FACTOR_AUTH_LOGIN_REQUIRED
                ) {
                    navigate(get2faNavigationTo(attemptKey ?? ""));
                } else {
                    setErrorMessage(i18n("api.default_error"));
                }
            }
        };
        getPasswordForm();
    }, [attemptKey, get2faNavigationTo, i18n, navigate, params]);

    return (
        <NoAuthLayout gap="small">
            {errorMessage && <h1 className="text-2xl font-semibold">{errorMessage}</h1>}
            {passwordForm && <SubmitOtpDynamicForm formData={passwordForm} />}
        </NoAuthLayout>
    );
};

export default VerificationPassword;
