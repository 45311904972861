import {useCallback, useMemo} from "react";
import {generatePath} from "react-router-dom";

import DataAndMeasuresCell, {AccordionTableDataAndMeasuresCell} from "common/components/table/DataAndMeasuresCell";
import {CustomizableTableHeaders} from "common/components/table/TableWithCustomizableColumns";
import {Dash} from "common/constants";
import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";

import {DriverEarningsService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton} from "@bolteu/kalep-react";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";

import {ReportsTableData} from "..";

export function useTableColumns(
    currency: string,
    isMobileView: boolean,
    configuration: DriverEarningsService.GetDriverEarningsColumnsConfiguration | undefined,
) {
    const {i18n} = useI18n();

    const goToDriverProfile = useCallback(
        (id: string) => () => {
            window.open(generatePath(FleetRoute.DRIVER, {id}));
        },
        [],
    );

    const CellComponent = isMobileView ? AccordionTableDataAndMeasuresCell : DataAndMeasuresCell;
    const vatTranslation = i18n("auth.app.invoices.rider-invoices.csv.vat");

    return useMemo<CustomizableTableHeaders<ReportsTableData>>(() => {
        const sumPerHourUnit = `${currency}/${i18n("common.hour_abbreviation")}`;

        return {
            name: {
                label: i18n("auth.app.fleet.reports.header.driver"),
                renderCell: (item: ReportsTableData) => (
                    <DefaultCellContent>
                        <GhostButton onClick={goToDriverProfile(item.id)} overrideClassName="hover:underline">
                            {item.name}
                        </GhostButton>
                    </DefaultCellContent>
                ),
                nonConfigurable: true,
            },
            email: {
                label: i18n("auth.app.fleet.driver.email"),
                renderCell: (item: ReportsTableData) => <DefaultCellContent>{item.email}</DefaultCellContent>,
                hideByDefault: true,
            },
            phone: {
                label: i18n("auth.app.fleet.driver.phone"),
                renderCell: (item: ReportsTableData) => <DefaultCellContent>{item.phone}</DefaultCellContent>,
                hideByDefault: true,
            },
            // Earnings
            gross_revenue: {
                label: i18n("auth.app.fleet.reports.header.gross_revenue"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.gross_revenue"),
                renderCell: (item: ReportsTableData) => <CellComponent data={item.gross_revenue} measure={currency} />,
                align: "right",
            },
            gross_revenue_app: {
                label: i18n("auth.app.fleet.reports.header.gross_revenue_app"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.gross_revenue_app"),
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_revenue_in_app && item.vat_sum_revenue_in_app !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_revenue_in_app}`;
                    }
                    return <CellComponent data={item.gross_revenue_app} measure={currency} extraData={extraData} />;
                },
                align: "right",
            },
            gross_revenue_cash: {
                label: i18n("auth.app.fleet.reports.header.gross_revenue_cash"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.gross_revenue_cash"),
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_revenue_cash && item.vat_sum_revenue_cash !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_revenue_cash}`;
                    }
                    return <CellComponent data={item.gross_revenue_cash} measure={currency} extraData={extraData} />;
                },
                align: "right",
            },
            cash_in_hand: {
                label: i18n("auth.app.fleet.reports.header.cash_in_hand"),
                renderCell: (item: ReportsTableData) => <CellComponent data={item.cash_in_hand} measure={currency} />,
                align: "right",
            },
            tips: {
                label: i18n("auth.app.fleet.reports.header.tips"),
                renderCell: (item: ReportsTableData) => <CellComponent data={item.tips} measure={currency} />,
                align: "right",
            },
            bonuses: {
                label: i18n("auth.app.fleet.reports.cards.bonuses"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.bonuses"),
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_bonuses && item.vat_sum_bonuses !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_bonuses}`;
                    }
                    return <CellComponent data={item.bonuses} measure={currency} extraData={extraData} />;
                },
                align: "right",
            },
            compensations: {
                label: i18n("auth.app.fleet.reports.compensations"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.compensations"),
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_compensations && item.vat_sum_compensations !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_compensations}`;
                    }
                    return <CellComponent data={item.compensations} measure={currency} extraData={extraData} />;
                },
                align: "right",
            },
            cancellation_fees: {
                label: i18n("auth.app.fleet.reports.cancellation_fees"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.cancellation_fees"),
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (
                        configuration?.is_show_vat_sum_cancellation_fees &&
                        item.vat_sum_cancellation_fees !== Dash.EM
                    ) {
                        extraData = `${vatTranslation} ${item.vat_sum_cancellation_fees}`;
                    }
                    return <CellComponent data={item.cancellation_fees} measure={currency} extraData={extraData} />;
                },
                align: "right",
            },
            toll_roads: {
                label: i18n("auth.app.fleet.reports.cards.toll_roads"),
                renderCell: (item: ReportsTableData) => <CellComponent data={item.toll_roads} measure={currency} />,
                align: "right",
                hideByDefault: true,
            },
            booking_fees: {
                label: i18n("auth.app.fleet.reports.cards.booking_fees"),
                renderCell: (item: ReportsTableData) => {
                    let extraData;
                    if (configuration?.is_show_vat_sum_booking_fees && item.vat_sum_booking_fees !== Dash.EM) {
                        extraData = `${vatTranslation} ${item.vat_sum_booking_fees}`;
                    }
                    return <CellComponent data={item.booking_fees} measure={currency} extraData={extraData} />;
                },
                align: "right",
                hideByDefault: true,
            },
            // Expenses
            expenses_total: {
                label: i18n("auth.app.fleet.reports.expenses_total"),
                tooltip: i18n("auth.app.fleet.reports.expenses_total_explanation"),
                renderCell: (item: ReportsTableData) => <CellComponent data={item.expenses_total} measure={currency} />,
                align: "right",
                hideByDefault: true,
            },
            expense_commissions: {
                label: i18n("auth.app.fleet.reports.expense_commissions"),
                tooltip: i18n("auth.app.fleet.reports.expense_commissions_explanation"),
                renderCell: (item: ReportsTableData) => (
                    <CellComponent data={item.expense_commissions} measure={currency} />
                ),
                align: "right",
                hideByDefault: true,
            },
            expense_refunds: {
                label: i18n("auth.app.fleet.reports.expense_refunds"),
                tooltip: i18n("auth.app.fleet.reports.expense_refunds_explanation"),
                renderCell: (item: ReportsTableData) => (
                    <CellComponent data={item.expense_refunds} measure={currency} />
                ),
                align: "right",
                hideByDefault: true,
            },
            expense_other_fees: {
                label: i18n("auth.app.fleet.reports.expense_other_fees"),
                tooltip: i18n("auth.app.fleet.reports.expense_other_fees_explanation"),
                renderCell: (item: ReportsTableData) => (
                    <CellComponent data={item.expense_other_fees} measure={currency} />
                ),
                align: "right",
                hideByDefault: true,
            },
            // Net
            net_earnings: {
                label: i18n("auth.app.fleet.reports.header.net_earnings"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.net_earnings"),
                renderCell: (item: ReportsTableData) => <CellComponent data={item.net_earnings} measure={currency} />,
                align: "right",
            },
            payouts: {
                label: i18n("auth.app.fleet.reports.payouts"),
                tooltip: i18n("auth.app.fleet.reports.payouts_explanation"),
                renderCell: (item: ReportsTableData) => <CellComponent data={item.payouts} measure={currency} />,
                align: "right",
                hideByDefault: true,
            },
            // Earnings indicators
            earnings_per_hour_gross: {
                label: i18n("auth.app.fleet.reports.cards.eph_gross"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.earnings_per_hour_gross"),
                renderCell: (item: ReportsTableData) => (
                    <CellComponent data={item.earnings_per_hour_gross} measure={sumPerHourUnit} />
                ),
                align: "right",
            },
            earnings_per_hour_net: {
                label: i18n("auth.app.fleet.reports.cards.eph_net"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.earnings_per_hour_net"),
                renderCell: (item: ReportsTableData) => (
                    <CellComponent data={item.earnings_per_hour_net} measure={sumPerHourUnit} />
                ),
                align: "right",
            },
            // Other indicators
            ...(configuration?.is_show_withholding_tax && {
                withholding_tax: {
                    label: i18n("auth.app.fleet.balance.transactions_list.earnings.withholding_tax.title"),
                    renderCell: (item: ReportsTableData) => (
                        <CellComponent data={item.withholding_tax} measure={currency} />
                    ),
                    align: "right",
                },
            }),
            uuid: {
                label: i18n("auth.app.fleet.driver.uuid"),
                renderCell: (item: ReportsTableData) => (
                    <DefaultCellContent>
                        {item.uuid ? (
                            <div className="text-base">{item.uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </DefaultCellContent>
                ),
                hideByDefault: true,
                tooltip: i18n("auth.app.fleet.driver.uuid_tooltip"),
            },
            partner_uuid: {
                label: i18n("auth.app.fleet.driver.partner_uuid"),
                renderCell: (item: ReportsTableData) => (
                    <DefaultCellContent>
                        {item.partner_uuid ? (
                            <div className="text-base">{item.partner_uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </DefaultCellContent>
                ),
                hideByDefault: true,
                tooltip: i18n("auth.app.fleet.driver.partner_uuid_tooltip"),
            },
        };
    }, [currency, i18n, goToDriverProfile, CellComponent, configuration, vatTranslation]);
}
