import {FC, useCallback, useContext, useEffect, useState} from "react";

import ConfirmActionDialog, {
    ConfirmActionDialogVariant,
} from "common/components/confirmActionDialog/ConfirmActionDialog";
import {NotificationType} from "common/components/notifications/TopLeftCorner";
import {FetchStatus} from "common/hooks/useFetch";
import {NotificationContext, NotificationContextValue} from "common/services/notificationProvider";

import {Button} from "@bolteu/kalep-react";

interface ToggleDeactivationButtonProps {
    confirmButtonText: string;
    confirmButtonVariant: ConfirmActionDialogVariant;
    titleText: string;
    descriptionText: string;
    snackbarText: string;
    confirmAction: () => void;
    confirmActionStatus: FetchStatus;
    onSuccessAction: () => void;
}

const ToggleDeactivationButton: FC<ToggleDeactivationButtonProps> = ({
    confirmButtonText,
    confirmButtonVariant,
    titleText,
    descriptionText,
    snackbarText,
    confirmAction,
    confirmActionStatus,
    onSuccessAction,
}) => {
    const {setNotification} = useContext(NotificationContext) as NotificationContextValue;

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleButtonClick = useCallback(() => {
        setIsDialogOpen(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    useEffect(() => {
        if (confirmActionStatus === FetchStatus.Success) {
            setNotification({text: snackbarText, type: NotificationType.SUCCESS});
            onSuccessAction();
        }
        if (confirmActionStatus === FetchStatus.Success || confirmActionStatus === FetchStatus.Error) {
            setIsDialogOpen(false);
        }
    }, [confirmActionStatus, setNotification, snackbarText, onSuccessAction]);

    const isConfirmActionLoading = confirmActionStatus === FetchStatus.Loading;

    return (
        <>
            <Button disabled={isConfirmActionLoading} onClick={handleButtonClick} variant="secondary">
                {confirmButtonText}
            </Button>
            <ConfirmActionDialog
                confirmButtonText={confirmButtonText}
                confirmButtonVariant={confirmButtonVariant}
                titleText={titleText}
                descriptionText={descriptionText}
                isOpen={isDialogOpen}
                isLoading={isConfirmActionLoading}
                closeDialog={handleCloseDialog}
                confirmButtonAction={confirmAction}
            />
        </>
    );
};

export default ToggleDeactivationButton;
