import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

export function shouldDisplayToggleDeactivationButton(
    entityPortalStatus: EntityPortalStatus,
    isDeactivatedByFleetOwner: boolean,
): boolean {
    const isBLocked = entityPortalStatus === EntityPortalStatus.Blocked;
    const isDeactivatedNotByFleetOwner =
        entityPortalStatus === EntityPortalStatus.Deactivated && !isDeactivatedByFleetOwner;

    if (isBLocked || isDeactivatedNotByFleetOwner) {
        return false;
    }

    return true;
}
