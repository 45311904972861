import {ChangeEvent, FC, useCallback, useMemo, useRef} from "react";

import {AllowedExtensions, FileTypes, getFileTypeError, getMimeTypes, MimeTypes} from "common/constants/mimeTypes";
import {useI18n} from "common/hooks/useI18n";

import {Button} from "@bolteu/kalep-react";

import {useFileDragAndDrop} from "./useFileDragAndDrop";

export interface FileUploadProps {
    onFileUpload: (file: File) => void;
    setErrorMessage: (msg: string) => void;
    errorMessage: string;
    fileTypes: FileTypes[];
    maxFileSizeB?: number;
}

const FileUpload: FC<FileUploadProps> = ({onFileUpload, setErrorMessage, errorMessage, fileTypes, maxFileSizeB}) => {
    const {i18n} = useI18n();

    const fileInput = useRef<HTMLInputElement>(null);
    const formOverlayRef = useRef<HTMLDivElement>(null);

    const checkAndUploadFile = useCallback(
        (file: File) => {
            setErrorMessage("");
            if (file.size === 0) {
                setErrorMessage(i18n("auth.app.fleet.car-rentals.upload_file.file_error.file_empty"));
                return;
            }

            if (maxFileSizeB && file.size > maxFileSizeB) {
                setErrorMessage(
                    i18n("upload_file.file_too_big", {
                        fileSize: maxFileSizeB / 1_000_000,
                        sizeUnit: "MB",
                    }) as never as string,
                );
                return;
            }

            const extension = file.name.split(".").pop();
            const mimeTypes = getMimeTypes(fileTypes);
            if (
                !mimeTypes.includes(file.type as MimeTypes) &&
                extension &&
                !Object.values(AllowedExtensions).includes(extension as AllowedExtensions)
            ) {
                setErrorMessage(getFileTypeError(fileTypes, i18n));
                return;
            }

            onFileUpload(file);
        },
        [setErrorMessage, maxFileSizeB, fileTypes, onFileUpload, i18n],
    );

    const handleFileUpload = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files?.length) {
                return;
            }

            checkAndUploadFile(event.target.files[0]);
            if (fileInput.current) {
                fileInput.current.value = "";
            }
        },
        [checkAndUploadFile],
    );

    const handleButtonClick = useCallback(() => {
        fileInput.current?.click();
    }, [fileInput]);

    const acceptFileTypes = useMemo(() => {
        if (!fileTypes) {
            return "";
        }
        return getMimeTypes(fileTypes).join(",");
    }, [fileTypes]);

    const {handleDragEnter, handleDragLeave, handleDragOver, handleDrop, isFileOverForm} = useFileDragAndDrop({
        setErrorMessage,
        checkAndUploadFile,
        formOverlayRef,
    });

    let uploadFieldTitle = i18n("upload_file.title");
    if (errorMessage && errorMessage.length) {
        uploadFieldTitle = i18n("auth.app.fleet.car-rentals.upload_file.title_error");
    }

    let uploadFieldDescription = i18n("auth.app.fleet.car-rentals.upload_file.description");
    if (errorMessage.length) {
        uploadFieldDescription = i18n("auth.app.fleet.car-rentals.upload_file.description_error");
    }

    return (
        <div className="relative w-full">
            <form
                className={`relative flex min-h-[192px] max-w-md flex-col items-center justify-between gap-1 rounded-lg border-2 border-dashed py-10 px-6 ${
                    errorMessage.length !== 0 ? "border-red-700 bg-red-100 text-red-700" : "border-neutral-300"
                }`}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {isFileOverForm && (
                    <div
                        className="absolute top-[-2px] left-[-2px] z-10 flex h-[calc(100%+4px)] w-[calc(100%+4px)] items-center justify-center rounded-lg border-2 border-dashed border-green-700 bg-green-100 py-10 px-6 text-green-700"
                        ref={formOverlayRef}
                    >
                        {i18n("upload_file.title")}
                    </div>
                )}

                <p className={errorMessage.length !== 0 ? "font-semibold text-red-700" : "text-neutral-900"}>
                    {uploadFieldTitle}
                </p>
                <p className={errorMessage.length !== 0 ? "text-red-700" : "mb-3 text-sm text-neutral-700"}>
                    {uploadFieldDescription}
                </p>
                <input
                    type="file"
                    id="file-upload-input"
                    accept={acceptFileTypes}
                    onChange={handleFileUpload}
                    className="hidden"
                    ref={fileInput}
                />
                <Button
                    variant={errorMessage.length !== 0 ? "danger" : "secondary"}
                    size="sm"
                    onClick={handleButtonClick}
                >
                    {i18n("auth.app.fleet.car-rentals.upload_file.button")}
                </Button>
            </form>
            {errorMessage.length !== 0 && <p className="mt-2 text-red-700">{errorMessage}</p>}
        </div>
    );
};

export default FileUpload;
