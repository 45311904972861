import {FC, useCallback, useEffect} from "react";

import {GoogleMaps} from "common/geo/components/google-maps/GoogleMaps";
import {MapHelper} from "common/geo/components/google-maps/MapHelper";
import {BoundsPadding, Point} from "common/geo/components/google-maps/types";
import config from "config";
import {ClickEventValue} from "google-map-react";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import styles from "../style.module.css";
import {DriverInfo} from "../types";
import {useMapView} from "./hooks/useMapView";

interface ResponsiveLiveMapProps {
    drivers: FleetOwnerPortalService.DriverForFleetOwner[];
    selectedDriver: DriverInfo | null;
    previewDriverId: number | null;
    isMobileView: boolean;
    isMapView: boolean;
    onDriverSelected: (driver: FleetOwnerPortalService.DriverForFleetOwner) => void;
    onDriverPreviewSelected?: (driverId: number | null) => void;
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null;
}

export const ResponsiveMapView: FC<ResponsiveLiveMapProps> = ({
    drivers,
    selectedDriver,
    previewDriverId,
    isMobileView,
    isMapView,
    onDriverSelected,
    onDriverPreviewSelected,
    lastDropoffAndWaitingTime,
}) => {
    const {
        defaultMapConfig,
        hasUserRecentlyChangedMap,
        markers,
        googleMapsApi,
        automaticZoomLevel,
        setBounds,
        getBbox,
        onGoogleApiLoaded,
        handleZoomEnd,
        handleOnDrag,
    } = useMapView({
        drivers,
        onDriverSelected,
        selectedDriver,
        isMobileView,
        previewDriverId,
        onDriverPreviewSelected,
        lastDropoffAndWaitingTime,
    });

    const setDriversIntoView = useCallback(() => {
        if (googleMapsApi && drivers.length && (!hasUserRecentlyChangedMap || (isMobileView && selectedDriver))) {
            let points: Point[] = selectedDriver && !isMobileView ? [selectedDriver.driver] : [...drivers];
            if (isMobileView && isMapView && selectedDriver) {
                points = [selectedDriver.driver];
            }
            let mapPadding: BoundsPadding = {left: 500, top: 75, bottom: 75};
            if (isMobileView) {
                mapPadding = 10;
            }
            if (selectedDriver?.order) {
                points.push({lat: selectedDriver.order.lat, lng: selectedDriver.order.lng});
                if (selectedDriver.order.destination_lat && selectedDriver.order.destination_lng) {
                    points.push({
                        lat: selectedDriver.order.destination_lat,
                        lng: selectedDriver.order.destination_lng,
                    });
                }
            }
            MapHelper.fitBounds(googleMapsApi, points, mapPadding, !isMobileView);
            automaticZoomLevel.current = MapHelper.getZoom(googleMapsApi);
            setBounds(getBbox());
        }
    }, [
        googleMapsApi,
        drivers,
        hasUserRecentlyChangedMap,
        isMobileView,
        selectedDriver,
        isMapView,
        automaticZoomLevel,
        setBounds,
        getBbox,
    ]);

    useEffect(() => {
        setDriversIntoView();
    }, [setDriversIntoView]);

    const googleMapsOnClick = useCallback(
        (value: ClickEventValue) => {
            // google-maps library typed value.event as any
            const clickEvent = value.event as {target: {id: string}};
            const elementId = clickEvent.target.id;
            if (
                previewDriverId &&
                typeof elementId === "string" &&
                !elementId.includes("driver-marker-") &&
                onDriverPreviewSelected
            ) {
                onDriverPreviewSelected(null);
            }
        },
        [onDriverPreviewSelected, previewDriverId],
    );

    const shouldMockGoogleMaps = config.isStageMockoon;
    if (shouldMockGoogleMaps) {
        return <iframe title="blank page" className="h-[calc(100dvh-56px)] w-screen" />;
    }

    return (
        <div className={styles["live-map"]}>
            <GoogleMaps
                options={{
                    mapTypeId: "roadmap",
                    clickableIcons: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    maxZoom: 14,
                    disableDefaultUI: isMobileView,
                    zoomControlOptions: {position: 3},
                }}
                onClick={googleMapsOnClick}
                defaultCenter={defaultMapConfig.center}
                defaultZoom={defaultMapConfig.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={onGoogleApiLoaded}
                onZoomAnimationEnd={handleZoomEnd}
                onDragEnd={handleOnDrag}
            >
                {markers}
            </GoogleMaps>
        </div>
    );
};
