import {useCallback, useEffect, useState} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {Button, TextField} from "@bolteu/kalep-react";

interface BaseEditDisplayFieldProps<T extends string> {
    title: string;
    displayedValue: string;
    updateDisplayedValue: (newValue: string) => void;
    fieldName: T;
    updateFetchFunction: (api: Api, value: Record<T, string>) => Promise<void>;
    closeEditing: () => void;
}

const BaseEditDisplayField = <T extends string>({
    title,
    displayedValue,
    updateDisplayedValue,
    fieldName,
    updateFetchFunction,
    closeEditing,
}: BaseEditDisplayFieldProps<T>) => {
    const [editingValue, setEditingValue] = useState(displayedValue);

    const {i18n} = useI18n();

    const {error, fetch, status} = useFetch(updateFetchFunction);

    useEffect(() => {
        setEditingValue(displayedValue);
    }, [displayedValue]);

    const onSaveButtonClick = useCallback(() => {
        if (editingValue && fetch) {
            fetch({[fieldName]: editingValue} as Record<T, string>);
        }
    }, [editingValue, fieldName, fetch]);

    const onTextFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEditingValue(e.target.value);
    }, []);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            updateDisplayedValue(editingValue);
            closeEditing();
        }
    }, [status, updateDisplayedValue, editingValue, closeEditing]);

    const isLoading = status === FetchStatus.Loading;
    const errorText = error.validationErrors.find((e) => e.property === fieldName)?.error;

    return (
        <div className="border-separator flex flex-col gap-2 border-b border-solid py-3 last:border-0">
            <p className="text-sm font-semibold">{title}</p>
            <div className="flex flex-col justify-between gap-4">
                <TextField
                    fullWidth
                    size="sm"
                    autoFocus
                    placeholder={i18n("common.enter_placeholder", {field_name: title}) as never as string}
                    value={editingValue}
                    onChange={onTextFieldChange}
                    error={Boolean(errorText)}
                    helperText={errorText}
                />
                <div className="flex gap-2 self-end">
                    <Button variant="secondary" size="sm" onClick={closeEditing} disabled={isLoading}>
                        {i18n("auth.app.company-settings.cancel")}
                    </Button>
                    <Button size="sm" onClick={onSaveButtonClick} loading={isLoading}>
                        {i18n("auth.app.company-settings.save")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BaseEditDisplayField;
