import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Pagination as KalepPagination} from "@bolteu/kalep-react";

interface PaginationProps {
    totalCount?: number;
    pageSize: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    disabled?: boolean;
    showText?: boolean;
}

const Pagination: FC<PaginationProps> = ({
    currentPage,
    totalCount,
    pageSize,
    onPageChange,
    disabled,
    showText = false,
}) => {
    const {i18n} = useI18n();

    if (totalCount === undefined || totalCount <= pageSize) {
        return null;
    }

    return (
        <div
            className={`flex flex-col gap-4 sm:flex-row sm:items-center sm:gap-5 ${
                showText ? "sm:justify-between" : "sm:justify-center"
            }`}
        >
            {showText && (
                <p className="t text-sm text-neutral-700">
                    {i18n("auth.app.pagination_showing", {
                        pageStartNum: currentPage * pageSize + (totalCount !== 0 ? 1 : 0),
                        pageEndNum:
                            (currentPage + 1) * pageSize > totalCount ? totalCount : (currentPage + 1) * pageSize,
                        totalRows: totalCount,
                    })}
                </p>
            )}
            <div className="shrink">
                <KalepPagination
                    count={Math.ceil(totalCount / pageSize)}
                    currentPage={currentPage}
                    onClick={onPageChange}
                    aria-label="Driver table pagination"
                    disabled={disabled}
                    overrideClassName="justify-start"
                />
            </div>
        </div>
    );
};

export default Pagination;
