import {useCallback} from "react";

import {ValidatorResult} from "common/constants/types";
import {twMerge} from "tailwind-merge";
import {useId} from "@react-aria/utils";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {CustomFieldProps} from "@bolteu/kalep-form-react";
import {ComboBox, Grid, SelectOption, TextField} from "@bolteu/kalep-react";

import {Hint} from "./Hint";
import {useComboBox} from "./hooks/useComboBox";
import {getErrorMessage} from "./util";

export type ComboboxTextValue = {
    comboboxValue: SelectOption | SelectOption[] | null | undefined;
    textValue: string | undefined;
};

interface ComboboxTextProps extends CustomFieldProps<ComboboxTextValue> {
    fieldConfig: FleetOwnerRegistrationService.TextField;
    validatorErrors: ValidatorResult[];
    onTextInputBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const ComboboxText = (props: ComboboxTextProps) => {
    const {
        value,
        fieldConfig,
        onChange,
        disabled: isDisabled,
        validatorResults,
        validatorErrors,
        onTextInputBlur,
    } = props;
    const validationError = validatorResults?.[0];
    const {comboboxValue, textValue} = value;
    const id = useId();

    const {placeholder, prefix_combo_box: comboBoxConfig, label, required: isRequired, hint} = fieldConfig;

    const cbConfig = comboBoxConfig as FleetOwnerRegistrationService.ComboBoxField;
    const {options} = cbConfig;
    const prefixValidationError = getErrorMessage(cbConfig, validatorErrors);
    const {autocompleteFieldOptions, filterOptions, getOptionLabel, renderOption} = useComboBox(options);

    const onTextChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                value: {
                    comboboxValue,
                    textValue: event.target.value,
                },
            });
        },
        [comboboxValue, onChange],
    );

    const onComboboxChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            onChange({
                value: {
                    textValue,
                    comboboxValue: newValue,
                },
            });
        },
        [onChange, textValue],
    );

    return (
        <div className="w-full">
            <label
                htmlFor={id}
                className={twMerge(
                    "w-fit font-semibold text-sm font-sans",
                    isDisabled ? " text-content-tertiary" : "text-primary",
                    isRequired && "after:text-danger-primary after:content-['_*']",
                )}
            >
                {label}
            </label>
            <Grid columns={12}>
                <Grid.Item colSpan={4}>
                    <ComboBox
                        value={comboboxValue}
                        options={autocompleteFieldOptions}
                        renderOption={renderOption}
                        getOptionLabel={getOptionLabel}
                        filterOptions={filterOptions}
                        onChange={onComboboxChange}
                        error={!!prefixValidationError}
                        fullWidth
                        disabled={isDisabled}
                        clearable={false}
                    />
                </Grid.Item>
                <Grid.Item colSpan={8}>
                    <TextField
                        value={textValue}
                        onChange={onTextChange}
                        placeholder={placeholder}
                        fullWidth
                        id={id}
                        error={!!validationError}
                        disabled={isDisabled}
                        onBlur={onTextInputBlur}
                    />
                </Grid.Item>
            </Grid>
            {prefixValidationError && (
                <div className={twMerge("mt-2 font-sans text-sm text-danger-primary")}>{prefixValidationError}</div>
            )}
            <div className={twMerge("mt-2 font-sans text-sm ", validationError && "text-danger-primary")}>
                {validationError?.error ?? <Hint hint={hint} />}
            </div>
        </div>
    );
};

export {ComboboxText};
