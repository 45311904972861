import {FC, MouseEventHandler} from "react";

export interface TextButtonProps {
    onClick: MouseEventHandler;
    disabled?: boolean;
    text: string;
    className?: string;
}

const TextButton: FC<TextButtonProps> = ({onClick, disabled = false, text, className = ""}) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={`text-content-link-primary hover:text-content-active-link-primary active:scale-975 font-semibold active:duration-100 active:ease-in-out ${className}`}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default TextButton;
