import {useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";

import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";
import IncomingOrdersComponent from "./incoming-orders";
import OrderHistoryComponent from "./order-history";

export enum OrdersTabs {
    OrderHistory = "order-history",
    IncomingOrders = "incoming-orders",
}

const OrdersPage = () => {
    const {i18n} = useI18n();

    useEffect(() => {
        const pageTitle = i18n("auth.app.orders.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const tabs = useTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, OrdersTabs.OrderHistory);

    return (
        <Page>
            <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("auth.app.orders.title")} />
            {activeTab === OrdersTabs.OrderHistory && (
                <div className="font-normal text-neutral-700">{i18n("auth.app.orders.history.lead")}</div>
            )}
            {activeTab === OrdersTabs.IncomingOrders && (
                <div className="font-normal text-neutral-700">{i18n("auth.app.orders.incoming.title-message")}</div>
            )}
            {tabs.length > 1 && <TabSelector />}
            <div>
                {activeTab === OrdersTabs.OrderHistory && <OrderHistoryComponent />}
                {activeTab === OrdersTabs.IncomingOrders && <IncomingOrdersComponent />}
            </div>
        </Page>
    );
};

export default OrdersPage;
