import CalendarScheduling from "assets/icons/calendar-scheduling.svg?react";
import {useI18n} from "common/hooks/useI18n";

import {Typography} from "@bolteu/kalep-react";

export const NoShifts = () => {
    const {i18n} = useI18n();

    return (
        <div className="m-auto mt-10 flex justify-center md:mt-16">
            <div className="flex max-w-xs flex-col items-center gap-2">
                <CalendarScheduling className="mb-2" />
                <Typography fontSize="text-lg" fontWeight="semibold">
                    {i18n("auth.app.fleet.work_shifts.list_placeholder.title_shifts")}
                </Typography>
                <Typography color="secondary" align="center">
                    {i18n("auth.app.fleet.work_shifts.list_placeholder.description")}
                </Typography>
            </div>
        </div>
    );
};
