import {useCallback, useEffect, useMemo, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerReportingService, FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Drawer, IconButton, SelectOption} from "@bolteu/kalep-react";
import {Bin, Filters} from "@bolteu/kalep-react-icons";

import City = FleetOwnerService.City;
import SearchCategoryGroup = FleetOwnerReportingService.SearchCategoryGroup;
import OrderTryState = FleetOwnerReportingService.OrderTryState;

export type OrderFilters = Pick<
    FleetOwnerReportingService.GetOrdersV2SearchRequest,
    "orders_state_statuses" | "city_id" | "search_category_group"
>;

export const defaultOrdersFilters: OrderFilters = {
    orders_state_statuses: [
        OrderTryState.CLIENT_DID_NOT_SHOW,
        OrderTryState.FINISHED,
        OrderTryState.CLIENT_CANCELLED,
        FleetOwnerReportingService.OrderTryStateCustom.DRIVER_CANCELLED_AFTER_ACCEPT,
        OrderTryState.DRIVER_DID_NOT_RESPOND,
        OrderTryState.DRIVER_REJECTED,
    ],
    city_id: undefined,
    search_category_group: undefined,
};

export const isSelectedFilterDefault = (
    selectedStatuses: FleetOwnerReportingService.OrderTryStatePortal[] | undefined,
    selectedCategoryGroup: string | undefined,
    selectedCity: number | undefined,
) => {
    return (
        selectedStatuses?.length === defaultOrdersFilters.orders_state_statuses.length &&
        defaultOrdersFilters.orders_state_statuses.every((state) => selectedStatuses.includes(state)) &&
        selectedCategoryGroup === defaultOrdersFilters.search_category_group &&
        selectedCity === defaultOrdersFilters.city_id
    );
};

const orderTryFinalStates = [
    OrderTryState.CLIENT_CANCELLED,
    OrderTryState.DRIVER_DID_NOT_RESPOND,
    OrderTryState.DRIVER_REJECTED,
    OrderTryState.CLIENT_DID_NOT_SHOW,
    OrderTryState.FINISHED,
    FleetOwnerReportingService.OrderTryStateCustom.DRIVER_CANCELLED_AFTER_ACCEPT,
];

interface OrdersFilterProps {
    filters: OrderFilters;
    setFilters: (filters: OrderFilters) => void;
    cities: City[];
    category_groups: SearchCategoryGroup[];
}

const OrdersFilter = ({filters, setFilters, cities, category_groups}: OrdersFilterProps) => {
    const {i18n} = useI18n();
    const [isShowDialog, setShowDialog] = useState(false);

    const {Form, Button, setData, SelectField, useFieldValue} = useForm(filters);

    const selectedStatuses = useFieldValue("orders_state_statuses");
    const selectedCity = useFieldValue("city_id");
    const selectedCategoryGroup = useFieldValue("search_category_group");

    useEffect(() => {
        setData(filters);
    }, [setData, filters]);

    const onEditClick = useCallback(() => {
        setShowDialog(true);
    }, [setShowDialog]);

    const onCloseClick = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);

    const handleClearFiltersButtonClick = useCallback(() => {
        setData(defaultOrdersFilters);
    }, [setData]);

    const defaultOption = {
        value: undefined as unknown as null, // undefined works but not allowed in Kalep types
        title: i18n("common.all"),
    };

    const cityOptions = useMemo(
        () =>
            cities.map((city) => ({
                value: city.city_id,
                title: city.name,
            })),
        [cities],
    );

    const categoryOptions = useMemo(
        () =>
            category_groups.map((categoryGroup) => ({
                value: categoryGroup.id,
                title: categoryGroup.name,
            })),
        [category_groups],
    );

    const citySelectOptions: SelectOption[] = [defaultOption, ...cityOptions];
    const categorySelectOptions: SelectOption[] = [defaultOption, ...categoryOptions];
    const statesSelectOptions: SelectOption[] = orderTryFinalStates.map((state) => ({
        value: state,
        title: i18n(`order.status.${state}`),
    }));

    const statusesTextList = useMemo(
        () => selectedStatuses?.map((status) => i18n(`order.status.${status}`)).join(", "),
        [selectedStatuses, i18n],
    );

    const handleFormSubmit = useCallback(
        async ({data: formData}: FormState<OrderFilters>) => {
            setFilters(formData);
            setShowDialog(false);
        },
        [setFilters],
    );

    const isClearFiltersButtonShown = useMemo(() => {
        return !isSelectedFilterDefault(selectedStatuses, selectedCategoryGroup, selectedCity);
    }, [selectedStatuses, selectedCategoryGroup, selectedCity]);

    return (
        <div>
            <Form onSubmit={handleFormSubmit}>
                <ResponsiveDrawer
                    onRequestClose={onCloseClick}
                    isOpen={isShowDialog}
                    title={i18n("auth.app.fleet.campaigns.filters.title")}
                >
                    <Drawer.Content>
                        <div className="flex flex-col gap-4">
                            <SelectField
                                name="city_id"
                                label={i18n("auth.app.fleet.campaigns.filters.location.title")}
                                placeholder={i18n("common.all")}
                                options={citySelectOptions}
                                fullWidth
                            />
                            <SelectField
                                name="orders_state_statuses"
                                multiple
                                label={i18n("auth.app.fleet.campaigns.filters.status.title")}
                                options={statesSelectOptions}
                                placeholder={statusesTextList}
                            />
                            <SelectField
                                name="search_category_group"
                                label={i18n("auth.app.fleet.live-map.category")}
                                placeholder={i18n("common.all")}
                                options={categorySelectOptions}
                            />
                        </div>
                    </Drawer.Content>
                    <Drawer.Footer>
                        <Button submit>{i18n("common.apply")}</Button>
                        <Button variant="secondary" onClick={onCloseClick}>
                            {i18n("auth.app.company-settings.cancel")}
                        </Button>
                        {isClearFiltersButtonShown && (
                            <Button variant="secondary" onClick={handleClearFiltersButtonClick} startIcon={<Bin />}>
                                {i18n("common.filters.clear_filters")}
                            </Button>
                        )}
                    </Drawer.Footer>
                </ResponsiveDrawer>
            </Form>
            <IconButton
                icon={<Filters />}
                aria-label="Filter orders"
                onClick={onEditClick}
                variant="filled"
                size="md"
                shape="square"
                overrideClassName="flex"
            />
        </div>
    );
};

export default OrdersFilter;
