import {useCallback} from "react";

import FilteredBy, {RenderChips} from "common/components/table/FilteredBy";
import {UnreachableCode} from "common/components/util/UnreachableCode";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";

import {DrawerFilters, TabName} from "..";
import {getDefaultFilters} from "../utils/getDefaultFilters";
import {isFilterValueDefault} from "../utils/isFilterValueDefault";

import City = FleetOwnerService.City;

interface FilteredByProps {
    activeTab: TabName;
    cities: City[];
    filters: DrawerFilters;
    setFilters: (filters: DrawerFilters) => void;
}

const CampaignsFilteredBy = ({activeTab, cities, filters, setFilters}: FilteredByProps) => {
    const {i18n} = useI18n();

    const defaultFilters = getDefaultFilters(activeTab);

    const cityName = cities.find((city) => city.city_id === filters.city_id)?.name;

    const renderChips = useCallback(
        (getFilterOnRemove: RenderChips<DrawerFilters>) =>
            Object.entries(filters).map(([key, value]) => {
                const filtersKey = key as keyof DrawerFilters;
                const onRemove = getFilterOnRemove(filtersKey);

                if (isFilterValueDefault(activeTab, filtersKey, value)) {
                    return null;
                }

                let label = "";
                switch (filtersKey) {
                    case "statuses": {
                        const statuses = (value as DrawerFilters["statuses"]) || [];
                        label = statuses
                            .map((status) => i18n(`auth.app.fleet.campaigns.filters.status.options.${status}`))
                            .join(", ");
                        break;
                    }
                    case "city_id":
                        label = cityName || "";
                        break;
                    case "polygon_location":
                        label = i18n("auth.app.fleet.campaigns.filters.hide_special_locations.value");
                        break;
                    default:
                        return UnreachableCode.never(filtersKey, null);
                }

                return <Chip key={key} size="md" onRemove={onRemove} label={label} />;
            }),
        [filters, i18n, activeTab, cityName],
    );

    return (
        <FilteredBy
            setFilters={setFilters}
            currentFilterValues={filters}
            initialFilterValues={defaultFilters}
            renderChips={renderChips}
        />
    );
};

export default CampaignsFilteredBy;
