import {twMerge} from "tailwind-merge";
import {useId} from "@react-aria/utils";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {RadioFieldOption} from "@bolteu/kalep-form-react/build/DataContainer/fields/RadioField";

import {Hint} from "./Hint";
import {UseFormValueType} from "./types";

interface RadioProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.RadioGroupField;
    error?: string;
}

const Radio = ({useFormValue, fieldConfig, error}: RadioProps) => {
    const {RadioField} = useFormValue;
    const {name, label, hint, required: isRequired, options, disabled: isDisabled} = fieldConfig;
    const id = useId();

    const radioOptions: RadioFieldOption[] = options.map(({value, label: radioLabel}) => ({
        value,
        title: radioLabel ?? "",
    }));

    return (
        <div>
            <label
                htmlFor={id}
                className={twMerge(
                    "w-fit font-semibold text-sm font-sans",
                    isRequired && "after:text-danger-primary after:content-['_*']",
                )}
            >
                {label}
            </label>
            <div className="mt-2 mb-4">
                <Hint hint={hint} />
            </div>
            <RadioField name={name as never} label="" options={radioOptions} disabled={isDisabled} />
            <div className={twMerge("mt-2 font-sans text-sm ", error && "text-danger-primary")}>{error}</div>
        </div>
    );
};

export {Radio};
