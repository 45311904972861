import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";

import styles from "../../vehicleColor.module.css";
import {getPublicApplicationStatus} from "../util/publicApplicationStatus";

interface Props {
    application: CarApplicationService.GetCarApplicationResponse;
}

const Header = ({application}: Props) => {
    const {i18n} = useI18n();
    const status = getPublicApplicationStatus(application.status);

    const vehicleColorCss =
        application.color === "white" ? "border border-neutral-300" : styles[`bg-vehicle-${application.color}`];

    return (
        <>
            <h1 className="text-primary text-5xl font-semibold">
                {application.model} ({application.year})
            </h1>
            <div className="mt-4 mb-8 flex flex-wrap items-center justify-start gap-4">
                <span className="rounded-sm bg-neutral-200 py-[2px] px-2 font-semibold">{application.reg_number}</span>
                {application.color !== null && application.color.length !== 0 && (
                    <div className="flex items-center gap-2 text-sm">
                        <div className={`h-4 w-4 rounded-full ${vehicleColorCss}`} />
                        <span>{i18n(`vehicle.color.${application.color}` as TranslationKeys)}</span>
                    </div>
                )}
                {status && (
                    <Chip
                        label={i18n(`auth.app.fleet.vehicle-application.status_badges.${status.label}`)}
                        color={status.color}
                        size="sm"
                    />
                )}
            </div>
        </>
    );
};

export default Header;
