import {useCallback, useEffect, useState} from "react";

import {UnreachableCode} from "common/components/util/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Direction, OrderBy} from "@bolteu/kalep-table-react";

import {getUniqueColumnIndex} from "../utils/getUniqueColumnIndex";

import Column = FleetOwnerPortalService.Column;
import ColumnType = FleetOwnerPortalService.ColumnType;
import TableCell = FleetOwnerPortalService.TableCell;

export interface TableRow {
    id: number;
    [key: string]: TableCell | number;
}

// TODO: Remove when API will return default OrderBy (https://taxify.atlassian.net/browse/DRX-9256)
function getDefaultOrderBy(
    columns: Column[],
    defaultOrderColumnType: ColumnType,
    defaultOrderDirection: Direction,
): OrderBy<TableRow> | undefined {
    const orderColumn = columns.find((column) => column.type === defaultOrderColumnType);

    if (!orderColumn) {
        return undefined;
    }

    return {
        key: getUniqueColumnIndex(defaultOrderColumnType, orderColumn.title),
        direction: defaultOrderDirection,
    };
}

function mapColumnsToTableRows(columns: Column[]): TableRow[] {
    const tableRows: TableRow[] = [];
    columns.forEach(({type, title, rows}) => {
        const uniqueColumnIndex = getUniqueColumnIndex(type, title);
        for (let i = 0; i < rows.length; i++) {
            if (!tableRows[i]) {
                tableRows[i] = {id: i};
            }
            tableRows[i][uniqueColumnIndex] = rows[i];
        }
    });
    return tableRows;
}

function extractValueFromCell(cell: TableCell | number): string | number | null {
    if (typeof cell === "number") {
        return cell;
    }

    switch (cell.type) {
        case ColumnType.TEXT:
            return !cell.value || Number.isNaN(Number(cell.value)) ? cell.value : Number(cell.value);
        case ColumnType.ENTITY_NAME:
            return cell.value + (cell.subtext || "");
        case ColumnType.BOOLEAN:
            return cell.value ? 1 : 0;
        case ColumnType.CHIP:
            return cell.value.label;
        case ColumnType.PERIOD:
            // Sort by start date first, then by end date
            return cell.value.start * 10 + cell.value.end;
        case ColumnType.ICON:
            return cell.value.kalep_name;
        default:
            return UnreachableCode.never(cell, cell);
    }
}

function compareRows(left: TableRow, right: TableRow, orderBy: OrderBy<TableRow>): number {
    const leftVal = extractValueFromCell(left[orderBy.key]);
    const rightVal = extractValueFromCell(right[orderBy.key]);
    const directionMultiplier = orderBy.direction === "ASC" ? 1 : -1;

    if (leftVal === rightVal) {
        return 0;
    }

    if (rightVal === null || (leftVal !== null && leftVal > rightVal)) {
        return directionMultiplier;
    }
    if (leftVal === null || (rightVal !== null && leftVal < rightVal)) {
        return -1 * directionMultiplier;
    }
    return 0;
}

function sortRows(rows: TableRow[], orderBy: OrderBy<TableRow> | undefined): TableRow[] {
    if (!orderBy || orderBy.key === "id") {
        return rows;
    }

    return [...rows].sort((left, right) => compareRows(left, right, orderBy));
}

interface TableRows {
    sortedRows: TableRow[];
    orderBy: OrderBy<TableRow> | undefined;
    handleOrderByChange: (orderBy: OrderBy<TableRow> | undefined) => void;
}

export function useTableRows(
    columns: Column[],
    defaultOrderColumnType: ColumnType,
    defaultOrderDirection: Direction,
): TableRows {
    const [sortedRows, setSortedRows] = useState<TableRow[]>([]);
    const [orderBy, setOrderBy] = useState<OrderBy<TableRow>>();

    const handleOrderByChange = useCallback((newOrderBy: OrderBy<TableRow> | undefined) => {
        setOrderBy(newOrderBy);
        setSortedRows((prevSortedRows) => sortRows(prevSortedRows, newOrderBy));
    }, []);

    useEffect(() => {
        const defaultOrderBy = getDefaultOrderBy(columns, defaultOrderColumnType, defaultOrderDirection);
        const newRows = mapColumnsToTableRows(columns);
        const newSortedRows = sortRows(newRows, defaultOrderBy);

        setOrderBy(defaultOrderBy);
        setSortedRows(newSortedRows);
    }, [columns, defaultOrderColumnType, defaultOrderDirection]);

    return {
        sortedRows,
        orderBy,
        handleOrderByChange,
    };
}
