import {FC} from "react";

import {useTailwindViewport} from "common/hooks/useTailwindViewport";

import Header, {HeaderSize} from "./Header";

export enum ResponsiveHeaderType {
    MainPage = "MainPage",
    InnerPage = "InnerPage",
}

export interface ResponsiveHeaderProps {
    type: ResponsiveHeaderType;
    text: string;
    disabled?: boolean;
}

const getHeaderSize = (type: ResponsiveHeaderType, isMobileView: boolean): HeaderSize => {
    switch (type) {
        case ResponsiveHeaderType.MainPage:
            return isMobileView ? HeaderSize.Medium : HeaderSize.Large;
        case ResponsiveHeaderType.InnerPage:
            return isMobileView ? HeaderSize.Small : HeaderSize.Medium;
        default:
            return HeaderSize.Large;
    }
};

const ResponsiveHeader: FC<ResponsiveHeaderProps> = ({type, text, disabled}: ResponsiveHeaderProps) => {
    const viewport = useTailwindViewport();
    const headerSize = getHeaderSize(type, viewport === "sm");

    return <Header disabled={disabled} size={headerSize} text={text} />;
};

export {ResponsiveHeader};
