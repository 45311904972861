import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {VehicleTabs} from "..";

export function useTabSelectorOptions(
    isLoading: boolean,
    vehiclesCount: number,
    applicationsCount: number,
): Tab<VehicleTabs>[] {
    const {i18n} = useI18n();

    if (isLoading) {
        return [];
    }

    const tabs: Tab<VehicleTabs>[] = [
        {
            id: VehicleTabs.Vehicles,
            title: `${i18n("auth.app.fleet.vehicles.title")}: ${vehiclesCount}`,
            trackingEventTabName: TabNameEvent.VehiclesPageVehicles,
        },
        {
            id: VehicleTabs.Applications,
            title: `${i18n("auth.app.fleet.vehicles.applications")}: ${applicationsCount}`,
            trackingEventTabName: TabNameEvent.VehiclesPageApplications,
        },
    ];

    return tabs;
}
