import {useMemo} from "react";

import CashlessIcon from "assets/icons/cashless.svg?react";
import {CustomizableAccordionTableHeaders} from "common/components/table/AccordionTableWithCustomizableColumns";
import {KalepAccordionTableDataAndMeasuresCell} from "common/components/table/DataAndMeasuresCell";
import {KalepAccordionTableHoursAndMinutesCell} from "common/components/table/HoursAndMinutesCell";
import {Dash} from "common/constants";
import {useI18n} from "common/hooks/useI18n";

import {Tooltip} from "@bolteu/kalep-react";
import {Star} from "@bolteu/kalep-react-icons";

import {OnlineHoursConfig} from "../..";
import {DriverTableData} from "../../components/DriversTable";

export function useAccordionTableColumns(config: OnlineHoursConfig) {
    const {i18n} = useI18n();

    return useMemo<CustomizableAccordionTableHeaders<DriverTableData>>(() => {
        return {
            cash_rides: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.cash_rides"),
                direction: "horizontal",
                renderCell: (item: DriverTableData) => {
                    return (
                        !item.has_cash_payment && (
                            <div className="flex items-center justify-center">
                                <Tooltip content={i18n(`driver.cash_rides_disabled`)} placement="bottom">
                                    <div>
                                        <CashlessIcon />
                                    </div>
                                </Tooltip>
                            </div>
                        )
                    );
                },
            },
            driver_score: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.driver_score"),
                direction: "horizontal",
                renderCell: (item: DriverTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={item.driver_score}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            finished_orders: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.finished_orders"),
                direction: "horizontal",
                renderCell: (item: DriverTableData) => (
                    <div className={item.finished_orders === Dash.EM ? "text-content-tertiary" : ""}>
                        {item.finished_orders}
                    </div>
                ),
            },
            total_acceptance: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.total_acceptance"),
                direction: "horizontal",
                tooltip: i18n("auth.app.fleet.reports.tooltip.total_acceptance"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: DriverTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={item.total_acceptance}
                        measure={i18n("common.percentage")}
                    />
                ),
                hideByDefault: true,
            },
            required_acceptance: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.non_optional_acceptance"),
                direction: "horizontal",
                tooltip: i18n("auth.app.fleet.reports.tooltip.non_optional_acceptance"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: DriverTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={item.required_acceptance}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            ...(config.shouldShowOnlineHours && {
                online_hours: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.online_hours"),
                    direction: "horizontal",
                    renderCell: (item: DriverTableData) => (
                        <KalepAccordionTableHoursAndMinutesCell
                            valueInMinutes={item.online_min}
                            hoursLabel={i18n("common.hour_abbreviation")}
                            minutesLabel={i18n("common.minute_abbreviation")}
                        />
                    ),
                },
            }),
            ...(config.shouldShowWaitingOrders && {
                waiting_orders_min: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.waiting_orders"),
                    direction: "horizontal",
                    tooltip: i18n("auth.app.fleet.engagement-dashboard.header.waiting_orders_tooltip"),
                    tooltipProps: {
                        placement: "bottom",
                    },
                    renderCell: (item: DriverTableData) => (
                        <KalepAccordionTableHoursAndMinutesCell
                            valueInMinutes={item.waiting_orders_min}
                            hoursLabel={i18n("common.hour_abbreviation")}
                            minutesLabel={i18n("common.minute_abbreviation")}
                        />
                    ),
                },
            }),
            ...(config.shouldShowHasOrder && {
                has_order_min: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.has_order"),
                    direction: "horizontal",
                    tooltip: i18n("auth.app.fleet.engagement-dashboard.header.has_order_tooltip"),
                    tooltipProps: {
                        placement: "bottom",
                    },
                    renderCell: (item: DriverTableData) => (
                        <KalepAccordionTableHoursAndMinutesCell
                            valueInMinutes={item.has_order_min}
                            hoursLabel={i18n("common.hour_abbreviation")}
                            minutesLabel={i18n("common.minute_abbreviation")}
                        />
                    ),
                },
            }),
            ...(config.shouldShowUtilization && {
                utilization: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.utilization"),
                    direction: "horizontal",
                    renderCell: (item: DriverTableData) => (
                        <KalepAccordionTableDataAndMeasuresCell
                            data={item.utilization}
                            measure={i18n("common.percentage")}
                        />
                    ),
                },
            }),
            finished_rate: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.finish_rate"),
                direction: "horizontal",
                tooltip: i18n("auth.app.fleet.engagement-dashboard.header.finish_rate_tooltip"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: DriverTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={item.finished_rate}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            completion_rate: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.completion_rate"),
                direction: "horizontal",
                tooltip: i18n("auth.app.fleet.engagement-dashboard.header.completion_rate_tooltip"),
                tooltipProps: {
                    placement: "bottom",
                },
                renderCell: (item: DriverTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={item.completion_rate}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            average_trip_distance: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.average_trip_distance"),
                direction: "horizontal",
                renderCell: (item: DriverTableData) => (
                    <KalepAccordionTableDataAndMeasuresCell
                        data={
                            item.average_trip_distance ? Number(item.average_trip_distance / 1000).toFixed(1) : Dash.EM
                        }
                        measure="km"
                    />
                ),
                hideByDefault: true,
            },
            average_driver_rating: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.average_driver_rating"),
                direction: "horizontal",
                renderCell: (item: DriverTableData) =>
                    item.average_driver_rating ? (
                        <div className="flex items-center">
                            <span className="mr-1">{Number(item.average_driver_rating).toFixed(1)}</span>
                            <Star width={10} height={10} className="text-content-tertiary" />
                        </div>
                    ) : (
                        <div className="text-tertiary">{Dash.EM}</div>
                    ),
                hideByDefault: true,
            },
            uuid: {
                label: i18n("auth.app.fleet.driver.uuid"),
                renderCell: (item: DriverTableData) => (
                    <>
                        {item.uuid ? (
                            <div className="text-base">{item.uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </>
                ),
                hideByDefault: true,
                tooltip: i18n("auth.app.fleet.driver.uuid_tooltip"),
            },
            partner_uuid: {
                label: i18n("auth.app.fleet.driver.partner_uuid"),
                renderCell: (item: DriverTableData) => (
                    <>
                        {item.partner_uuid ? (
                            <div className="text-base">{item.partner_uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </>
                ),
                hideByDefault: true,
                tooltip: i18n("auth.app.fleet.driver.partner_uuid_tooltip"),
            },
        };
    }, [
        config.shouldShowOnlineHours,
        config.shouldShowWaitingOrders,
        config.shouldShowHasOrder,
        config.shouldShowUtilization,
        i18n,
    ]);
}
