import {useEffect} from "react";

import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";

import PageHeader from "./components/PageHeader";
import {ShiftTab, useTabSelectorOptions} from "./hooks/useTabSelectorOptions";
import {Log} from "./log";
import {Planning} from "./planning";

const ShiftsPage = () => {
    const {i18n} = useI18n();
    const tabs = useTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, ShiftTab.Planning);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.work_shifts.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    let Body = null;
    switch (activeTab) {
        case ShiftTab.Log:
            Body = <Log />;
            break;
        case ShiftTab.Planning:
        default:
            Body = <Planning />;
    }

    return (
        <Page>
            <PageHeader tabSelectorComponent={TabSelector} />
            {Body}
        </Page>
    );
};

export default ShiftsPage;
