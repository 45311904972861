import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";

import {Notification, NotificationType} from "common/components/notifications/TopLeftCorner";
import LoadingSpinner from "common/components/spinner";
import TextButton from "common/components/TextButton";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import useMediaQuery from "common/hooks/useMediaQuery";
import {Api} from "common/services/apiProvider";

import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert} from "@bolteu/kalep-react";

import AcceptDialog from "./AcceptDialog";
import MatchCard from "./match-card";
import {MatchesContextProvider} from "./matchesProvider";
import RejectDialog from "./RejectDialog";

const fetchFunction = (api: Api) => api.leadToFleet.leadmatcherAvailableMatches({});

interface MatchesTabProps {
    showNotification: (notification: Notification) => void;
    onLeadAccepted: () => void;
    showInfoPage: () => void;
}

const Matches: FC<MatchesTabProps> = ({showNotification, onLeadAccepted, showInfoPage}) => {
    const {i18n} = useI18n();
    const {data, fetch, status} = useFetch(fetchFunction);

    const matchesState = useContext(MatchesContextProvider);

    const [matches, setMatches] = useState<LeadToFleetService.ActiveMatch[] | null>(null);
    const [rejectedMatch, setRejectedMatch] = useState<LeadToFleetService.ActiveMatch | null>(null);
    const [acceptedMatch, setAcceptedMatch] = useState<LeadToFleetService.ActiveMatch | null>(null);

    const isMdBreakpointSurpassed = useMediaQuery("(min-width: 768px)");
    const isLoading = status === FetchStatus.Loading;

    useEffect(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            setMatches(data.list);
        }
    }, [status, data]);

    const matchesList = useMemo(() => {
        const matchCard = (match: LeadToFleetService.ActiveMatch) => {
            return (
                <MatchCard
                    key={match.matcher_lead_id}
                    match={match}
                    setRejectedMatch={setRejectedMatch}
                    setAcceptedMatch={setAcceptedMatch}
                />
            );
        };

        if (!matches) {
            return null;
        }

        if (!isMdBreakpointSurpassed) {
            return matches?.map((match) => matchCard(match));
        }

        return (
            <>
                <div className="flex flex-col gap-4">
                    {matches?.map((match, i) => (i % 2 === 0 ? matchCard(match) : null))}
                </div>
                <div className="flex flex-col gap-4">
                    {matches?.map((match, i) => (i % 2 !== 0 ? matchCard(match) : null))}
                </div>
            </>
        );
    }, [isMdBreakpointSurpassed, matches]);

    const closeModal = useCallback(() => {
        setAcceptedMatch(null);
        setRejectedMatch(null);
    }, [setRejectedMatch, setAcceptedMatch]);

    const removeMatch = useCallback(
        ({accepted}: {accepted: boolean}) => {
            const selectedMatch = acceptedMatch ?? rejectedMatch;
            if (!selectedMatch) {
                return;
            }
            const {matcher_lead_id: matcherLeadId, name} = selectedMatch;
            const newMatches = matches && matches.filter((match) => match.matcher_lead_id !== matcherLeadId);
            setMatches(newMatches);
            setRejectedMatch(null);
            setAcceptedMatch(null);
            if (accepted !== undefined) {
                showNotification({
                    type: accepted ? NotificationType.SUCCESS : NotificationType.ERROR,
                    text: i18n(`auth.app.fleet.matches.lead_${accepted ? "accepted" : "rejected"}`, {name}),
                });
            }
        },
        [i18n, matches, acceptedMatch, rejectedMatch, showNotification, setMatches],
    );

    const isMatchesListEmpty = !isLoading && matches && !matches.length;
    const hasMatches = !isLoading && matches && matches.length !== 0;

    return (
        <div>
            <RejectDialog
                key={rejectedMatch?.matcher_lead_id}
                show={!!rejectedMatch}
                closeModal={closeModal}
                removeMatch={removeMatch}
                matcherLeadId={rejectedMatch?.matcher_lead_id}
            />
            <AcceptDialog
                key={acceptedMatch?.matcher_lead_id}
                show={!!acceptedMatch}
                closeModal={closeModal}
                removeMatch={removeMatch}
                matcherLeadId={acceptedMatch?.matcher_lead_id}
                onLeadAccepted={onLeadAccepted}
            />
            <LoadingSpinner show={isLoading} showLoadingText parentClassName="mt-9" />
            {isMatchesListEmpty && <p className="mt-4 p-5">{i18n("auth.app.fleet.matches.no_matches")}</p>}
            {hasMatches && (
                <>
                    <div className="mb-6 flex max-w-3xl flex-col gap-6">
                        <p className="text-neutral-700">
                            <span>{`${i18n("auth.app.fleet.driver-registrations.proposed-candidates.info")} `}</span>
                            <TextButton
                                onClick={showInfoPage}
                                text={i18n("auth.app.fleet.driver-registrations.learn-more")}
                            />
                        </p>
                        {matchesState?.isInactiveWithMatches && (
                            <Alert
                                severity="warning"
                                title={i18n("auth.app.fleet.matching_removed_banner.title")}
                                overrideClassName="text-base"
                            >
                                {i18n("auth.app.fleet.matching_removed_banner.description")}
                            </Alert>
                        )}
                    </div>
                    <div className="grid grid-cols-1 content-start items-start gap-4 overflow-y-auto pb-7 md:max-w-screen-2xl md:grid-cols-2">
                        {matchesList}
                    </div>
                </>
            )}
        </div>
    );
};

export default Matches;
