import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useCountdown} from "common/hooks/useCountdown";
import {useI18n} from "common/hooks/useI18n";
import {ResponseCodes} from "common/ResponseCodes";
import {FleetOwnerVerificationNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {isServerError, isValidationError} from "common/util/isErrorType";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ProgressBar} from "@bolteu/kalep-react";

import {useVerificationNavigations} from "./hooks/useVerificationNavigations";
import {SubmitOtpDynamicForm} from "./SubmitOtpDynamicForm";

interface WaitSecondsProps {
    waitSeconds: number;
}

const WaitSeconds = ({waitSeconds}: WaitSecondsProps) => {
    const {i18n} = useI18n();
    const {seconds} = useCountdown(waitSeconds);

    const Caption = () => <div className="text-sm">{i18n("login.phone.updatingAndSendingCode")}</div>;

    return (
        <ProgressBar
            aria-label={i18n("login.phone.updatingAndSendingCode")}
            caption={<Caption />}
            value={((waitSeconds - seconds) / waitSeconds) * 100}
        />
    );
};

interface UpdatePhoneProps {
    onBack: () => void;
    onSuccess: () => void;
}

export const UpdatePhone = ({onBack, onSuccess}: UpdatePhoneProps) => {
    const {i18n} = useI18n();
    const params = useParams();
    const [otpForm, setOtpForm] = useState<FleetOwnerVerificationNoAuthService.GetOtpFormResponse | undefined>();
    const [errorMessage, setErrorMessage] = useState("");
    const [waitSeconds, setWaitSeconds] = useState<number | undefined>(undefined);
    const navigate = useNavigate();
    const {get2faNavigationTo} = useVerificationNavigations();

    const attemptKey = params.key;

    useEffect(() => {
        const getOtpForm = async () => {
            try {
                const response = await FleetOwnerVerificationNoAuthApiClient.getOtpForm({
                    attempt_key: attemptKey ?? "",
                    update_phone: true,
                });
                setOtpForm(response);
            } catch (error) {
                if (isValidationError(error)) {
                    setErrorMessage(i18n("api.error.FLEET_OWNER_VERIFICATION_PASSWORD_INVALID_KEY"));
                } else if (
                    isServerError(error) &&
                    error.response.code === ResponseCodes.TWO_FACTOR_AUTH_LOGIN_REQUIRED
                ) {
                    navigate(get2faNavigationTo(attemptKey ?? ""));
                } else {
                    setErrorMessage(i18n("api.default_error"));
                }
            }
        };
        getOtpForm();
    }, [attemptKey, get2faNavigationTo, i18n, navigate, params]);

    useEffect(() => {
        let timeout: number | undefined;
        if (waitSeconds !== undefined) {
            if (waitSeconds === 0) {
                onSuccess();
                return () => clearTimeout(timeout);
            }

            timeout = window.setTimeout(() => {
                onSuccess();
            }, waitSeconds * 1000);
        }
        return () => clearTimeout(timeout);
    }, [onSuccess, waitSeconds]);

    const waitSecondsOnSubmitSuccess = useCallback((wait: number) => {
        setWaitSeconds(wait);
    }, []);

    if (waitSeconds !== undefined && waitSeconds > 0) {
        return (
            <div className="flex w-full flex-col items-center">
                <WaitSeconds waitSeconds={waitSeconds} />
            </div>
        );
    }

    return (
        <div className="flex w-full flex-col items-center">
            {errorMessage && <h1 className="text-2xl font-semibold">{errorMessage}</h1>}
            {otpForm && (
                <SubmitOtpDynamicForm
                    formData={otpForm}
                    onBack={onBack}
                    waitSecondsOnSubmitSuccess={waitSecondsOnSubmitSuccess}
                />
            )}
        </div>
    );
};
