import styles from "./style.module.css";
import {ErrorViewProps, useErrorView} from "./useErrorView";

export enum ErrorViewType {
    ServiceUnavailable,
    NotFound,
    SomethingWentWrong,
}

const ErrorView = (props: ErrorViewProps) => {
    const {title, description, icon, ActionComponent} = useErrorView(props);

    return (
        <div className="grid h-full w-full place-items-center">
            <div className={`grid place-items-center gap-6 ${styles["content-box"]}`}>
                {icon}
                <div className="grid gap-2 text-center">
                    <div className="text-2xl font-semibold text-neutral-900">{title}</div>
                    <div className="text-base text-neutral-600">{description}</div>
                </div>
                {ActionComponent}
            </div>
        </div>
    );
};

export default ErrorView;
