import {FleetRoute} from "common/constants/routes";
import {CompanyType} from "common/constants/types";

export const NestedRoute = {
    DRIVER_DETAILS: `/:fleetId/${FleetRoute.DRIVER}`,
    VEHICLE_DETAILS: `/:fleetId/${FleetRoute.VEHICLE}`,
    DRIVER_REGISTRATION_DETAILS: `/:fleetId/${FleetRoute.DRIVER_REGISTRATION}`,
    DRIVER_INVITATION_DETAILS: `/:fleetId/${FleetRoute.DRIVER_INVITATION}`,
    CAMPAIGN_DETAILS: `/:fleetId/${FleetRoute.CAMPAIGN_DETAILS}`,
} as const;

type NestedRoutesKeys = keyof typeof NestedRoute;
type NestedRoutesValues = (typeof NestedRoute)[NestedRoutesKeys];
const routeToReplaceNestedRoute: Record<NestedRoutesValues, string> = {
    [NestedRoute.DRIVER_DETAILS]: `/:fleetId/${FleetRoute.DRIVERS}`,
    [NestedRoute.VEHICLE_DETAILS]: `/:fleetId/${FleetRoute.VEHICLES}`,
    [NestedRoute.DRIVER_REGISTRATION_DETAILS]: `/:fleetId/${FleetRoute.DRIVERS}/registration`,
    [NestedRoute.DRIVER_INVITATION_DETAILS]: `/:fleetId/${FleetRoute.DRIVERS}/invitation`,
    [NestedRoute.CAMPAIGN_DETAILS]: `/:fleetId/${FleetRoute.CAMPAIGNS}`,
};

export const nestedRoutes = [
    NestedRoute.DRIVER_DETAILS,
    NestedRoute.VEHICLE_DETAILS,
    NestedRoute.DRIVER_REGISTRATION_DETAILS,
    NestedRoute.DRIVER_INVITATION_DETAILS,
    NestedRoute.CAMPAIGN_DETAILS,
];

export const getNestedRouteReplaced = (path: NestedRoutesValues, companyId: number, companyType: CompanyType) => {
    const newPath = routeToReplaceNestedRoute[path];
    if (companyType === CompanyType.HOLDING_FLEET) {
        return newPath.replace(":holdingFleetId", String(companyId));
    }
    return newPath.replace(":fleetId", String(companyId));
};
