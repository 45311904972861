import {FC, useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {Button} from "@bolteu/kalep-react";

import useCallForBranding from "../hooks/useCallForBranding";

interface PageHeaderProps {
    openAddVehiclePage: () => void;
    tabSelectorComponent: FC;
}

const VehiclesPageHeader: FC<PageHeaderProps> = ({openAddVehiclePage, tabSelectorComponent}) => {
    const {getCarRentalsPath} = useAbsolutePath();
    const navigate = useNavigate();

    const {i18n} = useI18n();

    const fleet = useContext(AccountContextProvider)?.getFleet();
    const isCarRentalPaymentsEnabled = fleet?.targeting.car_rental_payments_enabled;

    const goToCarRentalPayments = useCallback(() => navigate(getCarRentalsPath()), [navigate, getCarRentalsPath]);
    const callForBrandingComponent = useCallForBranding();

    const TabSelector = tabSelectorComponent;

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-row items-baseline justify-between">
                <div className="flex flex-col gap-2">
                    <ResponsiveHeader
                        type={ResponsiveHeaderType.MainPage}
                        text={i18n("auth.app.fleet.vehicles.title")}
                    />
                    {callForBrandingComponent}
                </div>
                <div className="flex flex-row gap-8">
                    {isCarRentalPaymentsEnabled && (
                        <Button
                            variant="secondary"
                            onClick={goToCarRentalPayments}
                            overrideClassName="order-last md:order-1"
                        >
                            {i18n("auth.app.fleet.vehicles.charge_rental_payments")}
                        </Button>
                    )}
                    <div className="order-2">
                        <Button onClick={openAddVehiclePage}>{i18n("auth.app.fleet.dashboard.add-vehicle")}</Button>
                    </div>
                </div>
            </div>
            <TabSelector />
        </div>
    );
};

export default VehiclesPageHeader;
