import {useCallback, useContext, useEffect, useState} from "react";

import {EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DriverFilter} from "../components/list/Filter";
import {DriverInfo, DriverStats} from "../components/types";

import DriverState = FleetOwnerPortalService.DriverState;

export const DRIVER_POLL_INTERVAL_MS = 10_000;

const getDriversFetchFunction = (api: Api) => api.fleetOwnerPortal.getDriversForLiveMap();
const getOrderRouteFetchFunction = (api: Api, body: OrderFleetService.GetOrderRouteRequest) =>
    api.orderFleetShardedApi.getOrderRoute(body);
const getLastDropoffAndWaitingTimeFetchFunction = (
    api: Api,
    body: OrderFleetService.GetLastDropoffAndWaitingTimeRequest,
) => api.orderFleetShardedApi.getLastDropoffAndWaitingTime(body);

interface Props {
    isMobileMapOrDetailsView?: boolean;
    resetOnDriverStateChange?: () => void;
}

export const useLiveMap = ({isMobileMapOrDetailsView, resetOnDriverStateChange}: Props) => {
    const [sortedDrivers, setSortedDrivers] = useState<FleetOwnerPortalService.DriverForFleetOwner[]>([]);
    const [driverFilter, setDriverFilter] = useState<DriverFilter>({
        term: "",
        status: DriverState.HAS_ORDER,
    });
    const [filteredDrivers, setFilteredDrivers] = useState<FleetOwnerPortalService.DriverForFleetOwner[]>([]);
    const [selectedDriver, setSelectedDriver] = useState<DriverInfo | null>(null);
    const [driverStats, setDriverStats] = useState<DriverStats>({
        total: 0,
        [DriverState.HAS_ORDER]: 0,
        [DriverState.WAITING_FOR_ORDERS]: 0,
    });

    const fleet = useContext(AccountContextProvider)?.getFleet();
    const features = useContext(FeaturesContextProvider);

    const hasAccessToLiveMap = fleet?.targeting.live_map_enabled;

    const {trackEvent} = useTracking();
    const {data: driversData, fetch: driversFetch, status: driversStatus} = useFetch(getDriversFetchFunction);
    const {
        data: orderRouteData,
        fetch: orderRouteFetch,
        status: orderRouteStatus,
    } = useFetch(getOrderRouteFetchFunction);

    const {data: lastDropoffAndWaitingTime, fetch: lastDropoffAndWaitingTimeFetch} = useFetch(
        getLastDropoffAndWaitingTimeFetchFunction,
    );

    const getDrivers = useCallback(async () => {
        if (driversFetch && hasAccessToLiveMap) {
            driversFetch({});
        }
    }, [driversFetch, hasAccessToLiveMap]);

    useEffect(() => {
        if (driversStatus === FetchStatus.Success) {
            const sortedList = driversData.list.sort((a, b) => a.name.localeCompare(b.name));
            setSortedDrivers(sortedList);
        }
    }, [driversStatus, driversData]);

    useEffect(() => {
        getDrivers();
        const interval = setInterval(() => {
            getDrivers();
        }, DRIVER_POLL_INTERVAL_MS);
        return () => clearInterval(interval);
    }, [getDrivers]);

    useEffect(() => {
        const newStats = {
            total: sortedDrivers.length,
            [DriverState.HAS_ORDER]: 0,
            [DriverState.WAITING_FOR_ORDERS]: 0,
        };
        sortedDrivers.forEach((driver) => {
            switch (driver.state) {
                case DriverState.HAS_ORDER:
                    newStats[DriverState.HAS_ORDER] += 1;
                    break;
                case DriverState.WAITING_FOR_ORDERS:
                    newStats[DriverState.WAITING_FOR_ORDERS] += 1;
                    break;
                default:
            }
        });
        setDriverStats(newStats);
    }, [sortedDrivers, setDriverStats]);

    const getOrderInfo = useCallback(
        async (driver: FleetOwnerPortalService.DriverForFleetOwner) => {
            if (orderRouteFetch && driver.order_id) {
                orderRouteFetch({
                    order_id: driver.order_id,
                    driver_id: driver.id,
                });
            }
        },
        [orderRouteFetch],
    );

    useEffect(() => {
        if (orderRouteStatus === FetchStatus.Success) {
            setSelectedDriver((state) => {
                if (!state) {
                    return null;
                }
                return {...state, order: orderRouteData};
            });
        }
    }, [orderRouteStatus, orderRouteData]);

    useEffect(() => {
        if (selectedDriver && !selectedDriver.order) {
            getOrderInfo(selectedDriver.driver);
        }
    }, [getOrderInfo, selectedDriver]);

    const onFilterChange = useCallback(
        (newFilter: DriverFilter) => {
            setDriverFilter(newFilter);
            if (newFilter.term.length) {
                trackEvent(EventName.LiveMapDriverSearched);
            }
        },
        [setDriverFilter, trackEvent],
    );

    useEffect(() => {
        const filterFunction = (driver: FleetOwnerPortalService.DriverForFleetOwner) => {
            if (!isMobileMapOrDetailsView && driverFilter.status !== driver.state) {
                return false;
            }
            if (driverFilter.term) {
                const cleanTerm = driverFilter.term.toLocaleLowerCase().trim();
                return (
                    driver.name.toLocaleLowerCase().includes(cleanTerm) ||
                    driver.phone.toLocaleLowerCase().includes(cleanTerm) ||
                    driver.car_reg_number.toLocaleLowerCase().includes(cleanTerm)
                );
            }
            return true;
        };

        const newFilteredDrivers = sortedDrivers.filter(filterFunction);

        if (selectedDriver && !newFilteredDrivers.find((d) => d.id === selectedDriver.driver.id)) {
            if (resetOnDriverStateChange) {
                resetOnDriverStateChange();
            }
            setSelectedDriver(null);
        }
        setFilteredDrivers(newFilteredDrivers);
    }, [sortedDrivers, driverFilter, isMobileMapOrDetailsView, selectedDriver, resetOnDriverStateChange]);

    const shouldFetchLastDropoffAndWaitingTime =
        selectedDriver?.driver.state === DriverState.WAITING_FOR_ORDERS && features?.show_last_dropoff_and_waiting_time;
    useEffect(() => {
        if (lastDropoffAndWaitingTimeFetch && selectedDriver && shouldFetchLastDropoffAndWaitingTime) {
            lastDropoffAndWaitingTimeFetch({
                driver_id: selectedDriver.driver.id,
            });
        }
    }, [selectedDriver, lastDropoffAndWaitingTimeFetch, shouldFetchLastDropoffAndWaitingTime]);

    const onDriverSelected = useCallback((driver: FleetOwnerPortalService.DriverForFleetOwner | null) => {
        if (!driver) {
            setSelectedDriver(null);
        } else {
            setSelectedDriver({driver});
        }
    }, []);

    const onDriverDeselected = useCallback(() => onDriverSelected(null), [onDriverSelected]);

    const onDriverMapSelected = useCallback(
        (driver: FleetOwnerPortalService.DriverForFleetOwner | null) => {
            onDriverSelected(driver);

            if (driverFilter.term) {
                trackEvent(EventName.LiveMapSearchedDriverMapClicked);
            } else {
                trackEvent(EventName.LiveMapDriverMapClicked);
            }
        },
        [onDriverSelected, trackEvent, driverFilter.term],
    );

    const onDriverListSelected = useCallback(
        (driver: FleetOwnerPortalService.DriverForFleetOwner | null) => {
            onDriverSelected(driver);

            if (driverFilter.term) {
                trackEvent(EventName.LiveMapSearchedDriverClicked);
            } else {
                trackEvent(EventName.LiveMapDriverClicked);
            }
        },
        [onDriverSelected, trackEvent, driverFilter.term],
    );

    return {
        hasAccessToLiveMap,
        filteredDrivers,
        selectedDriver,
        driverFilter,
        driverStats,
        onDriverMapSelected,
        onFilterChange,
        onDriverListSelected,
        onDriverDeselected,
        lastDropoffAndWaitingTime: shouldFetchLastDropoffAndWaitingTime ? lastDropoffAndWaitingTime : null,
    };
};
