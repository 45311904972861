import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import LoadingSpinner from "common/components/spinner";
import {useTabs} from "common/components/tab/useTabs";
import {getDriverName} from "common/constants/driverName";
import {EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {
    ApiPeriod,
    apiPeriodToDates,
    getDatesStrFromDates,
    getPeriodName,
    OngoingDay,
    Period,
} from "common/services/period";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {getIsoDate} from "common/util/FormatUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {AddDriver} from "./components/AddDriver";
import {DriverDialogProvider} from "./components/DriverDialogProvider";
import DriverRegistrationsInfo from "./components/DriverRegistrationsInfo";
import DriverRegistrationsTab from "./components/DriverRegistrationsTab";
import {defaultDriversFilters, DriverFilter} from "./components/DriversFilter";
import DriversTab from "./components/DriversTab";
import {MatchesProvider} from "./components/matches/matchesProvider";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";
import DriverRegistrationLogStatus = FleetOwnerPortalService.DriverRegistrationLogStatus;

const MAX_LIMIT = 25;

export enum DriverTabs {
    Drivers = "drivers",
    Registrations = "registrations",
}

export enum DriverInvitationStatus {
    INVITED = "invited",
}

export type DriverRegistrationStatus = DriverRegistrationLogStatus | DriverInvitationStatus;

export interface DriverRegistration {
    id: string;
    hash: string;
    created: number;
    driver: string;
    status: DriverRegistrationStatus;
    email: string | null;
    phone: string;
    link: string | null;
}

const getEngagementDataFetchFunction = (api: Api, body: FleetOwnerPortalService.GetDriverEngagementDateRangeRequest) =>
    api.fleetOwnerPortal.getDriverEngagementDataDateRange(body);
const getDriverRegistrationsDataFetchFunction = (api: Api) => api.fleetOwnerPortal.getDriverRegistrations();
const getDriverInvitationsDataFetchFunction = (api: Api) => api.fleetDriverInvitation.invitationsGet();

export interface OnlineHoursConfig {
    shouldShowOnlineHours: boolean;
    shouldShowWaitingOrders: boolean;
    shouldShowHasOrder: boolean;
    shouldShowUtilization: boolean;
}

const DriversListPage: FC = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();

    const [tableSearchValue, setTableSearchValue] = useState("");
    const [driversFilters, setDriversFilters] = useState<DriverFilter>(defaultDriversFilters);

    const {trackEvent} = useTracking();
    const {
        data: engagementData,
        fetch: engagementFetch,
        status: engagementStatus,
    } = useFetch(getEngagementDataFetchFunction);
    const {data: driverRegistrationData, fetch: driverRegistrationFetch} = useFetch(
        getDriverRegistrationsDataFetchFunction,
    );
    const {data: driverInvitationData, fetch: driverInvitationFetch} = useFetch(getDriverInvitationsDataFetchFunction);

    const [pageNum, setPageNum] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.ONGOING_DAY} as OngoingDay);
    const [isLoadingRegistrations, setIsLoadingRegistrations] = useState(false);

    const [isRegistrationsInfoShown, setIsRegistrationsInfoShown] = useState(false);

    const driverCount = engagementData?.total_rows;
    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(engagementStatus);

    const driverApplications: DriverRegistration[] = [];

    if (driverRegistrationData) {
        driverApplications.push(
            ...driverRegistrationData.list.map((d) => {
                return {
                    id: String(d.id),
                    hash: d.hash,
                    created: d.created,
                    driver: getDriverName(d.first_name, d.last_name, d.email),
                    status: d.status,
                    email: d.email,
                    phone: d.phone,
                    link: d.registration_link,
                };
            }),
        );
    }

    if (driverInvitationData) {
        driverApplications.push(
            ...driverInvitationData.list.map((i) => {
                return {
                    id: i.hash,
                    hash: i.hash,
                    created: i.created,
                    driver: i.email,
                    status: DriverInvitationStatus.INVITED,
                    email: i.email,
                    phone: i.phone,
                    link: i.invitation_link,
                };
            }),
        );
    }

    driverApplications.sort((a, b) => b.created - a.created);

    const tabs = useTabSelectorOptions(isLoading, driverCount, driverApplications.length);

    const isShowApplicationsTabByDefault = !driverCount && !tableSearchValue && !driversFilters.status;

    const {activeTab, TabSelector} = useTabs(
        tabs,
        isShowApplicationsTabByDefault ? DriverTabs.Registrations : DriverTabs.Drivers,
    );

    const onlineHoursConfig: OnlineHoursConfig = useMemo(
        () => ({
            shouldShowOnlineHours: Boolean(engagementData?.rows[0]?.online_min !== null),
            shouldShowWaitingOrders: Boolean(engagementData?.rows[0]?.waiting_orders_min !== null),
            shouldShowHasOrder: Boolean(engagementData?.rows[0]?.has_order_min !== null),
            shouldShowUtilization: Boolean(engagementData?.rows[0]?.utilization !== null),
        }),
        [engagementData],
    );

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.drivers.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const getDashboardData = useCallback(
        async (newPageNum: number) => {
            if (engagementFetch) {
                const dates =
                    selectedPeriod.period === Period.CUSTOM
                        ? {start: selectedPeriod.startDate, end: selectedPeriod.endDate}
                        : apiPeriodToDates(selectedPeriod);

                const offset = newPageNum * MAX_LIMIT;
                engagementFetch({
                    offset,
                    limit: MAX_LIMIT,
                    start_date: getIsoDate(dates.start),
                    end_date: getIsoDate(dates.end),
                    portal_status: driversFilters.status ?? undefined,
                    ...(tableSearchValue && {search: tableSearchValue}),
                });
            }
        },
        [engagementFetch, selectedPeriod, tableSearchValue, driversFilters.status],
    );

    useEffect(() => {
        if (fleet) {
            setPageNum(0);
            getDashboardData(0);
        }
    }, [getDashboardData, fleet]);

    useEffect(() => {
        if (fleet) {
            if (driverRegistrationFetch) {
                driverRegistrationFetch({});
            }
        }
    }, [fleet, driverRegistrationFetch]);

    useEffect(() => {
        if (fleet) {
            if (driverInvitationFetch) {
                driverInvitationFetch({});
            }
        }
    }, [fleet, driverInvitationFetch]);

    const onPageChange = useCallback(
        async (page: number) => {
            if (page === pageNum) {
                return;
            }
            getDashboardData(page);
            setPageNum(page);
        },
        [getDashboardData, pageNum],
    );

    const onPeriodChange = useCallback(
        (newPeriod: ApiPeriod) => {
            setSelectedPeriod(newPeriod);
            const dates = apiPeriodToDates(newPeriod);

            trackEvent(EventName.EngagementDashboardPeriodPicked, {
                periodName: getPeriodName(newPeriod),
                period: getDatesStrFromDates(dates.start, dates.end),
            });
        },
        [setSelectedPeriod, trackEvent],
    );

    const onLeadAccepted = useCallback(() => {
        setIsLoadingRegistrations(true);
        if (driverRegistrationFetch) {
            setTimeout(() => {
                driverRegistrationFetch({}).then(() => setIsLoadingRegistrations(false));
            }, 3000);
        }
    }, [driverRegistrationFetch]);

    const closeRegistrationsInfo = useCallback(() => setIsRegistrationsInfoShown(false), []);
    const openRegistrationsInfo = useCallback(() => setIsRegistrationsInfoShown(true), []);

    if (engagementStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    return (
        <MatchesProvider>
            <Page>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
                        <ResponsiveHeader
                            type={ResponsiveHeaderType.MainPage}
                            text={i18n("auth.app.fleet.drivers.title")}
                        />
                        <AddDriver />
                    </div>
                    <TabSelector />
                </div>
                {activeTab === DriverTabs.Drivers && (
                    <DriversTab
                        dashboardData={engagementData?.rows}
                        onlineHoursConfig={onlineHoursConfig}
                        selectedPeriod={selectedPeriod}
                        onPeriodChange={onPeriodChange}
                        driverCount={driverCount}
                        currentPage={pageNum}
                        pageSize={MAX_LIMIT}
                        onPageChange={onPageChange}
                        onSearchChange={setTableSearchValue}
                        isLoading={isLoading}
                        filters={driversFilters}
                        setFilters={setDriversFilters}
                        showPaginationText
                    />
                )}
                {activeTab === DriverTabs.Registrations && (
                    <>
                        <LoadingSpinner show={isLoadingRegistrations} showLoadingText parentClassName="mt-9" />
                        {!isLoadingRegistrations && (
                            <>
                                <DriverRegistrationsInfo
                                    closePage={closeRegistrationsInfo}
                                    isOpen={isRegistrationsInfoShown}
                                />
                                <DriverRegistrationsTab
                                    onLeadAccepted={onLeadAccepted}
                                    driverRegistrations={driverApplications}
                                    showRegistrationsInfo={openRegistrationsInfo}
                                />
                            </>
                        )}
                    </>
                )}
            </Page>
        </MatchesProvider>
    );
};

const DriversListPageWithProvider = () => (
    <DriverDialogProvider>
        <DriversListPage />
    </DriverDialogProvider>
);

export default DriversListPageWithProvider;
