import {useCallback} from "react";

import FilteredBy, {RenderChips} from "common/components/table/FilteredBy";
import {UnreachableCode} from "common/components/util/UnreachableCode";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerReportingService, FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";

import {defaultOrdersFilters, OrderFilters} from "./OrdersFilter";

import City = FleetOwnerService.City;
import SearchCategoryGroup = FleetOwnerReportingService.SearchCategoryGroup;

interface FilteredByProps {
    cities: City[];
    filters: OrderFilters;
    setFilters: (filters: OrderFilters) => void;
    categories: SearchCategoryGroup[];
}

const OrderHistoryFilteredBy = ({cities, filters, setFilters, categories}: FilteredByProps) => {
    const {i18n} = useI18n();

    const cityName = cities.find((city) => city.city_id === filters.city_id)?.name;
    const categoryGroupName = categories.find((category) => category.id === filters.search_category_group)?.name;

    const renderChips = useCallback(
        (getFilterOnRemove: RenderChips<OrderFilters>) =>
            Object.entries(filters).map(([key, value]) => {
                const filtersKey = key as keyof OrderFilters;
                const onRemove = getFilterOnRemove(filtersKey);

                let label = "";
                switch (filtersKey) {
                    case "orders_state_statuses": {
                        const currentValues = value as OrderFilters["orders_state_statuses"];
                        if (
                            currentValues.length === defaultOrdersFilters.orders_state_statuses.length &&
                            defaultOrdersFilters.orders_state_statuses.every((state) => currentValues.includes(state))
                        ) {
                            return null;
                        }

                        const statuses = (value as OrderFilters["orders_state_statuses"]) || [];
                        label = statuses.map((status) => i18n(`order.status.${status}`)).join(", ");
                        break;
                    }
                    case "city_id":
                        if (value === defaultOrdersFilters.city_id) {
                            return null;
                        }
                        label = cityName || "";
                        break;
                    case "search_category_group":
                        if (value === defaultOrdersFilters.search_category_group) {
                            return null;
                        }
                        label = categoryGroupName || "";
                        break;
                    default:
                        return UnreachableCode.never(filtersKey, null);
                }

                return <Chip key={key} size="md" onRemove={onRemove} label={label} />;
            }),
        [filters, i18n, cityName, categoryGroupName],
    );

    return (
        <FilteredBy
            setFilters={setFilters}
            currentFilterValues={filters}
            initialFilterValues={defaultOrdersFilters}
            renderChips={renderChips}
        />
    );
};

export default OrderHistoryFilteredBy;
