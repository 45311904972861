import {useCallback, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import {Password} from "common/components/dynamicForm/Password";
import {AdditionalFields, FormValueType} from "common/components/dynamicForm/types";
import {getApiToFormValues, getChildrenErrorMessages, getFormToApiValues} from "common/components/dynamicForm/util";
import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {ValidatorResult} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {FleetOwnerAuthNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {isValidationError} from "common/util/isErrorType";
import {TranslationKeys} from "config/translations";

import {FleetOwnerAuthNoAuthService, FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Alert} from "@bolteu/kalep-react";

interface PasswordError {
    response: {
        data: PasswordErrorFields;
    };
    message: string;
}

interface PasswordErrorFields {
    errors: ErrorField[];
}

interface ErrorField {
    message: string;
    hint: "short" | "required_lower_upper_numeric";
    attribute: "password";
}

interface DynamicFormProps {
    setIsPasswordResetFinished: (isPasswordResetFinished: boolean) => void;
    passwordField: FleetOwnerRegistrationService.PasswordField;
}

export const DynamicForm = ({setIsPasswordResetFinished, passwordField}: DynamicFormProps) => {
    const {i18n} = useI18n();
    const params = useParams();
    const {trackEvent} = useTracking();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const formFields: FleetOwnerRegistrationService.FormField[] = useMemo(() => [passwordField], [passwordField]);
    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);
    const [formValue] = useState<FormValueType>(getApiToFormValues(formFields));

    const useFormValue = useForm<FormValueType, AdditionalFields>(formValue);
    const {Form, Button} = useFormValue;

    const onSubmitForm = useCallback(
        async (state: FormState<FormValueType>) => {
            const {data} = state;
            const request = {
                ...getFormToApiValues<FleetOwnerAuthNoAuthService.SubmitResetPasswordFormRequest>(data, formFields),
                token: params.token ?? "",
            };

            try {
                const response = await FleetOwnerAuthNoAuthApiClient.submitResetPasswordForm(request);
                if (response.validation_errors) {
                    setValidatorResults(response.validation_errors as ValidatorResult[]);
                    trackEvent(EventName.FormSubmissionFailed, {
                        formName: EventPropertiesFormSubmissionFailedName.ResetPassword,
                        errorMessage:
                            response.validation_errors?.[0]?.error ||
                            response.validation_errors?.[0]?.children?.[0]?.error ||
                            "",
                    });
                    return;
                }
                setIsPasswordResetFinished(true);
            } catch (e) {
                // Copied from existing catch block in useResetPasswordForm
                // Legacy error type, search "This error has a different format from other errors, so cannot throw" in server code
                const passwordError = e as PasswordError;
                const error = passwordError?.response?.data?.errors?.[0];

                if (!isValidationError(e) && error?.message) {
                    setErrorMessage(
                        i18n(`api.error.${error.message}` as TranslationKeys, undefined, "api.default_error"),
                    );
                }
                trackEvent(EventName.FormSubmissionFailed, {
                    formName: EventPropertiesFormSubmissionFailedName.ResetPassword,
                    errorMessage: String(passwordError?.message),
                });
            }
        },
        [formFields, i18n, params.token, setIsPasswordResetFinished, trackEvent],
    );

    return (
        <Form
            validatorResults={validatorResults}
            onSubmit={onSubmitForm}
            className="flex w-full max-w-[356px] flex-col gap-4"
        >
            <p className="mb-3 w-full text-center text-neutral-700">{i18n("reset-password.description")}</p>
            {errorMessage && (
                <Alert severity="error" overrideClassName="text-base mb-2">
                    {errorMessage}
                </Alert>
            )}
            <Password
                useFormValue={useFormValue}
                fieldConfig={passwordField}
                validatorErrors={getChildrenErrorMessages(passwordField, validatorResults)}
            />
            <Button fullWidth overrideClassName="mt-2" submit>
                {i18n("reset-password.save_password")}
            </Button>
        </Form>
    );
};
