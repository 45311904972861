import {useRef} from "react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Table, Tooltip} from "@bolteu/kalep-react";
import {InfoCircle} from "@bolteu/kalep-react-icons";

import {CellProps, CellRenderer, isColumnVisible, ReferenceColumnContent, UseableColumns} from "./Common";

type ApiDrivenTableDesktopProps = UseableColumns & {
    numberOfRecords: number;
};

export default function ApiDrivenTableDesktop(apiDrivenTableDesktopProps: ApiDrivenTableDesktopProps) {
    const {referenceColumn, userDefinedVisibleColumns, firstColumn, stateColumn} = apiDrivenTableDesktopProps;

    const refs = useRef<(HTMLElement | null)[]>([]);

    function addRef(idx: number) {
        return (r: HTMLElement | null) => {
            refs.current[idx] = r;
        };
    }

    return (
        <Table>
            <Table.Header>
                <Table.HeaderRow>
                    <Table.HeaderCell align="left">{firstColumn.title}</Table.HeaderCell>
                    {userDefinedVisibleColumns.map((column, columdIdx) => (
                        <Table.HeaderCell align="left" key={column.key}>
                            <div
                                className="align-center flex h-full w-full items-center gap-x-1"
                                ref={addRef(columdIdx)}
                            >
                                {column.title}
                                {column.tooltip && (
                                    <Tooltip
                                        content={column.tooltip}
                                        placement="left"
                                        key={column.key}
                                        boundaryElement={refs.current[columdIdx] ?? undefined}
                                    >
                                        <InfoCircle width={14} height={14} />
                                    </Tooltip>
                                )}
                            </div>
                        </Table.HeaderCell>
                    ))}
                </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
                {Array.from({length: apiDrivenTableDesktopProps.numberOfRecords}).map((_, rowIdx) => (
                    <Table.Row key={referenceColumn?.cells[rowIdx]}>
                        <ReferenceColumnCell
                            column={firstColumn}
                            referenceColumn={referenceColumn}
                            valueAtIdx={rowIdx}
                            stateColumn={stateColumn}
                        />
                        {userDefinedVisibleColumns.filter(isColumnVisible).map((column) => (
                            <Cell valueAtIdx={rowIdx} column={column} key={`${column.key} - ${column.cells[rowIdx]}`} />
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

function ReferenceColumnCell(
    cellProps: CellProps & {
        referenceColumn?: FleetOwnerPortalService.ReferenceColumnShape;
        stateColumn?: FleetOwnerPortalService.StateColumn;
    },
): JSX.Element {
    return (
        <Table.Cell>
            <ReferenceColumnContent {...cellProps} displayAsLink />
        </Table.Cell>
    );
}

function Cell(cellProps: CellProps): JSX.Element {
    return (
        <Table.Cell>
            <CellRenderer {...cellProps} />
        </Table.Cell>
    );
}
