import {useCallback, useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox, SelectOption} from "@bolteu/kalep-react";

export const ALL_DRIVERS_OPTION_VALUE = -1;

interface Props {
    driverId: number | null;
    onChange: (option: SelectOption | null) => void;
    drivers: FleetOwnerPortalService.Driver[];
    loading: boolean;
    label?: string;
    placeholder?: string;
}

const DriverFilter = ({driverId, onChange, drivers, loading, placeholder, label}: Props) => {
    const {i18n} = useI18n();

    const onComboBoxChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            if (newValue === null) {
                return;
            }
            let newDriver: SelectOption | null;
            if (Array.isArray(newValue)) {
                newDriver = newValue[0].value === null ? null : newValue[0];
            } else {
                newDriver = newValue.value === null ? null : newValue;
            }
            onChange(newDriver);
        },
        [onChange],
    );

    const driverOptions = useMemo(() => {
        let options: SelectOption[] = [{value: ALL_DRIVERS_OPTION_VALUE, title: i18n("common.all")}];
        if (drivers.length) {
            options = options.concat(
                drivers.map((driver) => ({value: driver.id, title: `${driver.first_name} ${driver.last_name}`})),
            );
        }
        return options;
    }, [drivers, i18n]);

    const selectedValue = driverOptions.find((option) => option.value === driverId) ?? null;

    return (
        <div className="w-80 shrink-0">
            <ComboBox
                value={selectedValue}
                onChange={onComboBoxChange}
                options={driverOptions}
                label={label}
                placeholder={placeholder ?? i18n("common.all")}
                fullWidth
                noResultsMessage={i18n("common.search_no_results")}
                loading={loading}
            />
        </div>
    );
};

export default DriverFilter;
