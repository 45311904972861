import {ChangeEventHandler, useCallback, useContext, useEffect, useState} from "react";

import {ResponsiveDialog} from "common/components/dialog/ResponsiveDialog";
import {NotificationType} from "common/components/notifications/TopLeftCorner";
import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {NotificationContext, NotificationContextValue} from "common/services/notificationProvider";

import {FleetDriverInvitationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Dialog, Grid, TextField} from "@bolteu/kalep-react";

import {AccountContextProvider} from "../../../../account/accountStateProvider";
import AreaCodeSelector from "./AreaCodeSelector";

export interface InviteDriverDialogProps {
    isOpen: boolean;
    closeDialog: () => void;
    openCompleteRegistrationDialog: (data: NewDriverRegistrationData) => void;
}

export interface NewDriverRegistrationData {
    email: string;
    registration_link: string;
}

const addInvitationFunction = (api: Api, body: FleetDriverInvitationService.CreateInvitationRequest) =>
    api.fleetDriverInvitation.createInvitation(body);

const InviteDriverDialog = ({isOpen, closeDialog, openCompleteRegistrationDialog}: InviteDriverDialogProps) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {setNotification} = useContext(NotificationContext) as NotificationContextValue;

    const {fetch, status, error, data} = useFetch(addInvitationFunction);
    const isLoading = status === FetchStatus.Loading;

    const [email, setEmail] = useState("");
    const [phoneAreaCode, setPhoneAreaCode] = useState("");
    const [phone, setPhone] = useState("");
    const [referralCode, setReferralCode] = useState("");

    const companyCountry = fleet?.country;

    const addDriverInvitation = useCallback(async () => {
        if (fetch) {
            fetch({
                email,
                phone: phoneAreaCode + phone,
                referral_code: referralCode,
            });
        }
    }, [email, fetch, phone, phoneAreaCode, referralCode]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            if (data && data.type === FleetDriverInvitationService.AddDriverResponseType.DRIVER_LEAD) {
                openCompleteRegistrationDialog({
                    email: data.data.email ?? "",
                    registration_link: data.data.registration_link ?? "",
                });

                trackEvent(EventName.AddNewDriverSubmitSuccessful);
            } else {
                trackEvent(EventName.InviteDriverSubmitSuccessful);
                setNotification({
                    type: NotificationType.SUCCESS,
                    text: i18n("auth.app.fleet.add-invitation.created"),
                    timeout: 10000,
                });
            }

            closeDialog();
        } else if (status === FetchStatus.Error) {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.InviteDriver,
                errorMessage: error.message,
            });
        }
    }, [status, trackEvent, i18n, setNotification, error, closeDialog, openCompleteRegistrationDialog, data]);

    const getFieldError = (property: string) => {
        return error.validationErrors.find((e) => e.property === property)?.error;
    };

    const formatPhone = (phoneNumber: string) => {
        return phoneNumber.replace(/[\s\-()]/g, "");
    };

    const handleEmail = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => setEmail(e.target.value), [setEmail]);
    const handlePhoneAreaCode = useCallback<(areaCode: string) => void>(
        (areaCode) => setPhoneAreaCode(areaCode),
        [setPhoneAreaCode],
    );
    const handlePhone = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => setPhone(formatPhone(e.target.value)),
        [setPhone],
    );

    const handleReferralCode = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => setReferralCode(e.target.value),
        [setReferralCode],
    );

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return (
        <ResponsiveDialog
            title={i18n("auth.app.fleet.add-invitation.title")}
            isOpen={isOpen}
            onRequestClose={closeDialog}
            experimentalOverflowVisible
        >
            <div className="add-new-driver-dialog">
                <Dialog.Content>
                    <Grid columns={12}>
                        <Grid.Item colSpan={12}>
                            <TextField
                                id="email"
                                type="text"
                                label={i18n("auth.app.fleet.add-driver.driver_email")}
                                placeholder={i18n("auth.app.fleet.add-driver.enter_email")}
                                onChange={handleEmail}
                                helperText={getFieldError("email")}
                                error={!!getFieldError("email")}
                                required
                            />
                        </Grid.Item>
                        <Grid.Item colSpan={4}>
                            <AreaCodeSelector
                                companyCountry={companyCountry}
                                handlePhoneAreaCode={handlePhoneAreaCode}
                            />
                        </Grid.Item>
                        <Grid.Item colSpan={8}>
                            <TextField
                                id="phone"
                                type="text"
                                label={i18n("auth.app.fleet.add-driver.driver_phone")}
                                placeholder={i18n("common.phone")}
                                onChange={handlePhone}
                                helperText={getFieldError("phone")}
                                error={!!getFieldError("phone")}
                                required
                                fullWidth
                            />
                        </Grid.Item>
                        <Grid.Item colSpan={8}>
                            <TextField
                                id="referral_code"
                                type="text"
                                label={i18n("auth.app.fleet.add-driver.referral_code")}
                                placeholder={i18n("auth.app.fleet.add-driver.enter_referral_code")}
                                onChange={handleReferralCode}
                            />
                        </Grid.Item>
                    </Grid>
                </Dialog.Content>
            </div>
            <Dialog.Footer>
                <Button
                    loading={isLoading}
                    disabled={isLoading || !email || !phoneAreaCode || !phone}
                    onClick={addDriverInvitation}
                    fullWidth={isMobileView}
                >
                    {i18n("auth.app.fleet.add-invitation.submit")}
                </Button>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};

export default InviteDriverDialog;
