import Bowser from "bowser";
import {getLegacyItem, setLegacyItem} from "common/services/localStorageService";
import {v4} from "uuid";

export const DeviceUUIDKey = "uuid";

export const getDeviceName = () => {
    return Bowser.parse(window.navigator.userAgent).browser.name || "unknown";
};

export const getDeviceOSInfo = () => {
    return Bowser.parse(window.navigator.userAgent).os.version?.toString() || "unknown";
};

export const getUserAgent = () => window.navigator.userAgent;

export const getDeviceUniqueId = () => {
    const authKey = getLegacyItem(DeviceUUIDKey);
    if (authKey) {
        return authKey;
    }

    const deviceId = v4();
    setLegacyItem(DeviceUUIDKey, deviceId);

    return deviceId;
};

export const getDeviceInfo = () => {
    return {
        device_uid: getDeviceUniqueId(),
        device_name: getDeviceName(),
        device_os_version: getDeviceOSInfo(),
    };
};
