import {FC, useCallback} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";

import {Button} from "@bolteu/kalep-react";

interface PageHeaderProps {
    tabSelectorComponent: FC;
}

const PageHeader: FC<PageHeaderProps> = ({tabSelectorComponent}) => {
    const {i18n} = useI18n();
    const viewPort = useTailwindViewport();

    const isMobileView = viewPort === "sm";
    const TabSelector = tabSelectorComponent;

    const onAddShiftClick = useCallback(() => {
        // eslint-disable-next-line no-console -- TODO replace with logic
        console.log("Add shift clicked");
    }, []);

    const onManageAssignmentsClick = useCallback(() => {
        // eslint-disable-next-line no-console -- TODO replace with logic
        console.log("Manage assignment clicked");
    }, []);

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col justify-between gap-4 md:flex-row">
                <ResponsiveHeader
                    type={ResponsiveHeaderType.MainPage}
                    text={i18n("auth.app.fleet.work_shifts.title")}
                />
                <div className="grid grid-cols-2 items-center justify-center gap-4 md:flex">
                    <Button onClick={onAddShiftClick} fullWidth={isMobileView}>
                        {i18n("auth.app.fleet.work_shifts.add_shift")}
                    </Button>
                    <Button onClick={onManageAssignmentsClick} variant="secondary" fullWidth={isMobileView}>
                        {i18n("auth.app.fleet.work_shifts.manage_assignments")}
                    </Button>
                </div>
            </div>
            <TabSelector />
        </div>
    );
};

export default PageHeader;
