import {useI18n} from "common/hooks/useI18n";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert} from "@bolteu/kalep-react";
import {Severity} from "@bolteu/kalep-react/build/components/Alert/Alert.types";
import CarApplicationStatus = CarApplicationService.CarApplicationStatus;

export interface Props {
    application: CarApplicationService.GetCarApplicationResponse;
}

const Alerts = ({application}: Props) => {
    const {i18n} = useI18n();
    const alerts: Array<{title: string; description: string; severity: Severity}> = [];

    if ([CarApplicationStatus.IN_PROGRESS, CarApplicationStatus.NEEDS_RESUBMISSION].includes(application.status)) {
        alerts.push({
            title: i18n("auth.app.fleet.vehicle-application.banner.missing-documents-title"),
            description: i18n("auth.app.fleet.vehicle-application.banner.missing-documents-description"),
            severity: "error",
        });
    }

    if (application.comment_to_applicant) {
        if (application.status === CarApplicationStatus.DECLINED) {
            alerts.push({
                title: i18n("auth.app.fleet.vehicle-application.banner.declined-title"),
                description: application.comment_to_applicant,
                severity: "error",
            });
        } else {
            alerts.push({
                title: i18n("auth.app.fleet.vehicle-application.banner.response-title"),
                description: application.comment_to_applicant,
                severity: "info",
            });
        }
    }

    return (
        <>
            {alerts.map((banner) => (
                <Alert severity={banner.severity} overrideClassName="mb-5" key={banner.title}>
                    <p className="mb-2 font-semibold">{banner.title}</p>
                    <p className="text-secondary">{banner.description}</p>
                </Alert>
            ))}
        </>
    );
};

export default Alerts;
