import {Navigate, useLocation} from "react-router-dom";

import {fleetIndexRoute} from "AppRoutes";
import {CommonRoute, CompanyOnboardingRoutePrefix, HoldingFleetRoutePrefix} from "common/constants/routes";
import {Company, isHoldingFleet} from "common/constants/types";
import {useCompanyData} from "common/hooks/useCompanyData";
import {LocationWithState, ViewLocationState} from "common/navigation/types/locationState";
import {getRouteEndingPath} from "common/util/routeUtils";

import {ActiveCompanyStorage} from "../services/authService";
import {getItem} from "../services/localStorageService";

const ChooseCompanyLayout = () => {
    const location = useLocation() as LocationWithState<ViewLocationState>;
    const {
        isZeroCompanies,
        isOnlyCompanyRegistrations,
        availableFleets,
        availableActiveFleets,
        availableHoldingFleets,
        continueCompanyRegistrationHash,
    } = useCompanyData();

    if (continueCompanyRegistrationHash) {
        return <Navigate to={`${CommonRoute.ADD_COMPANY}/${continueCompanyRegistrationHash}`} />;
    }
    if (isZeroCompanies) {
        return <Navigate to={CommonRoute.ADD_COMPANY} />;
    }
    if (isOnlyCompanyRegistrations) {
        const draftCompany = availableFleets[0];
        return <Navigate to={`/${CompanyOnboardingRoutePrefix}/${draftCompany.id}`} />;
    }

    let view = location.state?.view ?? "";
    if (view === getRouteEndingPath(CommonRoute.AUTH)) {
        view = fleetIndexRoute;
    }

    const activeCompanyIdFOPv1 = getItem(ActiveCompanyStorage);
    const activeCompanyId = Number(activeCompanyIdFOPv1);

    let company: Company | undefined = availableActiveFleets.find((c) => c.id === activeCompanyId);
    if (!company) {
        const companies = availableActiveFleets.length ? availableActiveFleets : availableHoldingFleets;
        [company] = companies;
    }

    if (isHoldingFleet(company)) {
        return <Navigate to={`${HoldingFleetRoutePrefix}/${String(company.id)}`} />;
    }

    return <Navigate to={`${String(company.id)}/${view}`} />;
};

export default ChooseCompanyLayout;
