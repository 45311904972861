import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {FormValueType} from "common/components/dynamicForm/types";
import {getFormToApiValues} from "common/components/dynamicForm/util";
import {UnreachableCode} from "common/components/util/UnreachableCode";
import {ValidatorResult} from "common/constants/types";
import {ResponseCodes} from "common/ResponseCodes";
import {FleetOwnerVerificationNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {RefreshToken} from "common/services/authService";
import {setLegacyItem} from "common/services/localStorageService";
import {getDeviceInfo, getUserAgent} from "common/util/browserInfo";
import {isServerError} from "common/util/isErrorType";
import {SubmitOtpFormResponse} from "features/account/pages/Verification/types";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormState} from "@bolteu/kalep-form-react";

import {useVerificationNavigations} from "./useVerificationNavigations";
import SubmitOtpFormResult = FleetOwnerVerificationNoAuthService.SubmitOtpFormResult;

export const useSubmitOtpForm = ({
    attemptKey,
    setErrorMessage,
    setValidatorResults,
    waitSecondsOnSubmitSuccess,
}: {
    attemptKey?: string;
    setErrorMessage: (error?: string) => void;
    setValidatorResults: (results: ValidatorResult[]) => void;
    waitSecondsOnSubmitSuccess?: (waitSeconds: number) => void;
}) => {
    const navigate = useNavigate();
    const {
        getContinueToAddCompanyNavigationUrl,
        getConfirmationNavigationTo,
        getContinueToCompanRegistrationOnboardingNavigationUrl,
        getContinueToCompanRegistrationStepsNavigationUrl,
        get2faNavigationTo,
        get2faConfirmationNavigationTo,
    } = useVerificationNavigations();
    const deviceInfo = getDeviceInfo();

    const handleSubmitResponse = useCallback(
        (response: SubmitOtpFormResponse) => {
            const responseType = response.type;
            switch (responseType) {
                case SubmitOtpFormResult.FAILURE:
                    setErrorMessage(response.error_text);
                    if (response.validation_errors) {
                        setValidatorResults(response.validation_errors as ValidatorResult[]);
                    }
                    break;
                case SubmitOtpFormResult.PHONE_UPDATE_SUCCESS:
                    if (waitSecondsOnSubmitSuccess) {
                        waitSecondsOnSubmitSuccess(response.wait_seconds_to_request_new_otp);
                    }
                    break;
                case SubmitOtpFormResult.CONTINUE_SIGNUP:
                    window.location.replace(`${window.origin}/signup/${response.signup_hash}`);
                    break;
                case SubmitOtpFormResult.CONTINUE_TO_ADD_COMPANY_REGISTRATION:
                    setLegacyItem(RefreshToken, response.refresh_token);
                    window.location.replace(getContinueToAddCompanyNavigationUrl(response.registration_hash));
                    break;
                case SubmitOtpFormResult.CONTINUE_TO_COMPANY_REGISTRATION_ONBOARDING_PAGE:
                    setLegacyItem(RefreshToken, response.refresh_token);
                    window.location.replace(
                        getContinueToCompanRegistrationOnboardingNavigationUrl(response.company_id),
                    );
                    break;
                case SubmitOtpFormResult.CONTINUE_TO_COMPANY_REGISTRATION_STEPS:
                    if (response.refresh_token) {
                        setLegacyItem(RefreshToken, response.refresh_token);
                    }
                    window.location.replace(
                        getContinueToCompanRegistrationStepsNavigationUrl(response.registration_hash),
                    );
                    break;
                case SubmitOtpFormResult.CONFIRMATION_REQUIRED:
                    if (!attemptKey) {
                        return;
                    }
                    setLegacyItem(RefreshToken, response.refresh_token);
                    navigate(getConfirmationNavigationTo(response.username, attemptKey));
                    break;
                case SubmitOtpFormResult.CONTINUE_TO_PORTAL_LOGGED_IN:
                    setLegacyItem(RefreshToken, response.refresh_token);
                    window.location.replace(`${window.origin}/v2`);
                    break;
                case SubmitOtpFormResult.TWO_FACTOR_AUTH_LOGIN_REQUIRED:
                    if (!attemptKey) {
                        return;
                    }
                    navigate(get2faNavigationTo(attemptKey));
                    break;
                case SubmitOtpFormResult.START_AUTHENTICATION_TWO_FACTOR_AUTH_REQUIRED:
                    // Regular login 2FA required, overall not enforced
                    return;
                case SubmitOtpFormResult.PASSWORD_CHANGE_REQUIRED:
                    if (response.validation_errors) {
                        setValidatorResults(response.validation_errors as ValidatorResult[]);
                    }
                    break;
                default: {
                    UnreachableCode.never(responseType);
                }
            }
        },
        [
            attemptKey,
            get2faNavigationTo,
            getConfirmationNavigationTo,
            getContinueToAddCompanyNavigationUrl,
            getContinueToCompanRegistrationOnboardingNavigationUrl,
            getContinueToCompanRegistrationStepsNavigationUrl,
            navigate,
            setErrorMessage,
            setValidatorResults,
            waitSecondsOnSubmitSuccess,
        ],
    );

    const onSubmitForm = useCallback(
        (formData: FleetOwnerVerificationNoAuthService.GetOtpFormResponse) =>
            async (
                state: FormState<FormValueType>,
                confirmation2fa?: FleetOwnerVerificationNoAuthService.TwoFactorAuthConfirmationType,
            ) => {
                setErrorMessage(undefined);
                const {data} = state;
                try {
                    const response = await FleetOwnerVerificationNoAuthApiClient.submitOtpForm({
                        ...getFormToApiValues<FleetOwnerVerificationNoAuthService.SubmitOtpFormRequest>(
                            data,
                            formData.fields,
                        ),
                        attempt_key: attemptKey ?? "",
                        type: formData.form_type,
                        device_uuid: deviceInfo.device_uid,
                        device_name: getUserAgent(),
                        device_os_version: deviceInfo.device_os_version,
                        two_factor_auth_confirmation: confirmation2fa,
                    });
                    handleSubmitResponse(response);
                } catch (error) {
                    if (isServerError(error)) {
                        if (error.response.code === ResponseCodes.TWO_FACTOR_AUTH_CONFIRMATION_REQUIRED) {
                            navigate(get2faConfirmationNavigationTo(attemptKey ?? ""));
                        } else if (error.response.code === ResponseCodes.TWO_FACTOR_AUTH_LOGIN_REQUIRED) {
                            navigate(get2faNavigationTo(attemptKey ?? ""));
                        }
                    }
                    // Ignore other errors
                }
            },
        [
            attemptKey,
            deviceInfo,
            get2faConfirmationNavigationTo,
            get2faNavigationTo,
            handleSubmitResponse,
            navigate,
            setErrorMessage,
        ],
    );

    return {onSubmitForm};
};
