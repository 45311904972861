import {useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";
import {getYearMonthDate} from "common/util/FormatUtil";
import {TranslationKeys} from "config/translations";
import {compareAsc, differenceInCalendarMonths, setDate, sub} from "date-fns/esm";

import {SelectOption} from "@bolteu/kalep-react";

import {MAX_MONTHS_TO_SHOW_IN_MONTH_SELECTOR} from "../../contants";

export const usePeriodOptions = (fleetCreatedUnixTime: number | undefined) => {
    const {i18n} = useI18n();

    const periodOptions = useMemo(() => {
        if (!fleetCreatedUnixTime) {
            return [];
        }
        let startDate = setDate(new Date(), 1);
        const fleetCreatedDate = new Date(fleetCreatedUnixTime * 1000);
        const monthsFromCreated = differenceInCalendarMonths(startDate, fleetCreatedDate) + 1;
        const monthsToSub = Math.min(monthsFromCreated, MAX_MONTHS_TO_SHOW_IN_MONTH_SELECTOR);
        const endDate = sub(startDate, {months: monthsToSub});

        const options: SelectOption[] = [];
        while (compareAsc(startDate, endDate) === 1) {
            const dateValue = getYearMonthDate(startDate);
            if (startDate.getMonth() === 11 || options.length === 0) {
                options.push({value: null, title: startDate.getFullYear().toString(), disabled: true});
            }
            options.push({
                value: dateValue,
                title: `${i18n(`months.${startDate.getMonth()}` as TranslationKeys)} ${startDate.getFullYear()}`,
            });
            startDate = sub(startDate, {months: 1});
        }
        return options;
    }, [fleetCreatedUnixTime, i18n]);

    return periodOptions;
};
