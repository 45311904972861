import {FC, useCallback} from "react";

import CopyIconButton from "common/components/copyIconButton/CopyIconButton";
import {EventName} from "common/constants/events";
import {I18nFn, useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton} from "@bolteu/kalep-react";
import {Call} from "@bolteu/kalep-react-icons";

import DriverBasicInfoContainer from "../driver/DriverBasicInfoContainer";
import {DriverInfo} from "../types";
import DriverDetailsHeader from "./DriverDetailsHeader";
import {InfoRow} from "./InfoRow";
import OrderDetails from "./OrderDetails";

interface DriverDetailsContainerProps {
    driverInfo: DriverInfo;
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null;
    onBackPressed: () => void;
    showRoute?: () => void;
}

const getVehicle = (driver: FleetOwnerPortalService.DriverForFleetOwner, i18n: I18nFn) => {
    const colorStr = driver.car_color ? `, ${i18n(`vehicle.color.${driver.car_color}`)}` : "";
    return `${driver.car_reg_number} • ${driver.car_manufacturer} ${driver.car_model} ${driver.car_year}${colorStr}`;
};

const getCategories = (driver: FleetOwnerPortalService.DriverForFleetOwner): string => {
    if (!driver.categories?.length) {
        return "-";
    }
    return driver.categories.map((category) => category.name).join(", ");
};

const DriverDetailsContainer: FC<DriverDetailsContainerProps> = ({
    driverInfo,
    lastDropoffAndWaitingTime,
    onBackPressed,
    showRoute,
}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const {driver} = driverInfo;

    const driverCalled = useCallback(() => {
        trackEvent(EventName.LiveMapCallDriverClicked);
    }, [trackEvent]);

    const location = `${driver.lat}, ${driver.lng}`;

    return (
        <div className="flex h-full flex-col gap-1 py-4 md:pt-8">
            <DriverDetailsHeader onBackPressed={onBackPressed} driverId={driver.id} />
            <div className="overflow-y-auto px-6">
                <DriverBasicInfoContainer driver={driver} nameOnly />
                <div>
                    <InfoRow title={i18n("auth.app.fleet.live-map.vehicle")} value={getVehicle(driver, i18n)} />
                    <InfoRow title={i18n("auth.app.fleet.live-map.category")} value={getCategories(driver)} />
                    <InfoRow
                        title={i18n("auth.app.fleet.live-map.location")}
                        value={location}
                        icon={
                            <CopyIconButton
                                value={location}
                                eventOnClick={EventName.LiveMapDriverCoordinatesCopyClicked}
                            />
                        }
                    />
                    {lastDropoffAndWaitingTime && (
                        <LastDropoffAndWaitingTime lastDropoffAndWaitingTime={lastDropoffAndWaitingTime} />
                    )}
                    <InfoRow
                        title={i18n("common.phone")}
                        value={driver.phone}
                        icon={
                            <a href={`tel:${driver.phone}`}>
                                <IconButton
                                    aria-label="Call the driver"
                                    size="sm"
                                    icon={<Call />}
                                    onClick={driverCalled}
                                />
                            </a>
                        }
                    />
                </div>
                {driver.state === FleetOwnerPortalService.DriverState.HAS_ORDER && (
                    <OrderDetails driverInfo={driverInfo} showRoute={showRoute} />
                )}
            </div>
        </div>
    );
};

function LastDropoffAndWaitingTime(props: {
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null;
}) {
    const {i18n} = useI18n();

    return (
        <>
            <InfoRow
                title={i18n("auth.app.fleet.live-map.last-order.last-dropoff")}
                value={contructAddressAndTime(
                    props.lastDropoffAndWaitingTime?.last_dropoff?.last_dropoff_address,
                    props.lastDropoffAndWaitingTime?.last_dropoff?.last_dropoff_time,
                )}
            />
            <InfoRow
                title={i18n("auth.app.fleet.live-map.last-order.waiting-for-orders")}
                value={minutesFromNow(props.lastDropoffAndWaitingTime?.time_switched_to_waiting || 0)}
            />
        </>
    );
}

function contructAddressAndTime(address?: string, unixTimestamp?: number): string {
    if (!address || !unixTimestamp) {
        return "-";
    }

    const hours = new Date(unixTimestamp).getHours();
    const minutes = new Date(unixTimestamp).getMinutes();

    return `${address} • ${hours}:${minutes}`;
}

function minutesFromNow(unixTimestamp: number): string {
    const date = new Date(unixTimestamp * 1000);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const minutes = Math.floor(diff / 1000 / 60);
    return `${minutes} min`;
}

export default DriverDetailsContainer;
