import {useCallback} from "react";

import TextButton from "common/components/TextButton";
import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {Breaks} from "./card/Breaks";
import {CardHeader} from "./card/CardHeader";

interface Props {
    shift: FleetShiftManagementService.ShiftWithAllocationSummary;
}

export const ShiftCard = ({shift}: Props) => {
    const {i18n} = useI18n();

    const onEditClick = useCallback(() => {
        // eslint-disable-next-line no-console -- TODO replace with logic
        console.log("Edit shift clicked, id: ", shift.id);
    }, [shift.id]);

    return (
        <div className="border-neutral-secondary w-full flex-col rounded-lg border pb-2">
            <CardHeader shift={shift} />
            <div className="flex flex-col gap-6 p-4">
                <Typography fontWeight="semibold">
                    {i18n("auth.app.fleet.work_shifts.planning.card.duration")}
                    <Typography inline>
                        {i18n("auth.app.fleet.work_shifts.planning.card.duration_time", {
                            startTime: shift.start_time,
                            endTime: shift.end_time,
                        })}
                    </Typography>
                </Typography>
                <Breaks breaks={shift.breaks} />
                <div className="px-6">
                    <TextButton onClick={onEditClick} text={i18n("common.edit")} />
                </div>
            </div>
        </div>
    );
};
