import {Typography} from "@bolteu/kalep-react";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";

interface Props {
    valueInMinutes: number;
    hoursLabel: string;
    minutesLabel: string;
}

const HoursAndMinutesCell = ({valueInMinutes, hoursLabel, minutesLabel}: Props) => {
    const hours = Math.floor(valueInMinutes / 60);
    const minutes = Math.round(valueInMinutes - hours * 60);

    return (
        <DefaultCellContent className="text-primary">
            <div className="flex gap-1 whitespace-nowrap">
                {hours !== 0 ? (
                    <div>
                        {hours} <span className="text-xs text-neutral-700">{hoursLabel}</span>
                    </div>
                ) : null}
                <div>
                    {minutes} <span className="text-xs text-neutral-700">{minutesLabel}</span>
                </div>
            </div>
        </DefaultCellContent>
    );
};

export default HoursAndMinutesCell;

export const KalepAccordionTableHoursAndMinutesCell = ({valueInMinutes, hoursLabel, minutesLabel}: Props) => {
    const hours = Math.floor(valueInMinutes / 60);
    const minutes = Math.round(valueInMinutes - hours * 60);

    return (
        <div className="flex gap-1 whitespace-nowrap">
            {hours !== 0 ? (
                <Typography>
                    {hours}{" "}
                    <Typography fontSize="text-sm" color="secondary" inline>
                        {hoursLabel}
                    </Typography>
                </Typography>
            ) : null}
            <Typography>
                {minutes}{" "}
                <Typography fontSize="text-sm" color="secondary" inline>
                    {minutesLabel}
                </Typography>
            </Typography>
        </div>
    );
};
