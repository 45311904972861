import {useContext} from "react";

import {AccountContextProvider} from "features/account/accountStateProvider";

import {CompanyDropdown} from "./CompanyDropdown";

const CompanySelector = () => {
    const accountState = useContext(AccountContextProvider);

    const {profile} = accountState;
    const selectedCompany = accountState.selectedCompany?.company;

    if (!profile) {
        return <p className="truncate py-3 text-sm">{selectedCompany?.name}</p>;
    }

    return (
        <CompanyDropdown
            fleets={profile.fleets}
            selectedCompany={accountState.selectedCompany}
            holdingFleets={profile.holdingFleets}
        />
    );
};

export default CompanySelector;
