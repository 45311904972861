import {useContext, useEffect} from "react";
import {matchRoutes, useLocation} from "react-router-dom";

import {PrivateRoutes, PublicRoutes} from "AppRoutes";
import {FullScreenLoadingSpinner} from "common/components/spinner";
import {CommonRoute, VerificationKeyMinLength} from "common/constants/routes";
import {useTracking} from "common/hooks/useTracking";
import {ResponseCodes} from "common/ResponseCodes";
import {ApiContextProvider} from "common/services/apiProvider";
import {HasLoggedIn, restoreRefreshToken} from "common/services/authService";
import {setLegacyItem} from "common/services/localStorageService";
import * as mixpanel from "common/services/mixpanel";
import {isServerError} from "common/util/isErrorType";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {useAccountState} from "features/account/hooks/useAccountState";
import {ApplicationContextProvider} from "features/app/appStateProvider";
import RefreshAppDialog from "RefreshAppDialog";

const AuthInit = () => {
    const appState = useContext(ApplicationContextProvider);
    const api = useContext(ApiContextProvider);
    const accountState = useContext(AccountContextProvider);

    const currentLocation = useLocation();
    const verificationRoutes = [
        CommonRoute.VERIFICATION,
        CommonRoute.VERIFICATION_PASSWORD,
        CommonRoute.VERIFICATION_CONFIRMATION,
        CommonRoute.VERIFICATION_2FA,
        CommonRoute.VERIFICATION_2FA_CONFIRMATION,
    ].map((route) => ({path: route}));
    const verificationRouteMatches = matchRoutes(verificationRoutes, currentLocation);
    const isVerificationPage =
        verificationRouteMatches &&
        verificationRouteMatches?.length > 0 &&
        verificationRouteMatches?.[0].params?.key &&
        verificationRouteMatches?.[0].params?.key.length >= VerificationKeyMinLength;

    const {setProfileFromApi, refreshProfile} = useAccountState();

    const {trackPageLoaded} = useTracking();

    const {setIsAppReady, isAppReady, setIsAuthenticated} = appState;

    useEffect(() => {
        async function initApp() {
            if (appState && !appState.isAppReady && !appState.refreshToken) {
                const refreshToken = restoreRefreshToken();
                if (isVerificationPage) {
                    if (refreshToken) {
                        mixpanel.reset();
                    }
                    appState.setIsAppReady(true);
                    return;
                }

                if (refreshToken) {
                    setLegacyItem(HasLoggedIn, "true");
                    appState.setRefreshToken(refreshToken);
                } else {
                    appState.setIsAppReady(true);
                }
            }
        }
        initApp();
    }, [appState, isVerificationPage]);

    useEffect(() => {
        async function getUser() {
            if (accountState && api && !accountState.profile?.fleets) {
                try {
                    const user = await api.fleetOwner.portalGetProfile();
                    setProfileFromApi(accountState, user);
                    if (user?.get_profile_polling_interval) {
                        setTimeout(refreshProfile, user.get_profile_polling_interval * 1000);
                    }

                    mixpanel.identify(user.id);

                    setIsAuthenticated(true);

                    if (!isAppReady && setIsAppReady) {
                        setIsAppReady(true);
                    }
                } catch (error) {
                    if (isServerError(error)) {
                        if (
                            error.response.code === ResponseCodes.FLEET_OWNER_NOT_FOUND ||
                            error.response.code === ResponseCodes.FLEET_OWNER_NOT_APPROVED
                        ) {
                            appState.setIsAppReady(true);
                        }
                    }
                }
            }
        }

        getUser();
    }, [setIsAppReady, setIsAuthenticated, isAppReady, api, accountState, setProfileFromApi, refreshProfile, appState]);

    useEffect(() => {
        trackPageLoaded();
    }, [trackPageLoaded]);

    return appState.isAppReady ? (
        <>
            <PrivateRoutes />
            <PublicRoutes />
            <RefreshAppDialog />
        </>
    ) : (
        <FullScreenLoadingSpinner />
    );
};

export default AuthInit;
