import config from "config/index";

import {FleetOwnerPortal, HttpClientLibrary, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {HttpClientBoltServer, HttpRequestOpts} from "@bolteu/http-client-bolt-server";

import {getLanguageWithDefaultLocale} from "../localStorageService";
// Import Auth & Sharding strategies
import {AppJwtAuthStrategy} from "./strategies/AppJwtAuthStrategy";

export class AppApiClient extends FleetOwnerPortal.ApiClient<HttpRequestOpts> {
    public httpClient: HttpClientBoltServer<FleetOwnerPortal.HttpClientConstraints>;
    public orderFleetShardedApi: OrderFleetService.ApiClient;
    private readonly orderFleetApiClient: HttpClientBoltServer<OrderFleetService.HttpClientConstraints>;
    public authenticationStrategy: {jwt: AppJwtAuthStrategy} | undefined;
    constructor() {
        const httpClient = new HttpClientBoltServer<FleetOwnerPortal.HttpClientConstraints>({
            baseUrl: config.baseUrl,
        });
        super({httpClient});
        this.httpClient = httpClient;

        this.orderFleetApiClient = new HttpClientBoltServer<OrderFleetService.HttpClientConstraints>({
            baseUrl: config.baseUrlOld,
        });
        this.orderFleetShardedApi = new OrderFleetService.ApiClient({
            httpClient: this.orderFleetApiClient,
            apiClientOptions: {shardingStrategy: HttpClientLibrary.ShardingStrategy.URL, virtualPathsEnabled: false},
        });
    }

    init(jwtAuthStrategy: AppJwtAuthStrategy, companyId?: number, userId?: number, shardId?: string) {
        const authStrategy = {
            jwt: jwtAuthStrategy,
        };
        this.authenticationStrategy = authStrategy;
        const standardRequestStrategy = {
            getStandardRequest: () => {
                return {
                    language: getLanguageWithDefaultLocale(),
                    version: config.version,
                    company_id: companyId,
                    user_id: userId,
                    brand: config.appBrand as unknown as OrderFleetService.BrandEnum,
                };
            },
        };
        const shardingStrategy = {
            url: {
                getShardKey: () => shardId,
            },
            header: {
                getShardKey: () => -1,
            },
        };
        this.httpClient.init({
            authStrategy,
            standardRequestStrategy,
            shardingStrategy,
        });

        this.orderFleetApiClient.init({
            authStrategy,
            standardRequestStrategy,
            shardingStrategy,
        });
    }
}
