import {useCallback, useMemo, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {debounce} from "lodash-es";

import {TextField} from "@bolteu/kalep-react";

interface Props {
    onChange: (search: string) => void;
    placeholder?: string;
}

const TableSearchField = ({onChange, placeholder}: Props) => {
    const {i18n} = useI18n();
    const placeholderText = placeholder || i18n("auth.app.fleet.reports.search_by_name");

    const [tableSearchValue, setTableSearchValue] = useState("");

    const searchDebounce = useMemo(() => debounce((newSearch: string) => onChange(newSearch), 500), [onChange]);

    const onSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setTableSearchValue(e.target.value);
            searchDebounce(e.target.value);
        },
        [searchDebounce],
    );

    return (
        <div className="w-96">
            <TextField
                fullWidth
                type="search"
                value={tableSearchValue}
                onChange={onSearchChange}
                placeholder={placeholderText}
            />
        </div>
    );
};

export default TableSearchField;
