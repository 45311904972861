import {FC} from "react";

import {UnreachableCode} from "common/components/util/UnreachableCode";
import {I18nFn, useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";
import {ChipColor} from "@bolteu/kalep-react/build/components/Chip/Chip.types";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

interface DriverProfileBodyStatusChipProps {
    status: EntityPortalStatus;
}

const getChipLabel = (i18n: I18nFn, driverStatus: EntityPortalStatus): string | null => {
    switch (driverStatus) {
        case EntityPortalStatus.Blocked:
            return i18n("driver.status.blocked");
        case EntityPortalStatus.Deactivated:
            return i18n("driver.status.deactivated");
        case EntityPortalStatus.Active:
            return null;
        default:
            return UnreachableCode.never(driverStatus, null);
    }
};

const getChipColor = (driverStatus: EntityPortalStatus): ChipColor | null => {
    switch (driverStatus) {
        case EntityPortalStatus.Blocked:
            return "danger";
        case EntityPortalStatus.Deactivated:
            return "neutral";
        case EntityPortalStatus.Active:
            return null;
        default:
            return UnreachableCode.never(driverStatus, null);
    }
};

const DriverProfileBodyStatusChip: FC<DriverProfileBodyStatusChipProps> = ({status}) => {
    const {i18n} = useI18n();

    const chipLabel = getChipLabel(i18n, status);
    const chipColor = getChipColor(status);

    if (!chipLabel || !chipColor) {
        return null;
    }

    return <Chip label={chipLabel} size="sm" color={chipColor} variant="filled" />;
};

export default DriverProfileBodyStatusChip;
