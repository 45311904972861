import {useTailwindViewport} from "common/hooks/useTailwindViewport";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ApiDrivenTableDesktop from "./ApiDrivenTableDesktop";
import {ApiDrivenTableMobile} from "./ApiDrivenTableMobile";
import {useApiDrivenTableColumns} from "./Common";

interface ApiDrivenTableProps {
    columns: FleetOwnerPortalService.ApiColumn[];
    numberOfRecords: number;
    tableId: string;
}

export default function ApiDrivenTable(apiDrivenTableProps: ApiDrivenTableProps) {
    const columns = useApiDrivenTableColumns(apiDrivenTableProps.columns, apiDrivenTableProps.tableId);

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    if (isMobileView) {
        return <ApiDrivenTableMobile {...columns} numberOfRecords={apiDrivenTableProps.numberOfRecords} />;
    }

    return <ApiDrivenTableDesktop {...columns} numberOfRecords={apiDrivenTableProps.numberOfRecords} />;
}
