import {useCallback, useContext, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {VerificationContextProvider} from "features/account/pages/Verification/VerificationProvider";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton} from "@bolteu/kalep-react";

import {useVerificationOtherMethods} from "./hooks/useVerificationOtherMethods";
import {MobileOtherMethods} from "./MobileOtherMethods";
import {OtherMethods} from "./OtherMethods";

import VerificationChannel = FleetOwnerVerificationNoAuthService.VerificationChannel;

interface Props {
    seconds: number;
}

const ResendCode = ({seconds}: Props) => {
    const {i18n} = useI18n();
    const {requestCode, isLoading} = useContext(VerificationContextProvider);
    const [isDidNotReceiveCodeClicked, setIsDidNotReceiveCodeClicked] = useState(false);
    const {isOnlyVoiceChannelEnabled, handleCallMe} = useVerificationOtherMethods();

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const handleDidNotReceiveCode = useCallback(() => {
        setIsDidNotReceiveCodeClicked(true);
    }, []);

    const handleResendCode = useCallback(() => {
        requestCode({channel: VerificationChannel.SMS});
    }, [requestCode]);

    const requestNewCodeClassName = isLoading ? "text-secondary" : "text-action-primary";
    const ResendCodeLink = () => (
        <GhostButton overrideClassName={requestNewCodeClassName} onClick={handleResendCode} disabled={isLoading}>
            {i18n("login.phone.resendCode")}
        </GhostButton>
    );

    const CallMeLink = () => (
        <GhostButton overrideClassName={requestNewCodeClassName} onClick={handleCallMe} disabled={isLoading}>
            {i18n("login.phone.callMeInstead")}
        </GhostButton>
    );

    const Others = () => {
        if (isOnlyVoiceChannelEnabled) {
            return <CallMeLink />;
        }

        if (isMobileView) {
            return <MobileOtherMethods />;
        }
        return <OtherMethods />;
    };

    if (!isDidNotReceiveCodeClicked) {
        return (
            <GhostButton overrideClassName="text-action-primary" onClick={handleDidNotReceiveCode} disabled={isLoading}>
                {i18n("login.phone.didNotReceiveCode")}
            </GhostButton>
        );
    }

    const isTimerRunning = seconds > 0;
    if (isTimerRunning) {
        const NumberOfSeconds = () => <span className="font-semibold">{seconds}</span>;
        return (
            <>
                {i18n("login.phone.resendCodeSeconds", {
                    number_of_seconds: <NumberOfSeconds />,
                })}
            </>
        );
    }

    return (
        <>
            {i18n("common.options_or", {
                option1: <ResendCodeLink />,
                option2: <Others />,
            })}
        </>
    );
};

export {ResendCode};
