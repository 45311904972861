import {useCallback, useContext} from "react";
import {generatePath} from "react-router-dom";

import {CommonRoute, CompanyOnboardingRoutePrefix, FleetRoute} from "common/constants/routes";
import {isActiveFleet} from "common/constants/types";
import {AccountContextProvider} from "features/account/accountStateProvider";

export const useAbsolutePath = () => {
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState?.getFleet();
    const fleetId = fleet?.id;
    const isCompanyOnboarding = !isActiveFleet(fleet);

    const getHomePath = useCallback(() => {
        if (isCompanyOnboarding) {
            return `/${CompanyOnboardingRoutePrefix}/`;
        }
        return CommonRoute.HOME;
    }, [isCompanyOnboarding]);

    const getCarRentalsPath = useCallback(() => {
        return `/${fleetId}/${FleetRoute.CAR_RENTAL_PAYMENT}`;
    }, [fleetId]);

    const getDriverDetailsPath = useCallback(
        (id: number) => generatePath(`${getHomePath()}${fleetId}/${FleetRoute.DRIVER}`, {id: String(id)}),
        [fleetId, getHomePath],
    );
    const getVehicleDetailsPath = useCallback(
        (id: number) => generatePath(`${getHomePath()}${fleetId}/${FleetRoute.VEHICLE}`, {id: String(id)}),
        [fleetId, getHomePath],
    );
    const getVehicleApplicationPath = useCallback(
        (id: number) => generatePath(`${getHomePath()}${fleetId}/${FleetRoute.VEHICLE_APPLICATION}`, {id: String(id)}),
        [fleetId, getHomePath],
    );
    const getCompanyRegistrationPath = useCallback(
        (hash: string) => generatePath(`${CommonRoute.REGISTER_COMPANY}`, {hash: String(hash)}),
        [],
    );
    const getCompanyOnboardingPath = useCallback(
        (companyId: string) => generatePath(`${CommonRoute.ONBOARD_COMPANY}`, {companyId: String(companyId)}),
        [],
    );

    const getVerificationPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION}`, {key: attemptKey}),
        [],
    );

    const getVerificationPasswordPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_PASSWORD}`, {key: attemptKey}),
        [],
    );

    const getVerificationConfirmationPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_CONFIRMATION}`, {key: attemptKey}),
        [],
    );

    const getVerification2faPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_2FA}`, {key: attemptKey}),
        [],
    );

    const getVerification2faConfirmationPath = useCallback(
        (attemptKey: string) => generatePath(`${CommonRoute.VERIFICATION_2FA_CONFIRMATION}`, {key: attemptKey}),
        [],
    );

    const getVehiclesPath = useCallback(
        () => generatePath(`${getHomePath()}${fleetId}/${FleetRoute.VEHICLES}`),
        [fleetId, getHomePath],
    );

    const getDriversPath = useCallback(
        () => generatePath(`${getHomePath()}${fleetId}/${FleetRoute.DRIVERS}`),
        [fleetId, getHomePath],
    );

    return {
        getCarRentalsPath,
        getVehiclesPath,
        getDriversPath,
        getDriverDetailsPath,
        getVehicleDetailsPath,
        getVehicleApplicationPath,
        getCompanyRegistrationPath,
        getCompanyOnboardingPath,
        getHomePath,
        getVerificationPath,
        getVerificationPasswordPath,
        getVerificationConfirmationPath,
        getVerification2faPath,
        getVerification2faConfirmationPath,
    };
};
