import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

export enum ShiftTab {
    Planning = "planning",
    Log = "log",
}

export function useTabSelectorOptions(): Tab<ShiftTab>[] {
    const {i18n} = useI18n();

    const tabs: Tab<ShiftTab>[] = [
        {
            id: ShiftTab.Planning,
            title: i18n("auth.app.fleet.work_shifts.shift_planning"),
            trackingEventTabName: TabNameEvent.ShiftPlanning,
        },
        {
            id: ShiftTab.Log,
            title: i18n("auth.app.fleet.work_shifts.shift_log"),
            trackingEventTabName: TabNameEvent.ShiftLog,
        },
    ];

    return tabs;
}
