import {useCallback, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {Spinner} from "@bolteu/kalep-react";

import {NoShifts} from "./components/NoShifts";
import {useShifts} from "./hooks/useShifts";

const fetchFunction = (api: Api) => api.fleetShiftManagement.getShifts();

export const Planning = () => {
    const {data: shifts, fetch: fetchShifts, status: shiftGetStatus} = useFetch(fetchFunction);
    const shiftsList = useShifts({shifts: shifts?.list, fetchStatus: shiftGetStatus});

    const getShifts = useCallback(() => {
        if (fetchShifts) {
            fetchShifts({});
        }
    }, [fetchShifts]);

    useEffect(() => {
        getShifts();
    }, [getShifts]);

    let Body: JSX.Element | null = null;
    if ([FetchStatus.Init, FetchStatus.Loading].includes(shiftGetStatus)) {
        Body = (
            <div className="m-auto mt-16 flex justify-center">
                <Spinner />
            </div>
        );
    } else if (shiftGetStatus === FetchStatus.Error) {
        Body = <ErrorView type={ErrorViewType.SomethingWentWrong} onActionClick={getShifts} />;
    } else if (!shiftsList) {
        Body = <NoShifts />;
    } else {
        Body = shiftsList;
    }

    return <>{Body}</>;
};
