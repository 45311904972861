import {useCallback} from "react";

import CopyIconButton from "common/components/copyIconButton/CopyIconButton";
import {ResponsiveDialog} from "common/components/dialog/ResponsiveDialog";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {useTracking} from "common/hooks/useTracking";

import {Dialog, Link, LinkButton, TextField} from "@bolteu/kalep-react";

import {NewDriverRegistrationData} from "./InviteDriverDialog";

export interface CompleteRegistrationDialogProps {
    isOpen: boolean;
    closeDialog: () => void;
    registrationData: NewDriverRegistrationData | null;
}

const CompleteRegistrationDialog = ({isOpen, closeDialog, registrationData}: CompleteRegistrationDialogProps) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const completeRegistrationClicked = useCallback(
        () => trackEvent(EventName.CompleteDriverRegistrationButtonClicked),
        [trackEvent],
    );

    const sendRegistrationLinkToDriver = useCallback(
        () => trackEvent(EventName.CompleteDriverRegistrationButtonClicked),
        [trackEvent],
    );

    const CopyLinkButton = useCallback((value: string) => () => <CopyIconButton value={value} />, []);

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    if (!registrationData) {
        return <></>;
    }

    return (
        <ResponsiveDialog
            title={i18n("auth.app.fleet.add-driver.driver_application")}
            isOpen={isOpen}
            onRequestClose={closeDialog}
        >
            <div>
                <Dialog.Content>
                    <div className="text-neutral-700">
                        <p>
                            {i18n("auth.app.fleet.add-driver.application_has_been_created", {
                                email: registrationData.email,
                            })}
                        </p>
                        {registrationData.email && registrationData.registration_link && (
                            <>
                                <p className="mt-3">
                                    {i18n("auth.app.fleet.add-driver.complete_registration_yourself")}
                                    <Link
                                        href={`mailto:${registrationData.email}?subject=${i18n(
                                            "auth.app.fleet.add-driver.bolt_driver_registration",
                                        )}&body=${registrationData.registration_link}`}
                                        onClick={sendRegistrationLinkToDriver}
                                    >
                                        {i18n("auth.app.fleet.add-driver.send_link_to_driver")}
                                    </Link>
                                </p>
                                <div className="mt-3">
                                    <TextField
                                        label={i18n("auth.app.fleet.add-driver.invitation_link")}
                                        renderEndSlot={CopyLinkButton(registrationData.registration_link)}
                                        value={registrationData.registration_link}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </Dialog.Content>
            </div>
            <div className="md:flex md:flex-row md:justify-center">
                <Dialog.Footer>
                    {registrationData.registration_link && (
                        <LinkButton
                            href={registrationData.registration_link}
                            target="_blank"
                            onClick={completeRegistrationClicked}
                            fullWidth={isMobileView}
                        >
                            {i18n("auth.app.fleet.driver-registrations.complete-registration")}
                        </LinkButton>
                    )}
                </Dialog.Footer>
            </div>
        </ResponsiveDialog>
    );
};

export default CompleteRegistrationDialog;
