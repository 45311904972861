import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import TextButton from "common/components/TextButton";
import {CommonRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";

import {MenuHeader} from "@bolteu/kalep-react";

const Header = ({
    onAllNotificationsSetRead,
    hasUnreadMessages,
    isPartOfMenu = false,
}: {
    onAllNotificationsSetRead: () => void;
    hasUnreadMessages: boolean;
    isPartOfMenu: boolean;
}) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();

    const navigateToNotificationPreferences = useCallback(() => {
        navigate(`../${CommonRoute.SETTINGS}`);
    }, [navigate]);

    const readAllButton = hasUnreadMessages && (
        <TextButton
            onClick={onAllNotificationsSetRead}
            className="text-sm font-normal"
            text={i18n("auth.app.notifications.mark_all_as_read")}
        />
    );

    if (isPartOfMenu) {
        return (
            <MenuHeader>
                <div className="m-6 mt-4 flex flex-row items-baseline justify-between">
                    <h1 className="text-3xl font-semibold">{i18n("auth.app.notifications.title")}</h1>
                    {readAllButton}
                </div>
            </MenuHeader>
        );
    }
    const NotificationPreferencesLink = () => (
        <TextButton
            onClick={navigateToNotificationPreferences}
            className="text-sm font-normal"
            text={i18n("auth.app.notifications.set_notification_preferences")}
        />
    );
    return (
        <div className="mb-6 flex flex-row items-baseline justify-between">
            <div className="flex flex-col gap-4">
                <ResponsiveHeader type={ResponsiveHeaderType.InnerPage} text={i18n("auth.app.notifications.title")} />
                <div className="font-normal text-neutral-700">
                    {i18n("auth.app.notifications.description", {
                        set_notification_preferences: <NotificationPreferencesLink />,
                    })}
                </div>
            </div>
            {readAllButton}
        </div>
    );
};

export default Header;
