import CoffeeBreak from "assets/icons/coffee-break.svg?react";
import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

interface Props {
    breaks: FleetShiftManagementService.Break[];
}

export const Breaks = ({breaks}: Props) => {
    const {i18n} = useI18n();

    const shouldShowBreakNumbers = breaks.length > 1;

    return (
        <>
            {breaks.map((breakObj, index) => (
                <div
                    key={`${breakObj.start_time}-${breakObj.end_time}`}
                    className="flex items-center justify-start gap-2"
                >
                    <CoffeeBreak />
                    <div className="flex flex-col">
                        <Typography>
                            {i18n("auth.app.fleet.work_shifts.planning.card.duration_time", {
                                startTime: breakObj.start_time,
                                endTime: breakObj.end_time,
                            })}
                        </Typography>
                        <Typography variant="body-secondary" color="secondary">
                            {i18n("auth.app.fleet.work_shifts.planning.card.break")}
                            {shouldShowBreakNumbers && ` ${index + 1}`}
                        </Typography>
                    </div>
                </div>
            ))}
        </>
    );
};
