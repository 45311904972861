import {Location, NavigateFunction} from "react-router-dom";

import {EventName} from "common/constants/events";
import {CommonRoute} from "common/constants/routes";
import {getDeviceInfo} from "common/util/browserInfo";
import {createReturnUrl, getPageNameFromRoute, getReturnPath} from "common/util/routeUtils";
import {AccountState} from "features/account/accountStateProvider";

import {FleetOwnerAuthNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {FleetOwnerAuthNoAuthApiClient} from "./apiClients/noAuthApiClient";
import {getLegacyItem, removeItems, removeLegacyItems, setLegacyItem} from "./localStorageService";
import * as mixpanel from "./mixpanel";

export const ActiveCompanyStorage = "active_company_id"; // Used to sync company selection between FOPs
export const AuthKeyStorage = "auth";
export const LocaleCode = "locale";
export const RefreshToken = "refresh_token";
export const HasLoggedIn = "hasLoggedIn";
export const LocationItem = "ip_location_data";

export const createAuthKey = (username: string, password: string) =>
    window.btoa(`${window.unescape(encodeURIComponent(username))}:${window.unescape(encodeURIComponent(password))}`);

export const parseAuthKey = (authKey: string) => {
    const decoded = window.atob(authKey);
    const separatorIndex = decoded.indexOf(":");
    if (separatorIndex < 0) {
        return null;
    }

    const decode = (input: string) => decodeURIComponent(window.escape(input));

    return {
        username: decode(decoded.substring(0, separatorIndex)),
        password: decode(decoded.substring(separatorIndex + 1)),
    };
};

export const removeStorageItems = () => {
    removeItems([AuthKeyStorage, ActiveCompanyStorage]);
    removeLegacyItems([RefreshToken]);
};

export const logout = async (accountState: AccountState, sendEvents: boolean) => {
    const language = getLegacyItem(LocaleCode) || undefined;
    const pageName = getPageNameFromRoute(window.location.pathname) || undefined;

    removeStorageItems();

    if (sendEvents) {
        await mixpanel
            .trackEvent({
                eventName: EventName.LogoutCompleted,
                companyId: accountState.selectedCompany?.company?.id,
                language,
                pageName,
            })
            .finally(mixpanel.reset);
    } else {
        await mixpanel.reset();
    }
};

export const redirectToLogin = (navigate: NavigateFunction, location: Location) => {
    const returnUrl = getReturnPath(location);
    if (returnUrl) {
        const query = createReturnUrl(returnUrl);
        navigate(`${CommonRoute.AUTH}?${query}`);
    } else {
        navigate(CommonRoute.AUTH);
    }
};

export const requestNewRefreshToken = async (username: string, password: string): Promise<string> => {
    const authResponse = await FleetOwnerAuthNoAuthApiClient.startAuthentication(
        {} as unknown as {version: string}, // TODO: API should allow undefined version
        {
            username,
            password,
            ...getDeviceInfo(),
        },
    );

    // TODO: implement 2FA later
    if (authResponse.type === FleetOwnerAuthNoAuthService.AuthResult.SUCCESS) {
        const token = authResponse.refresh_token;
        setLegacyItem(RefreshToken, token);
        setLegacyItem(HasLoggedIn, "true");

        return token;
    }

    throw new Error("Failed to get refresh token");
};

export const requestNewAccessToken = async (
    refreshToken: string | undefined,
    selectedCompanyId: number | undefined,
    selectedCompanyType: FleetOwnerAuthNoAuthService.CompanyIdentityType | undefined,
): Promise<string> => {
    if (!refreshToken) {
        throw new Error("No refresh token provided");
    }

    let company: FleetOwnerAuthNoAuthService.CompanyIdentity | undefined;
    if (selectedCompanyId && selectedCompanyType) {
        company = {
            company_id: selectedCompanyId,
            company_type: selectedCompanyType,
        };
    }

    const token = await FleetOwnerAuthNoAuthApiClient.getAccessToken({
        refresh_token: refreshToken,
        company,
    });

    return token.access_token;
};

export const restoreRefreshToken = () => getLegacyItem(RefreshToken);
