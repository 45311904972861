import {FC} from "react";

export enum HeaderSize {
    Large = "large",
    Medium = "medium",
    Small = "small",
    ExtraSmall = "extra-small",
}

export interface HeaderProps {
    size: HeaderSize;
    text: string;
    disabled?: boolean;
    className?: string;
}

function getHeaderSize(type: HeaderSize) {
    switch (type) {
        case HeaderSize.ExtraSmall:
            return "text-xl";
        case HeaderSize.Small:
            return "text-2xl";
        case HeaderSize.Medium:
            return "text-3xl";
        case HeaderSize.Large:
        default:
            return "text-6xl";
    }
}

const Header: FC<HeaderProps> = ({size, text, disabled, className = ""}) => {
    const textSize = getHeaderSize(size);
    const textColor = disabled ? "text-tertiary" : "text-neutral-900";

    return <h1 className={`${textSize} leading-14 font-semibold tracking-normal ${textColor} ${className}`}>{text}</h1>;
};

export default Header;
