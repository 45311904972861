import {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from "react";

import {useTabs} from "common/components/tab/useTabs";
import {UnreachableCode} from "common/components/util/UnreachableCode";
import {useI18n} from "common/hooks/useI18n";
import {debounce} from "lodash-es";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {TextField} from "@bolteu/kalep-react";

import {DriverStats} from "../types";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

export type DriverFilterStatus =
    | FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS
    | FleetOwnerPortalService.DriverState.HAS_ORDER;

export interface DriverFilter {
    term: string;
    status: DriverFilterStatus;
}

interface FilterProps {
    filter: DriverFilter;
    onFilterChange: (filter: DriverFilter) => void;
    driverStats: DriverStats;
}

const Filter: FC<FilterProps> = ({filter, onFilterChange, driverStats}) => {
    const {i18n} = useI18n();

    const tabs = useTabSelectorOptions(driverStats);
    const {activeTab, TabSelector} = useTabs(tabs, FleetOwnerPortalService.DriverState.HAS_ORDER);

    const [termInputValue, setTermInputValue] = useState(filter.term);

    const termDebounce = useMemo(
        () => debounce((newTerm: string) => onFilterChange({...filter, term: newTerm}), 500),
        [filter, onFilterChange],
    );

    const updateTerm = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setTermInputValue(e.target.value);
            termDebounce(e.target.value);
        },
        [setTermInputValue, termDebounce],
    );

    const setFilterStatus = useCallback(
        (id: DriverFilterStatus) => {
            if (id === filter.status) {
                return;
            }

            switch (id) {
                case FleetOwnerPortalService.DriverState.HAS_ORDER:
                    onFilterChange({...filter, status: FleetOwnerPortalService.DriverState.HAS_ORDER});
                    break;
                case FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS:
                    onFilterChange({...filter, status: FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS});
                    break;
                default:
                    UnreachableCode.never(id);
            }
        },
        [filter, onFilterChange],
    );

    useEffect(() => {
        setFilterStatus(activeTab);
    }, [activeTab, setFilterStatus]);

    return (
        <div className="flex flex-col flex-wrap gap-2 px-6">
            <TextField
                fullWidth
                id="term"
                type="search"
                value={termInputValue}
                placeholder={i18n("auth.app.fleet.live-map.search-placeholder")}
                onChange={updateTerm}
            />
            <TabSelector />
        </div>
    );
};

export default Filter;
