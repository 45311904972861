import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import LoadingSpinner from "common/components/spinner";
import TextButton from "common/components/TextButton";
import {useReactRouterLinkForBreadcrumb} from "common/hooks/useBreadcrumbRenderItem";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {CarRentalPaymentService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Breadcrumb, Button} from "@bolteu/kalep-react";

import CarRentalPaymentsInfo from "./components/CarRentalPaymentsInfo";
import ConfirmDeletionDialog from "./components/ConfirmDeletionDialog";
import ConfirmPayments from "./components/ConfirmPayments";
import PaymentsTable from "./components/PaymentsTable";
import Timeline from "./components/Timeline";
import UploadCarRentalPayments from "./components/UploadCarRentalPayments";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";

export enum UploadStatus {
    UPLOADED = "UPLOADED",
    NOT_UPLOADED = "NOT_UPLOADED",
    COMPLETED = "COMPLETED",
    IN_PROGRESS = "IN_PROGRESS",
}

export interface ProcessedFile {
    fileContent: string;
    payments: CarRentalPaymentService.CarRentalPaymentUploadData[];
}

const getUploadedPaymentsFetchFunction = (api: Api) => api.carRentalPayment.carRentalPaymentGetUploadedPayments();

const getDeadlinesFetchFunction = (api: Api) => api.carRentalPayment.carRentalPaymentGetDeadlines();

const CarRentalsPage: FC = () => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const breadcrumbs = useBreadcrumbs(fleet?.id);
    const {
        data: uploadedPayments,
        fetch: fetchUploadedPayments,
        status: uploadedPaymentsStatus,
    } = useFetch(getUploadedPaymentsFetchFunction);
    const {data: deadlines, fetch: fetchDeadlines, status: deadlinesStatus} = useFetch(getDeadlinesFetchFunction);

    const [processedFile, setProcessedFile] = useState<ProcessedFile | null>(null);
    const [rentalPayments, setRentalPayments] = useState<CarRentalPaymentService.GetCarRentalPaymentData[]>([]);
    const [isConfirmDeletionDialogVisible, setConfirmDeletionDialogVisible] = useState(false);
    const [isInfoPageShown, setIsInfoPageShown] = useState(false);

    const isCarRentalPaymentDisabled = !fleet?.targeting.car_rental_payments_enabled;

    useEffect(() => {
        if (uploadedPaymentsStatus === FetchStatus.Success) {
            setRentalPayments(uploadedPayments.list);
        }
    }, [uploadedPaymentsStatus, uploadedPayments]);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.car-rentals.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const getUploadedPayments = useCallback(async () => {
        if (isCarRentalPaymentDisabled || !fetchUploadedPayments) {
            return;
        }

        fetchUploadedPayments({});
    }, [isCarRentalPaymentDisabled, fetchUploadedPayments]);

    const getDeadlines = useCallback(async () => {
        if (isCarRentalPaymentDisabled || !fetchDeadlines) {
            return;
        }

        fetchDeadlines({});
    }, [isCarRentalPaymentDisabled, fetchDeadlines]);

    const deleteList = useCallback(async () => {
        setConfirmDeletionDialogVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setConfirmDeletionDialogVisible(false);
    }, []);

    const setPaymentsRemoved = useCallback(() => {
        setConfirmDeletionDialogVisible(false);
        setRentalPayments([]);
        getDeadlines();
    }, [setRentalPayments, getDeadlines]);

    useEffect(() => {
        getUploadedPayments();
        getDeadlines();
    }, [getDeadlines, getUploadedPayments]);

    const uploadedPaymentsConfirmed = useCallback(async () => {
        setProcessedFile(null);
        getUploadedPayments();
        getDeadlines();
    }, [getDeadlines, getUploadedPayments]);

    const handleFileUpload = useCallback(
        (payments: CarRentalPaymentService.CarRentalPaymentUploadData[], fileContent: string) => {
            setProcessedFile({payments, fileContent});
        },
        [],
    );

    const closeConfirmPayments = useCallback(() => {
        setProcessedFile(null);
    }, []);

    const downloadCsvTemplate = useCallback(() => {
        const templateCsv = {
            title: i18n("auth.app.fleet.car-rentals.template_csv"),
            content: "driver_uuid,amount\n123abcd-12345678912345abcd-23456abcd,150.20",
        };
        const element = document.createElement("a");
        const file = new Blob([templateCsv.content], {type: "text/csv"});

        element.href = URL.createObjectURL(file);
        element.download = `${templateCsv.title}.csv`;
        document.body.appendChild(element);
        element.click();
    }, [i18n]);

    const thisTemplateTextBtn = useMemo(
        () => (
            <TextButton onClick={downloadCsvTemplate} text={i18n("auth.app.fleet.car-rentals.description_template")} />
        ),
        [downloadCsvTemplate, i18n],
    );

    const showInfoPage = useCallback(() => setIsInfoPageShown(true), []);
    const closeInfoPage = useCallback(() => setIsInfoPageShown(false), []);

    const knowMoreTextBtn = useMemo(
        () => <TextButton onClick={showInfoPage} text={i18n("auth.app.fleet.car-rentals.description_know_more")} />,
        [i18n, showInfoPage],
    );

    if (isCarRentalPaymentDisabled) {
        return (
            <ErrorView
                type={ErrorViewType.ServiceUnavailable}
                customTitle={i18n("auth.app.fleet.car-rentals.not_available_title")}
            />
        );
    }

    return (
        <>
            {isInfoPageShown && <CarRentalPaymentsInfo closePage={closeInfoPage} />}
            {!isInfoPageShown && (
                <div className="h-full w-full">
                    {processedFile !== null && (
                        <ConfirmPayments
                            payments={processedFile.payments}
                            fileContent={processedFile.fileContent}
                            close={closeConfirmPayments}
                            paymentsConfirmed={uploadedPaymentsConfirmed}
                        />
                    )}
                    {processedFile === null && (
                        <div className="container mx-auto mb-14 flex flex-col gap-y-6 px-6 pt-4 lg:pt-8">
                            <Breadcrumb items={breadcrumbs} renderItem={useReactRouterLinkForBreadcrumb} />
                            <ResponsiveHeader
                                type={ResponsiveHeaderType.MainPage}
                                text={i18n("auth.app.fleet.car-rentals.title")}
                            />
                            <p className="max-w-3xl">
                                {/* i18n can't process React components */}
                                {intl.formatMessage(
                                    {id: "auth.app.fleet.car-rentals.description"},
                                    {
                                        templateLink: thisTemplateTextBtn,
                                        knowMoreLink: knowMoreTextBtn,
                                    },
                                )}
                            </p>
                            <div className="container mx-auto flex flex-row flex-wrap justify-between gap-6">
                                {uploadedPaymentsStatus === FetchStatus.Loading && (
                                    <div className="relative">
                                        <LoadingSpinner show />
                                    </div>
                                )}
                                {uploadedPaymentsStatus !== FetchStatus.Loading && rentalPayments.length === 0 && (
                                    <UploadCarRentalPayments onFileUpload={handleFileUpload} />
                                )}
                                {uploadedPaymentsStatus === FetchStatus.Success && rentalPayments.length !== 0 && (
                                    <>
                                        <div className="grow flex-col">
                                            <PaymentsTable payments={rentalPayments} />
                                            <div className="my-10">
                                                <Button variant="secondary" onClick={deleteList}>
                                                    {i18n("auth.app.fleet.car-rentals.delete_list")}
                                                </Button>
                                            </div>
                                        </div>
                                        <ConfirmDeletionDialog
                                            show={isConfirmDeletionDialogVisible}
                                            closeModal={closeModal}
                                            setPaymentsRemoved={setPaymentsRemoved}
                                            currentPeriodUploadTimestamp={
                                                deadlinesStatus === FetchStatus.Success
                                                    ? deadlines.current_period_upload_ts
                                                    : 0
                                            }
                                        />
                                    </>
                                )}
                                {deadlinesStatus === FetchStatus.Success && (
                                    <Timeline
                                        currentPeriodUploadTimestamp={deadlines.current_period_upload_ts}
                                        currentPeriodUploadStatus={deadlines.current_period_upload_status}
                                        previousPeriodUploadTimestamp={deadlines.previous_period_upload_ts}
                                        previousPeriodUploadStatus={deadlines.previous_period_upload_status}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CarRentalsPage;
