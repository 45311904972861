import {DocumentsList} from "common/components/document/DocumentsList";
import {DocumentOwnerType, useDocumentsList} from "common/components/document/useDocumentsList";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ApplicationData from "./ApplicationData";
import Header from "./Header";
import CarApplicationStatus = CarApplicationService.CarApplicationStatus;

interface Props {
    application: CarApplicationService.GetCarApplicationResponse;
    reloadApplication: () => void;
}

const Application = ({application, reloadApplication}: Props) => {
    const {documentsListProps} = useDocumentsList({
        areDocumentsEnabled: true,
        owner: {id: application.id, type: DocumentOwnerType.VehicleApplication},
        onDocumentUpload: reloadApplication,
        isUploadAllowed: true,
    });

    const shouldShowDocumentsList = ![
        CarApplicationStatus.DELETED,
        CarApplicationStatus.DECLINED,
        CarApplicationStatus.DECLINED_SILENTLY,
    ].includes(application.status);

    return (
        <div className="mt-6">
            <Header application={application} />
            <div className="flex w-full flex-row flex-wrap justify-between gap-8">
                <div className="w-1/2 max-w-[480px]">
                    <ApplicationData application={application} />
                </div>
                {shouldShowDocumentsList && (
                    <div className="w-1/2 max-w-[400px]">
                        {documentsListProps && <DocumentsList {...documentsListProps} />}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Application;
