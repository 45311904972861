import {getIsoDate} from "common/util/FormatUtil";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

interface NewToggleEntityData {
    portal_status: EntityPortalStatus;
    deactivation_details: FleetOwnerPortalService.DeactivationDetails | null;
}

export function getNewToggleEntityData(entityPortalStatus: EntityPortalStatus): NewToggleEntityData | undefined {
    switch (entityPortalStatus) {
        case EntityPortalStatus.Active:
            return {
                portal_status: EntityPortalStatus.Deactivated,
                deactivation_details: {
                    is_deactivated_by_fleet_owner: true,
                    deactivated_since: getIsoDate(new Date()),
                },
            };
        case EntityPortalStatus.Deactivated:
            return {
                portal_status: EntityPortalStatus.Active,
                deactivation_details: null,
            };
        default:
            return undefined;
    }
}
