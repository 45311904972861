import {useTailwindViewport} from "common/hooks/useTailwindViewport";

export const MobileSectionSeparator = () => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    if (!isMobileView) {
        return null;
    }

    return (
        <div className="h-10">
            <div className="bg-layer-floor-0-grouped absolute inset-x-0 flex h-10 flex-col gap-2">
                <div className="h-4 rounded-b-full bg-white" />
                <div className="h-4 rounded-t-full bg-white" />
            </div>
        </div>
    );
};
