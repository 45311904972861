export enum PageName {
    LOGIN = "Login Page v2",
    FORGOT_PASSWORD = "Forgot Password Page v2",
    RESET_PASSWORD = "Reset Password Page v2",
    SETTINGS = "Settings Page",
    VERIFICATION = "Verification Page",
    VERIFICATION_PASSWORD = "Verification Password Page",
    VERIFICATION_CONFIRMATION = "Verification Confirmation Page",
    VERIFICATION_2FA = "Verification 2FA Page",
    VERIFICATION_2FA_CONFIRMATION = "Verification 2FA Confirmation Page",
    SMS_NOTIFICATION = "SMS Page",
    // Companies
    LIVE_MAP = "Live Map",
    EXPIRING_DOCUMENTS = "Expiring Documents Page",
    DRIVERS = "Drivers Dashboard Page",
    DRIVER = "Driver Profile Page",
    DRIVER_REGISTRATIONS = "Driver Applications Page",
    DRIVER_REGISTRATION = "Driver Application Page",
    DRIVER_INVITATION = "Driver Invitation Page",
    CAR_RENTAL_PAYMENT = "Car Rental Payment Page",
    REPORTS = "Reports Page",
    COMPANY_SETTINGS = "Company Settings Page",
    VEHICLES = "Vehicles Page",
    VEHICLE = "Vehicle Details Page",
    VEHICLE_APPLICATION = "Vehicle Application Page",
    INVOICES = "Invoices Page",
    ORDERS = "Orders Page",
    CAMPAIGNS = "Campaigns Page",
    CAMPAIGN_DETAILS = "Campaign Details Page",
    BALANCE = "Balance Page",
    NOTIFICATIONS = "Notifications Page",
    API_CREDENTIALS = "API Credentials Page",
    SHIFTS = "Shifts Page",
    // Holding Fleets
    ENTERPRISE_INVOICES = "Enterprise Invoices Page",
    REGISTER_COMPANY = "Company Registration Page",
}

export const publicPageNames: string[] = [
    PageName.LOGIN,
    PageName.FORGOT_PASSWORD,
    PageName.RESET_PASSWORD,
    PageName.VERIFICATION,
    PageName.VERIFICATION_PASSWORD,
    PageName.VERIFICATION_CONFIRMATION,
    PageName.VERIFICATION_2FA,
    PageName.VERIFICATION_2FA_CONFIRMATION,
];
