import {useCallback, useState} from "react";

import Pagination from "common/components/Pagination";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import LoadingSpinner from "common/components/spinner";
import ApiDrivenTablesCustomizationDrawer from "common/components/table/ApiDrivenTable/ApiDrivenTableCustomizationDrawer";
import ApiDrivenTable from "common/components/table/ApiDrivenTable/index";
import TableSearchField from "common/components/table/TableSearchField";
import {useI18n} from "common/hooks/useI18n";
import {selectPeriodOptionsUntilNow} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import NoVehicles from "./NoVehicles";
import VehiclesDownload from "./VehiclesDownload";
import VehiclesFilter, {VehicleFilter} from "./VehiclesFilter";
import FilteredBy from "./VehiclesFilteredBy";

export const VEHICLES_TABLE_ID = "vehicle-list-table";

export interface VehicleTableProps {
    columnsResponse: FleetOwnerPortalService.GetVehicleListResponse | null;
    pageSize: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    vehiclesCount: number;
    isLoading: boolean;
    openAddVehiclePage: () => void;
    onSearchChange: (search: string) => void;
    filters: VehicleFilter;
    setFilters: (filters: VehicleFilter) => void;
    selectedPeriod: ApiPeriod;
    onPeriodChange: (period: ApiPeriod) => void;
}

function VehiclesTableV2(vehicleTableProps: VehicleTableProps) {
    const {currentPage, vehiclesCount, pageSize, onPageChange, isLoading, columnsResponse} = vehicleTableProps;

    const {i18n} = useI18n();

    const [isInvalidPeriod, setIsInvalidPeriod] = useState<boolean>(false);

    const onPeriodErrorStateChange = useCallback((hasError: boolean) => {
        setIsInvalidPeriod(hasError);
    }, []);

    return (
        <>
            <div className="flex flex-col gap-4 lg:flex-row lg:justify-between">
                <div className="mb-1 flex flex-[2] gap-4 sm:mb-5">
                    <TableSearchField
                        onChange={vehicleTableProps.onSearchChange}
                        placeholder={i18n("auth.app.fleet.dashboard.vehicles.search_by_model_plate")}
                    />
                    <VehiclesFilter filters={vehicleTableProps.filters} setFilters={vehicleTableProps.setFilters} />
                    <ApiDrivenTablesCustomizationDrawer
                        tableId={VEHICLES_TABLE_ID}
                        columns={columnsResponse?.columns ?? []}
                    />
                </div>
                <div className="flex gap-4">
                    <PeriodDatePicker
                        period={vehicleTableProps.selectedPeriod}
                        selectPeriodOptions={selectPeriodOptionsUntilNow}
                        onPeriodChange={vehicleTableProps.onPeriodChange}
                        dateRangeLimitDays={31}
                        onErrorStateChange={onPeriodErrorStateChange}
                    />
                    <VehiclesDownload period={vehicleTableProps.selectedPeriod} isError={isInvalidPeriod} />
                </div>
            </div>
            <FilteredBy filters={vehicleTableProps.filters} setFilters={vehicleTableProps.setFilters} />
            {isLoading && <LoadingSpinner show parentClassName="mt-2" />}
            {columnsResponse?.columns && columnsResponse.columns.length > 0 ? (
                <ApiDrivenTable
                    columns={columnsResponse?.columns}
                    numberOfRecords={columnsResponse?.columns[0].cells.length ?? 0}
                    tableId={VEHICLES_TABLE_ID}
                />
            ) : (
                !isLoading && <NoVehicles openAddVehiclePage={vehicleTableProps.openAddVehiclePage} />
            )}
            <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={vehiclesCount}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </>
    );
}

export default VehiclesTableV2;
