import {FC} from "react";

import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {useId} from "@react-aria/utils";

import {BaseModal, Drawer, DrawerProps} from "@bolteu/kalep-react";

const MobileDrawer: FC<DrawerProps> = ({isOpen, title, onRequestClose, children}: DrawerProps) => {
    const titleId = useId();

    return (
        <BaseModal isOpen={isOpen} onRequestClose={onRequestClose} aria-labelledby={titleId} placement="m-0">
            <div className="bg-layer-floor-1 flex h-screen w-screen flex-col">
                <BaseModal.Header title={title} titleId={titleId}>
                    <BaseModal.Close onClose={onRequestClose} />
                </BaseModal.Header>
                {children}
            </div>
        </BaseModal>
    );
};

const ResponsiveDrawer: FC<DrawerProps> = (props: DrawerProps) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return isMobileView ? <MobileDrawer {...props} /> : <Drawer {...props} />;
};

export {ResponsiveDrawer};
