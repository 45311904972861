import {FC, useCallback, useRef, useState} from "react";

import {EventName} from "common/constants/events";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {useTracking} from "common/hooks/useTracking";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton} from "@bolteu/kalep-react";
import {Cross, List, Map} from "@bolteu/kalep-react-icons";

import {useLiveMap} from "../hooks/useLiveMap";
import DriverDetailsContainer from "./detail-view/DriverDetailsContainer";
import DriverList from "./list/DriverList";
import {ResponsiveMapView} from "./map/ResponsiveMap";

export type MobileViewMode = "list" | "map" | "details";

export const ResponsiveLiveMap: FC = () => {
    const [previewDriverId, setPreviewDriverId] = useState<number | null>(null);
    const [viewMode, setViewMode] = useState<MobileViewMode>("map");
    const fromViewModeToDetail = useRef<"map" | "list">("map");
    const {trackEvent} = useTracking();

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const setToMapMode = useCallback(() => {
        setPreviewDriverId(null);
        setViewMode("map");
    }, []);
    const setToListMode = useCallback(() => setViewMode("list"), []);
    const setToDetailsMode = useCallback(() => setViewMode("details"), []);

    const resetViewMode = useCallback(() => {
        if (!isMobileView) {
            return;
        }
        if (fromViewModeToDetail.current === "map") {
            setToMapMode();
        } else {
            setToListMode();
        }
    }, [isMobileView, setToListMode, setToMapMode]);

    const {
        filteredDrivers,
        selectedDriver,
        driverFilter,
        driverStats,
        onDriverMapSelected,
        onFilterChange,
        onDriverListSelected,
        onDriverDeselected,
        lastDropoffAndWaitingTime,
    } = useLiveMap({
        isMobileMapOrDetailsView: isMobileView && ["map", "details"].includes(viewMode),
        resetOnDriverStateChange: resetViewMode,
    });

    const onListClick = useCallback(
        (driver: FleetOwnerPortalService.DriverForFleetOwner | null) => {
            onDriverListSelected(driver);
            if (isMobileView) {
                fromViewModeToDetail.current = "list";
                setToDetailsMode();
            }
        },
        [isMobileView, onDriverListSelected, setToDetailsMode],
    );

    const setPreviewDriver = useCallback(
        (driverId: number | null) => {
            setPreviewDriverId(driverId);
            if (driverId && isMobileView) {
                trackEvent(EventName.LiveMapDriverPreviewClicked);
            }
        },
        [isMobileView, trackEvent],
    );

    const onMapClick = useCallback(
        (driver: FleetOwnerPortalService.DriverForFleetOwner) => {
            onDriverMapSelected(driver);
            if (isMobileView) {
                fromViewModeToDetail.current = "map";
                setToDetailsMode();
            }
        },
        [isMobileView, onDriverMapSelected, setToDetailsMode],
    );

    const onDriverDeselect = useCallback(() => {
        onDriverDeselected();
        resetViewMode();
    }, [onDriverDeselected, resetViewMode]);

    const onListFabClick = useCallback(() => {
        setToListMode();
        trackEvent(EventName.LiveMapFabDriverListClicked);
    }, [setToListMode, trackEvent]);

    const onMapFabClick = useCallback(() => {
        setToMapMode();
        trackEvent(EventName.LiveMapFabMapClicked);
    }, [setToMapMode, trackEvent]);

    const showRoute = useCallback(() => {
        setToMapMode();
        trackEvent(EventName.LiveMapShowRouteClicked);
    }, [setToMapMode, trackEvent]);

    let ListAndMapFabButton =
        isMobileView && ["list", "map"].includes(viewMode) ? (
            <IconButton
                icon={viewMode === "list" ? <Map /> : <List />}
                aria-label="Toggle list and map view"
                variant="floating"
                size="lg"
                overrideClassName="[&>span]:bg-white [&>span]:shadow-lg"
                onClick={viewMode === "list" ? onMapFabClick : onListFabClick}
            />
        ) : null;
    const CloseRouteDetailsFabButton =
        isMobileView && viewMode === "map" && selectedDriver ? (
            <IconButton
                icon={<Cross />}
                aria-label="Back to drivers details"
                variant="floating"
                size="lg"
                overrideClassName="[&>span]:bg-white [&>span]:shadow-lg"
                onClick={setToDetailsMode}
            />
        ) : null;
    if (viewMode === "map" && selectedDriver) {
        ListAndMapFabButton = null;
    }

    return (
        <div className="w-full flex-col">
            <div className="relative">
                <ResponsiveMapView
                    drivers={filteredDrivers}
                    onDriverSelected={onMapClick}
                    selectedDriver={selectedDriver}
                    previewDriverId={previewDriverId}
                    isMobileView={isMobileView}
                    isMapView={viewMode === "map"}
                    lastDropoffAndWaitingTime={lastDropoffAndWaitingTime}
                    onDriverPreviewSelected={setPreviewDriver}
                />
                <div
                    className={`md:map-overlay absolute inset-0 z-10 bg-white shadow md:inset-y-10 md:left-10 md:max-h-[900px] md:rounded-lg ${
                        isMobileView && viewMode === "map" && "hidden"
                    }`}
                >
                    {!selectedDriver ? (
                        <DriverList
                            filteredDrivers={filteredDrivers}
                            filter={driverFilter}
                            onFilterChange={onFilterChange}
                            onDriverSelected={onListClick}
                            driverStats={driverStats}
                        />
                    ) : (
                        <DriverDetailsContainer
                            driverInfo={selectedDriver}
                            lastDropoffAndWaitingTime={lastDropoffAndWaitingTime}
                            onBackPressed={onDriverDeselect}
                            showRoute={isMobileView ? showRoute : undefined}
                        />
                    )}
                </div>
            </div>
            <div className="fixed left-3 top-[68px] z-20">{CloseRouteDetailsFabButton}</div>
            <div id="live-map-fab" className="fixed right-4 bottom-6 z-20">
                {ListAndMapFabButton}
            </div>
        </div>
    );
};
