import {FC, useCallback, useEffect, useState} from "react";

import VehicleIcon from "assets/icons/vehicle.svg?react";
import CloseButton from "common/components/CloseButton";
import {DocumentType} from "common/components/document/useDocumentsList";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {ValidatorResult} from "common/constants/types";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import UploadVehicleDocument from "./UploadVehicleDocument";

export interface AddVehicleDocumentsPageProps {
    closePage: () => void;
    documentTypes: DocumentType[];
    vehicleApplicationId: number;
}

const getUploadedDocumentsFunction = (api: Api, body: CarApplicationService.GetCarApplicationDocumentsRequest) =>
    api.carApplication.v2GetCarApplicationDocuments(body);

const getErrorMessage = (fieldName: string, validatorErrors: ValidatorResult[]): string | undefined => {
    const validationError = validatorErrors.filter((error) => error.property === fieldName)?.[0];
    return validationError?.error;
};

const AddVehicleDocumentsPage: FC<AddVehicleDocumentsPageProps> = ({
    closePage,
    documentTypes,
    vehicleApplicationId,
}) => {
    const {i18n} = useI18n();

    const {fetch, status, data} = useFetch(getUploadedDocumentsFunction);

    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);

    const onValidateForm = useCallback(() => {
        if (fetch) {
            fetch({car_application_id: vehicleApplicationId});
        }
    }, [fetch, vehicleApplicationId]);

    useEffect(() => {
        if (!data) {
            return;
        }

        const {list: uploadedDocs} = data;

        const validationErrors: ValidatorResult[] = documentTypes
            .filter(
                (docType) => !uploadedDocs.find((doc) => doc.document_type_id === docType.id) && docType.is_required,
            )
            .map((docType) => ({error: i18n("document.required_document"), property: docType.name}));

        setValidatorResults(validationErrors);

        if (status === FetchStatus.Success && !validationErrors.length) {
            closePage();
        }
    }, [setValidatorResults, data, documentTypes, i18n, closePage, status]);

    return (
        <div className="m-auto my-10 flex w-[484px] max-w-3xl flex-col justify-center">
            <div className="flex justify-end">
                <CloseButton onClick={closePage} />
            </div>
            <VehicleIcon />
            <div className="my-6 flex flex-col gap-3">
                <ResponsiveHeader
                    type={ResponsiveHeaderType.InnerPage}
                    text={i18n("auth.app.fleet.add-vehicle.add_vehicle")}
                />
            </div>
            <div className="flex flex-col gap-6">
                {documentTypes.map((docType) => (
                    <div key={docType.id}>
                        <UploadVehicleDocument
                            documentType={docType}
                            vehicleApplicationId={vehicleApplicationId}
                            error={getErrorMessage(docType.name, validatorResults)}
                        />
                    </div>
                ))}
            </div>
            <div className="mt-6">
                <Button variant="primary" onClick={onValidateForm}>
                    {i18n("auth.app.fleet.add-vehicle.add_vehicle")}
                </Button>
            </div>
        </div>
    );
};

export default AddVehicleDocumentsPage;
