import {Fragment, ReactElement} from "react";

import {Dash} from "common/constants";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";
import {ArrowRight, ArrowRightDouble} from "@bolteu/kalep-react-icons";

interface Props {
    order: FleetOwnerReportingService.FleetPortalV2Order;
}

const OrderRoute = ({order}: Props) => {
    const orderStops = order.order_stops?.sort((x, y) => x.id - y.id);
    if (!orderStops || !orderStops.length) {
        return <span>{Dash.EM}</span>;
    }

    const TooltipWrapper = ({children}: {children: ReactElement}) => {
        return (
            <Tooltip content={orderStops.map((x) => x.address).join(" → ")} delay={400}>
                {children}
            </Tooltip>
        );
    };

    const routeStart = orderStops[0];
    const routeEnd = orderStops[orderStops.length - 1];
    const orderStopsCount = orderStops.length;
    const Wrapper = orderStopsCount > 2 ? TooltipWrapper : Fragment;

    return (
        <Wrapper>
            <div key={routeStart.id} className="w-full overflow-hidden">
                {routeStart.address} {orderStopsCount === 2 && <ArrowRight size="xs" />}
                {orderStopsCount > 2 && <ArrowRightDouble size="xs" />}
                {orderStopsCount > 1 && <span>{` ${routeEnd.address}`}</span>}
            </div>
        </Wrapper>
    );
};

export {OrderRoute};
