import {useCallback, useEffect, useMemo, useState} from "react";

import LoadingSpinner from "common/components/spinner";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {useI18n} from "common/hooks/useI18n";
import {debounce} from "lodash-es";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {TextField} from "@bolteu/kalep-react";

import {useTableColumns} from "../../common/hooks/useTableColumns";
import {useTableRows} from "../../common/hooks/useTableRows";
import {getUniqueColumnIndex} from "../../common/utils/getUniqueColumnIndex";
import CardRow from "../components/CardRow";
import GlossarySection from "../components/GlossarySection";
import NoParticipants from "./NoParticipants";

import GetCompanyCampaignParticipantsResponse = FleetOwnerPortalService.GetCampaignParticipantsResponse;
import ColumnType = FleetOwnerPortalService.ColumnType;
import EntityNameCell = FleetOwnerPortalService.EntityNameCell;

interface CampaignParticipantsProps {
    data: GetCompanyCampaignParticipantsResponse | null;
}

const CampaignParticipants = ({data}: CampaignParticipantsProps) => {
    const {i18n} = useI18n();

    const apiColumns = useMemo(() => data?.columns || [], [data?.columns]);
    const columns = useTableColumns(apiColumns);
    const {sortedRows, orderBy, handleOrderByChange} = useTableRows(apiColumns, ColumnType.ENTITY_NAME, "ASC");

    const [nameInputValue, setNameInputValue] = useState("");
    const [filteredRows, setFilteredRows] = useState(sortedRows);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFilteredRows(sortedRows);
    }, [sortedRows]);

    const filterRows = useCallback(
        (searchTerm: string) => {
            // TODO: temporary search filter on frontend until decided how to handle it on backend (https://taxify.atlassian.net/browse/DRX-8745)
            if (!sortedRows.length) {
                return;
            }

            const entityNameColumn = apiColumns.find((column) => column.type === ColumnType.ENTITY_NAME);
            if (!entityNameColumn) {
                return;
            }

            const uniqueColumnIndex = getUniqueColumnIndex(entityNameColumn.type, entityNameColumn.title);
            const rows = sortedRows.filter((row) => {
                const entityNameCell = row[uniqueColumnIndex] as EntityNameCell;
                const isValueIncludesSearchTerm = entityNameCell.value.toLowerCase().includes(searchTerm.toLowerCase());
                const isSubTextIncludesSearchTerm =
                    entityNameCell.subtext && entityNameCell.subtext.toLowerCase().includes(searchTerm.toLowerCase());
                return isValueIncludesSearchTerm || isSubTextIncludesSearchTerm;
            });
            setFilteredRows(rows);
            setIsLoading(false);
        },
        [apiColumns, sortedRows],
    );

    const debouncedFilterRows = useMemo(() => debounce(filterRows, 500), [filterRows]);

    const onSearchChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setNameInputValue(event.target.value);
            setIsLoading(true);
            debouncedFilterRows(event.target.value);
        },
        [debouncedFilterRows],
    );

    const renderEmptyPlaceholder = useCallback(() => {
        return <NoParticipants />;
    }, []);

    if (!data) {
        return <LoadingSpinner show />;
    }

    return (
        <div className="flex flex-col gap-8">
            {data.glossary && <GlossarySection content={data.glossary} />}
            {data.stat_cards.length !== 0 && <CardRow cards={data.stat_cards} isLoading={isLoading} />}
            <TextField
                type="search"
                value={nameInputValue}
                onChange={onSearchChange}
                placeholder={i18n("common.filters.search_by_name")}
            />
            <FixedHeaderTable
                isLoading={isLoading}
                columns={columns}
                items={filteredRows}
                orderBy={orderBy}
                onOrderByChange={handleOrderByChange}
                renderEmptyPlaceholder={renderEmptyPlaceholder}
                aria-label="Campaign participants table"
            />
        </div>
    );
};

export default CampaignParticipants;
