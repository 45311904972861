import {useCallback, useMemo} from "react";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {AutocompleteFieldOption} from "@bolteu/kalep-form-react/build/DataContainer/fields/AutocompleteField";
import {FilterOptionsState} from "@bolteu/kalep-form-react/build/utils/MUIUtils";
import {ListItemLayout, OptionProps, SelectOption} from "@bolteu/kalep-react";

import {CustomOption} from "../Combobox";
import {Icon} from "../Icon";

export const getComboboxOptions = (options: FleetOwnerRegistrationService.ComboBoxOption[]): CustomOption[] => {
    const customOptions: CustomOption[] = options.map(({value, label: optionLabel, appearance, icon}) => {
        return {
            value,
            title: optionLabel,
            appearance,
            icon,
        };
    });

    return customOptions;
};

export const useComboBox = (options: FleetOwnerRegistrationService.ComboBoxOption[]) => {
    const autocompleteFieldOptions: CustomOption[] = useMemo(() => getComboboxOptions(options), [options]);

    const hasHeaderInactiveOption = useMemo(
        () =>
            autocompleteFieldOptions.some(
                (o) => o.appearance === FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE,
            ),
        [autocompleteFieldOptions],
    );

    const getOptionLabel = useCallback(
        (option: SelectOption | null): string => {
            if (!option || !option?.value || !option?.title) {
                return "";
            }

            const customOption: CustomOption = option as CustomOption;
            const {
                appearance,
                value: optionValue,
                title,
            } = autocompleteFieldOptions.find((x) => x.value === customOption.value) as CustomOption;
            const optionTitle: string = title as string;

            if (!appearance) {
                return optionTitle;
            }

            let optionLabel;
            switch (appearance) {
                case FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE:
                    optionLabel = optionTitle;
                    break;
                case FleetOwnerRegistrationService.ComboBoxAppearance.COLUMN_SPLIT:
                    optionLabel = optionValue;
                    break;
                default:
                    optionLabel = optionTitle;
                    break;
            }

            return String(optionLabel);
        },
        [autocompleteFieldOptions],
    );

    const filterOptions = useCallback(
        (o: AutocompleteFieldOption[], state: FilterOptionsState<AutocompleteFieldOption>) => {
            return o.filter(
                (option) => (option.title as string).toLowerCase().indexOf(state.inputValue.toLowerCase()) >= 0,
            );
        },
        [],
    );

    const renderOption = useCallback(
        ({key, option, onClick, highlighted, selected, size}: OptionProps) => {
            const customOption: CustomOption = option as CustomOption;
            const {title, icon, appearance, value} = customOption;

            let primaryText;
            let renderStartSlot;
            let renderEndSlot;
            switch (appearance) {
                case FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE:
                    primaryText = title;
                    renderStartSlot = () => (icon ? <Icon config={icon} /> : null);
                    break;
                case FleetOwnerRegistrationService.ComboBoxAppearance.COLUMN_SPLIT:
                    primaryText = title;
                    renderStartSlot = () => (icon ? <Icon config={icon} /> : null);
                    renderEndSlot = () => <div>{value}</div>;
                    break;
                default:
                    primaryText = hasHeaderInactiveOption ? <span className="ml-10">{title}</span> : title;
                    break;
            }

            const shouldDisableSelection =
                appearance && appearance === FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE;

            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- Currently there is a strict typing in Kalep that you can only use <li>
                <li key={key} onClick={shouldDisableSelection ? undefined : onClick}>
                    <ListItemLayout
                        highlighted={highlighted}
                        selected={selected}
                        primary={primaryText}
                        variant={size === "sm" ? "sm" : "base"}
                        renderStartSlot={renderStartSlot}
                        renderEndSlot={renderEndSlot}
                    />
                </li>
            );
        },
        [hasHeaderInactiveOption],
    );

    return {
        autocompleteFieldOptions,
        getOptionLabel,
        filterOptions,
        renderOption,
    };
};
