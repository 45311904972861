import {FC, useCallback} from "react";

import {ConfirmActionDialogVariant} from "common/components/confirmActionDialog/ConfirmActionDialog";
import {shouldDisplayToggleDeactivationButton} from "common/components/toggleDeactivationButton/shouldDisplayToggleDeactivationButton";
import ToggleDeactivationButton from "common/components/toggleDeactivationButton/ToggleDeactivationButton";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

interface ToggleDriverDeactivationButtonProps {
    driverId: number;
    driverPortalStatus: EntityPortalStatus;
    isDeactivatedByFleetOwner: boolean;
    onSuccessAction: () => void;
}

const fetchFunction = (api: Api, query: FleetOwnerPortalService.ToggleDriverDeactivationRequest) =>
    api.fleetOwnerPortal.driverToggleDeactivation(query);

const ToggleDriverDeactivationButton: FC<ToggleDriverDeactivationButtonProps> = ({
    driverId,
    driverPortalStatus,
    isDeactivatedByFleetOwner,
    onSuccessAction,
}) => {
    const {i18n} = useI18n();
    const {fetch, status} = useFetch(fetchFunction);

    const handleConfirmToggle = useCallback(() => {
        if (fetch) {
            fetch({driver_id: driverId});
        }
    }, [fetch, driverId]);

    if (!shouldDisplayToggleDeactivationButton(driverPortalStatus, isDeactivatedByFleetOwner)) {
        return null;
    }

    let confirmButtonText;
    let titleText;
    let descriptionText;
    let snackbarText;
    let confirmButtonVariant;

    if (driverPortalStatus === EntityPortalStatus.Deactivated) {
        confirmButtonText = i18n("common.activate");
        confirmButtonVariant = ConfirmActionDialogVariant.Primary;
        titleText = i18n("auth.app.fleet.driver-profile.activation_action.dialog.title");
        descriptionText = i18n("auth.app.fleet.driver-profile.activation_action.dialog.text");
        snackbarText = i18n("auth.app.fleet.driver-profile.activation_action.snackbar.text");
    } else {
        confirmButtonText = i18n("common.deactivate");
        confirmButtonVariant = ConfirmActionDialogVariant.Danger;
        titleText = i18n("auth.app.fleet.driver-profile.deactivation_action.dialog.title");
        descriptionText = i18n("auth.app.fleet.driver-profile.deactivation_action.dialog.text");
        snackbarText = i18n("auth.app.fleet.driver-profile.deactivation_action.snackbar.text");
    }

    return (
        <ToggleDeactivationButton
            confirmButtonText={confirmButtonText}
            confirmButtonVariant={confirmButtonVariant}
            titleText={titleText}
            descriptionText={descriptionText}
            snackbarText={snackbarText}
            confirmAction={handleConfirmToggle}
            confirmActionStatus={status}
            onSuccessAction={onSuccessAction}
        />
    );
};

export default ToggleDriverDeactivationButton;
