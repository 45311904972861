import {FC, useCallback, useMemo, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import useMediaQuery from "common/hooks/useMediaQuery";

import {CarRentalPaymentService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, Tooltip} from "@bolteu/kalep-react";
import {Alert, ChevronDown} from "@bolteu/kalep-react-icons";

export interface PaymentsTableProps {
    payments: CarRentalPaymentService.CarRentalPaymentUploadData[];
    hasErrors?: boolean;
}

const MAX_SHOW_ROWS = 50;

const PaymentsTable: FC<PaymentsTableProps> = ({payments, hasErrors}) => {
    const {i18n} = useI18n();
    const [pageNum, setPageNum] = useState(1);
    const [mobileRowExpandedId, setMobileRowExpandedId] = useState<string | null>(null);

    const isWideScreen = useMediaQuery("(min-width: 1024px)");

    const paymentsShown = useMemo(() => {
        return payments.slice(0, pageNum * MAX_SHOW_ROWS);
    }, [pageNum, payments]);

    const currency = useMemo<string>(() => {
        const payment = payments.find((p) => p.currency !== undefined);
        return payment?.currency ?? "";
    }, [payments]);

    const headers = useMemo<string[]>(
        () => [
            i18n("auth.app.fleet.car-rentals.header.driver_uuid"),
            i18n("auth.app.fleet.car-rentals.header.driver_name"),
            i18n("auth.app.fleet.car-rentals.header.driver_phone"),
        ],
        [i18n],
    );

    const feeHeaderKey = useMemo(() => {
        return i18n("auth.app.fleet.car-rentals.header.amount", {currency}, undefined, true);
    }, [currency, i18n]);

    const feeHeaderRenderText = useMemo(() => {
        return i18n("auth.app.fleet.car-rentals.header.amount", {currency});
    }, [currency, i18n]);

    const onClickViewMore = useCallback(() => {
        setPageNum(pageNum + 1);
    }, [pageNum]);

    const emptyOnClick = useCallback(() => {}, []);

    const expandMobileRow = (driver_uuid: string) => () => {
        if (driver_uuid === mobileRowExpandedId) {
            setMobileRowExpandedId(null);
            return;
        }
        setMobileRowExpandedId(driver_uuid);
    };

    return (
        <div>
            {isWideScreen && (
                <table className={`min-w-full table-auto text-sm ${hasErrors ? "mt-4" : ""}`}>
                    <thead>
                        <tr className="border-b border-neutral-300">
                            {headers.map((headerStr) => (
                                <td key={headerStr} className="py-3 px-4">
                                    <div className="text-left font-semibold text-neutral-900">{headerStr}</div>
                                </td>
                            ))}
                            <td key={feeHeaderKey} className="py-3 px-4">
                                <div className="text-right font-semibold text-neutral-900">{feeHeaderRenderText}</div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentsShown.map((payment) => (
                            <tr
                                key={`${payment.driver_uuid}-${payment.amount}`}
                                className={`border-b border-neutral-300 hover:bg-neutral-300 ${
                                    payment.error ? "text-neutral-700" : ""
                                }`}
                            >
                                <td className="flex items-center gap-2 py-3 px-4">
                                    {!!payment.error && (
                                        <Tooltip
                                            content={i18n(
                                                `auth.app.fleet.car-rentals.confirm_payment.error.${payment.error}`,
                                            )}
                                            placement="bottom"
                                        >
                                            <GhostButton overrideClassName="text-red-500" onClick={emptyOnClick}>
                                                <Alert />
                                            </GhostButton>
                                        </Tooltip>
                                    )}
                                    {payment.driver_uuid}
                                </td>
                                <td className="py-3 px-4 text-left">{payment.driver_name ?? "-"}</td>
                                <td className="py-3 px-4 text-left">{payment.phone ?? "-"}</td>
                                <td className="py-3 px-4 text-right">{payment.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {!isWideScreen && (
                <div className="flex flex-col">
                    {paymentsShown.map((payment) => (
                        <div
                            key={`${payment.driver_uuid}-${payment.amount}`}
                            className="tap flex flex-col border-b border-neutral-300 py-3"
                            tabIndex={0}
                            onClick={expandMobileRow(payment.driver_uuid)}
                            onKeyDown={expandMobileRow(payment.driver_uuid)}
                            role="button"
                        >
                            <div className="flex items-center justify-between">
                                <div className="flex w-6/12 flex-col sm:w-9/12">
                                    {!payment.error && (
                                        <>
                                            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                {payment.driver_name ?? "-"}
                                            </span>
                                            <span>{payment.phone ?? "-"}</span>
                                        </>
                                    )}
                                    {!!payment.error && (
                                        <div className="flex items-center gap-1">
                                            <Alert className="text-red-500" />{" "}
                                            {i18n(`auth.app.fleet.car-rentals.confirm_payment.error.${payment.error}`)}
                                        </div>
                                    )}
                                </div>
                                <div className="flex gap-4">
                                    <span>{`${payment.amount} ${payment.currency}`}</span>
                                    <ChevronDown
                                        className={`duration-100 ease-linear ${
                                            mobileRowExpandedId === payment.driver_uuid ? "rotate-180" : ""
                                        }`}
                                    />
                                </div>
                            </div>
                            {mobileRowExpandedId === payment.driver_uuid && (
                                <>
                                    <span className="text-xs font-semibold">
                                        {i18n("auth.app.fleet.car-rentals.header.driver_uuid")}
                                    </span>
                                    <span className="text-xs sm:text-sm">{payment.driver_uuid}</span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {pageNum * MAX_SHOW_ROWS < payments.length && (
                <div className="text-center">
                    <GhostButton overrideClassName="py-4 text-green-700 text-sm" onClick={onClickViewMore}>
                        <div className="flex items-center justify-center gap-2">
                            {i18n("auth.app.fleet.car-rentals.view_next", {
                                next:
                                    (pageNum + 1) * MAX_SHOW_ROWS > payments.length
                                        ? payments.length - pageNum * MAX_SHOW_ROWS
                                        : MAX_SHOW_ROWS,
                                showing: pageNum * MAX_SHOW_ROWS,
                                all: payments.length,
                            })}
                            <ChevronDown size="sm" />
                        </div>
                    </GhostButton>
                </div>
            )}
        </div>
    );
};

export default PaymentsTable;
