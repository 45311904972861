import * as React from "react";
import {IntlShape, useIntl} from "react-intl";

import {AppBrand} from "../constants/appConstants";

type PrimitiveValues = string | number | boolean | null | undefined | Date;
type ComplexValues = PrimitiveValues | React.ReactNode;

export interface I18nFn<TranslationKeys> {
    (id: TranslationKeys): string;
    (id: TranslationKeys, values: undefined, defaultMessageId?: TranslationKeys): string;
    (id: TranslationKeys, values: Record<string, PrimitiveValues>, defaultMessageId?: TranslationKeys): React.ReactNode;
    (id: TranslationKeys, values: Record<string, ComplexValues>, defaultMessageId?: TranslationKeys): React.ReactNode;
    (
        id: TranslationKeys,
        values: Record<string, PrimitiveValues>,
        defaultMessageId?: TranslationKeys,
        skipSpanWrap?: boolean,
    ): string;
    (
        id: TranslationKeys,
        values: Record<string, ComplexValues>,
        defaultMessageId?: TranslationKeys,
        skipSpanWrap?: boolean,
    ): string;
}

const resolveKey = (brand: AppBrand, intl: IntlShape, translationKey: unknown): string => {
    const key = `${translationKey}__${brand}`.toLowerCase();
    const isGetBrandOverrideMessage = brand === AppBrand.HOPP && key in intl.messages;

    return String(isGetBrandOverrideMessage ? key : translationKey);
};

export function useI18nBranded<TranslationKeys>(brand: AppBrand) {
    const intl = useIntl();

    const i18n = React.useCallback<I18nFn<TranslationKeys>>(
        (
            id: TranslationKeys,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We need to allow any type for values
            values?: Record<string, any>,
            defaultMessageId?: TranslationKeys,
            skipSpanWrap?: boolean,
        ): string => {
            let defaultMessage: string | undefined;
            if (defaultMessageId) {
                const defaultMessageKey = resolveKey(brand, intl, defaultMessageId);
                defaultMessage = intl.formatMessage({id: defaultMessageKey});
            }

            const stringId = resolveKey(brand, intl, id);

            const i18nValue = intl.formatMessage(
                {
                    id: stringId,
                    defaultMessage,
                },
                values,
            );

            if (!skipSpanWrap && values && Array.isArray(i18nValue)) {
                const wrappedSpanI18nValue = (i18nValue as React.ReactNode[]).map((value): React.ReactNode => {
                    if (typeof value === "string") {
                        return <span key={value}>{value}</span>;
                    }
                    return value;
                });

                return wrappedSpanI18nValue as never as string;
            }

            return i18nValue;
        },
        [intl, brand],
    );

    return {i18n};
}
