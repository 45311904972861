import {useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Typography} from "@bolteu/kalep-react";
import {BinOutlined} from "@bolteu/kalep-react-icons";

interface Props {
    shift: FleetShiftManagementService.ShiftWithAllocationSummary;
}

export const CardHeader = ({shift}: Props) => {
    const {i18n} = useI18n();

    const onDeleteClick = useCallback(() => {
        // eslint-disable-next-line no-console -- TODO replace with logic
        console.log("Delete clicked, id: ", shift.id);
    }, [shift.id]);

    return (
        <div className="flex justify-between gap-3 border-b border-neutral-200 p-4">
            <div className="flex flex-col">
                <Typography variant="title-primary" lines={2}>
                    {shift.name}
                </Typography>
                <Typography variant="body-secondary" color="secondary">
                    {i18n("auth.app.fleet.work_shifts.planning.card.description", {
                        driversCount: shift.driver_count,
                    })}
                </Typography>
            </div>
            <IconButton onClick={onDeleteClick} icon={<BinOutlined />} aria-label="Delete" />
        </div>
    );
};
