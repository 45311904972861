import {FC, useCallback} from "react";

import {ConfirmActionDialogVariant} from "common/components/confirmActionDialog/ConfirmActionDialog";
import {shouldDisplayToggleDeactivationButton} from "common/components/toggleDeactivationButton/shouldDisplayToggleDeactivationButton";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ToggleDeactivationButton from "../../../../../common/components/toggleDeactivationButton/ToggleDeactivationButton";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

interface ToggleVehicleDeactivationButtonProps {
    vehicleId: number;
    vehiclePortalStatus: EntityPortalStatus;
    isDeactivatedByFleetOwner: boolean;
    onSuccessAction: () => void;
}

const fetchFunction = (api: Api, query: FleetOwnerPortalService.ToggleVehicleDeactivationRequest) =>
    api.fleetOwnerPortal.vehicleToggleDeactivation(query);

const ToggleVehicleDeactivationButton: FC<ToggleVehicleDeactivationButtonProps> = ({
    vehicleId,
    vehiclePortalStatus,
    isDeactivatedByFleetOwner,
    onSuccessAction,
}) => {
    const {i18n} = useI18n();
    const {fetch, status} = useFetch(fetchFunction);

    const handleConfirmToggle = useCallback(() => {
        if (fetch) {
            fetch({vehicle_id: vehicleId});
        }
    }, [fetch, vehicleId]);

    if (!shouldDisplayToggleDeactivationButton(vehiclePortalStatus, isDeactivatedByFleetOwner)) {
        return null;
    }

    let confirmButtonText;
    let titleText;
    let descriptionText;
    let snackbarText;
    let confirmButtonVariant;

    if (vehiclePortalStatus === EntityPortalStatus.Deactivated) {
        confirmButtonText = i18n("common.activate");
        confirmButtonVariant = ConfirmActionDialogVariant.Primary;
        titleText = i18n("auth.app.fleet.vehicle.activation_action.dialog.title");
        descriptionText = i18n("auth.app.fleet.vehicle.activation_action.dialog.text");
        snackbarText = i18n("auth.app.fleet.vehicle.activation_action.snackbar.text");
    } else {
        confirmButtonText = i18n("common.deactivate");
        confirmButtonVariant = ConfirmActionDialogVariant.Danger;
        titleText = i18n("auth.app.fleet.vehicle.deactivation_action.dialog.title");
        descriptionText = i18n("auth.app.fleet.vehicle.deactivation_action.dialog.text");
        snackbarText = i18n("auth.app.fleet.vehicle.deactivation_action.snackbar.text");
    }

    return (
        <ToggleDeactivationButton
            confirmButtonText={confirmButtonText}
            confirmButtonVariant={confirmButtonVariant}
            titleText={titleText}
            descriptionText={descriptionText}
            snackbarText={snackbarText}
            confirmAction={handleConfirmToggle}
            confirmActionStatus={status}
            onSuccessAction={onSuccessAction}
        />
    );
};

export default ToggleVehicleDeactivationButton;
