import {FC, useContext} from "react";

import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {NotificationContext} from "common/services/notificationProvider";
import {useId} from "@react-aria/utils";

import {BaseModal, Dialog, DialogProps, DrawerProps} from "@bolteu/kalep-react";

import TopLeftCornerNotification from "../notifications/TopLeftCorner";

export interface MobileDialogProps extends DrawerProps {
    variant?: "default" | "alert";
}

const MobileDialog: FC<MobileDialogProps> = ({
    isOpen,
    title,
    onRequestClose,
    children,
    variant = "default",
}: MobileDialogProps) => {
    const notifications = useContext(NotificationContext);
    const titleId = useId();

    return (
        <BaseModal isOpen={isOpen} onRequestClose={onRequestClose} aria-labelledby={titleId} placement="m-0">
            {notifications !== null && (
                <TopLeftCornerNotification
                    key="base_modal_notification"
                    notification={notifications.notification}
                    setNotification={notifications.setNotification}
                />
            )}
            <div className="bg-layer-floor-1 flex h-screen w-screen flex-col">
                <BaseModal.Header title={title} titleId={titleId}>
                    {variant === "default" && <BaseModal.Close onClose={onRequestClose} />}
                </BaseModal.Header>
                {children}
            </div>
        </BaseModal>
    );
};

const ResponsiveDialog: FC<DialogProps> = (props: DialogProps) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return isMobileView ? <MobileDialog {...props} /> : <Dialog {...props} />;
};

export {ResponsiveDialog};
