import {FC, useCallback} from "react";

import TextButton from "common/components/TextButton";
import {EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {I18nFn, useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import DocumentsList from "./DocumentsList";
import GenericFieldList from "./GenericFieldList";
import Header from "./Header";

export interface MatchCardProps {
    match: LeadToFleetService.ActiveMatch;
    setRejectedMatch: (match: LeadToFleetService.ActiveMatch) => void;
    setAcceptedMatch: (match: LeadToFleetService.ActiveMatch) => void;
}

const getLeadsPersonalInfoList = (
    leadsDetails: LeadToFleetService.LeadDetailsResponse | null,
    i18n: I18nFn,
): LeadToFleetService.GenericField[] => {
    if (!leadsDetails) {
        return [];
    }
    const leadsPersonalInfo: LeadToFleetService.GenericField[] = [
        {name: i18n("common.phone"), value: leadsDetails.phone},
    ];
    if (leadsDetails.age) {
        leadsPersonalInfo.push({name: i18n("auth.app.fleet.matches.age"), value: leadsDetails.age.toString()});
    }
    if (leadsDetails.generic_fields?.length) {
        leadsPersonalInfo.push(...leadsDetails.generic_fields);
    }
    return leadsPersonalInfo;
};

const fetchFunction = (api: Api, body: LeadToFleetService.GetLeadDetailsRequest) =>
    api.leadToFleet.leadmatcherLeadDetails(body);

const MatchCard: FC<MatchCardProps> = ({match, setRejectedMatch, setAcceptedMatch}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const {data: leadsDetails, fetch, status} = useFetch(fetchFunction);

    const showPersonalInfo = useCallback(() => {
        if (fetch) {
            trackEvent(EventName.L2FLeadInfoClicked);
            fetch({matcher_lead_id: match.matcher_lead_id});
        }
    }, [fetch, match.matcher_lead_id, trackEvent]);

    let textBtnText = i18n("auth.app.fleet.matches.show_personal_info");
    let textBtnTopMargin = "";
    if (match.documents.length || match.experience_generic_fields?.length || match.preference_generic_fields?.length) {
        textBtnText = i18n("common.view_all");
        textBtnTopMargin = "mt-2";
    }

    const leadsPersonalInfo = getLeadsPersonalInfoList(leadsDetails, i18n);

    return (
        <div className="w-full flex-col rounded-lg border border-solid border-neutral-100 pb-4 shadow">
            <Header match={match} setAcceptedMatch={setAcceptedMatch} setRejectedMatch={setRejectedMatch} />
            <div className="mb-4 h-[1px] bg-neutral-300" />
            <div className="flex flex-col gap-3 px-4 text-sm">
                <GenericFieldList
                    title={i18n("auth.app.fleet.matches.experience")}
                    list={match.experience_generic_fields}
                />
                <GenericFieldList
                    title={i18n("auth.app.fleet.matches.pereferences")}
                    list={match.preference_generic_fields}
                />
                <DocumentsList
                    title={i18n("auth.app.fleet.matches.documents")}
                    list={match.documents}
                    leadDetailsShown={!!leadsDetails}
                />
                <GenericFieldList title={i18n("auth.app.fleet.matches.personal_info")} list={leadsPersonalInfo} />
            </div>
            {!leadsDetails && (
                <div className={`px-4 ${textBtnTopMargin}`}>
                    <TextButton
                        onClick={showPersonalInfo}
                        text={textBtnText}
                        className="font-normal"
                        disabled={status === FetchStatus.Loading}
                    />
                </div>
            )}
        </div>
    );
};

export default MatchCard;
