import {FC} from "react";

import {Cross} from "@bolteu/kalep-react-icons";

export interface CloseButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

const CloseButton: FC<CloseButtonProps> = ({onClick, disabled = false}) => {
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className="br flex h-9 w-9 items-center justify-center rounded-full bg-neutral-200"
            aria-label="Close"
        >
            <Cross size="md" className="text-neutral-900" />
        </button>
    );
};

export default CloseButton;
